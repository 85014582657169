import Joi from 'joi';
import React, { ChangeEvent, Component, FormEvent, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { COLORS } from '../../../../common/configs/Colors';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { TColor } from '../../../../common/types/Color';
import { TThumbnails } from '../../../../common/types/Thumbnails';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import Button from '../../../controls/Button';
import RequestStatus from '../../RequestStatus/RequestStatus';
import { generateLogo } from '../../../../common/helpers/dynamicFavicon';
import Accordion from '../../../controls/Accordion';
import Radio from '../../../controls/Radio';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { THUMBNAILS } from '../../../../common/configs/Thumbnails';
import Thumbnail from '../../Thumbnail/Thumbnail';
import { unite } from '../../../../common/helpers/unite';
import LegacyBoardCard, {
  LegacyBoardCardProps,
} from '../BoardCard/LegacyBoardCard';
import { CARD_DATA_LABELS } from '../../../../common/configs/CardDataLabels';
import { TCardData } from '../../../../common/types/CardData';

interface FormData {
  color: string;
  thumbnail: string;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

interface RouteParams {
  routeBoardId: string;
}

interface Props extends RouteComponentProps<RouteParams>, WithTranslation {
  boardName: string;
  formData: FormData;
  initialFormData: FormData | undefined;
  formUpdateStatus: TRequestStatus;
  updateForm: <K extends keyof FormData>(field: K, value: FormData[K]) => void;
  handleSubmit: () => void;
  resetForm: () => void;
}

interface State {
  formErrors: FormErrors;
}

class AppearanceTab extends Component<Props, State> {
  formSchema = Joi.object({
    color: Joi.string().required().trim(true).messages(FormErrorMsgs.string),
    thumbnail: Joi.string()
      .trim(true)
      .messages(FormErrorMsgs.string)
      .allow(null, ''),
  });

  constructor(props: Props) {
    super(props);

    this.state = {
      formErrors: {},
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.formData.color !== this.props.formData.color) {
      const favicon: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']");
      if (favicon) {
        favicon.href = generateLogo(this.props.formData.color);
      }
    }
  }

  validateFormField = <K extends keyof FormData>(field: K) => {
    const result = this.formSchema.validate(this.props.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  validateForm = () => {
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.props.formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  previewAccent = (color: number | string | TColor | undefined) => {
    document.getElementsByTagName('html')[0].classList.remove('red-accent');
    document.getElementsByTagName('html')[0].classList.remove('green-accent');
    document.getElementsByTagName('html')[0].classList.remove('yellow-accent');
    document.getElementsByTagName('html')[0].classList.remove('blue-accent');
    document.getElementsByTagName('html')[0].classList.remove('purple-accent');
    document.getElementsByTagName('html')[0].classList.remove('pink-accent');
    document.getElementsByTagName('html')[0].classList.add(color + '-accent');
  };

  setColor = (
    ev:
      | React.MouseEvent<HTMLLIElement>
      | KeyboardEvent<Element>
      | ChangeEvent<HTMLInputElement>,
    color: number | string | TColor | undefined,
  ) => {
    this.props.updateForm('color', String(color));
    this.previewAccent(color);
  };

  setThumbnail = (
    ev:
      | React.MouseEvent<HTMLLIElement>
      | KeyboardEvent<Element>
      | ChangeEvent<HTMLInputElement>,
    thumbnail: number | string | TThumbnails | undefined,
  ) => {
    this.props.updateForm('thumbnail', String(thumbnail));
  };

  triggerFormSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.props.handleSubmit();
    }
  };

  hasUnsavedColor = (): boolean => {
    return this.props.initialFormData
      ? this.props.initialFormData.color !== this.props.formData.color
      : false;
  };

  hasUnsavedThumbnail = (): boolean => {
    return this.props.initialFormData
      ? this.props.initialFormData.thumbnail !== this.props.formData.thumbnail
      : false;
  };

  triggerFormReset = () => {
    this.previewAccent(
      this.props.initialFormData ? this.props.initialFormData.color : 'green',
    );
    this.props.resetForm();
  };

  renderCardPreview = () => {
    const mockProps: LegacyBoardCardProps = {
      selected: true,
      disabled: false,
      cardNrPrefix: 'DEMO',
      boardCard: {
        id: '1',
        title: 'Card Title',
        description: '',
        number: 1,
        tagIds: [],
        assigneeIds: [],
        priorityId: null,
        numberOfAttachments: 0,
        numberOfComments: 0,
        subtasks: {
          checked: 0,
          unchecked: 0,
        },
      },
      isPeekView: true,
      columnId: null,
      tags: [],
      priorities: [],
      members: [],
      selectCard: () => {
        return false;
      }, // Empty function
      setCardRef: () => {
        return false;
      }, // Empty function
      selectedCardData: Object.values(CARD_DATA_LABELS) as TCardData[],
    };

    return (
      <div className="card-preview card-board-component">
        <div className="card-board-list">
          <div className="list-drag-helper">
            <LegacyBoardCard {...mockProps} />
            <LegacyBoardCard
              {...mockProps}
              selected={false}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="flex-row fill">
          <div className="column pt-2xs">
            <Accordion
              accordionSlug="preview"
              isOpen={true}
              iconClasses="fal fa-eye icon"
              title={t('preview')}
              subheading={t('thisIsHowYourCardsWillLook')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
              translucent={true}
            >
              <div className="accordion-row py-sm">
                {this.renderCardPreview()}
              </div>
            </Accordion>
            <Accordion
              accordionSlug="accent"
              isOpen={false}
              iconClasses="fal fa-brush icon"
              title={t('accent')}
              subheading={t('colorUsed')}
              translucent={true}
            >
              <div className="accordion-row py-sm">
                <ul className="control-list-component pt-2xs">
                  {COLORS.filter((color: TColor) => {
                    return !['orange', 'cyan'].includes(color.id);
                  }).map((color: TColor) => {
                    return (
                      <li
                        className="text-center"
                        key={color.id}
                      >
                        <label
                          htmlFor={color.label}
                          title={color.label}
                        >
                          <span
                            className={`pe-none fas text-4xl fa-square accent-text-${color.id}`}
                          ></span>
                        </label>
                        <br />
                        <Radio
                          id={color.label}
                          name={color.label}
                          checked={color.id === this.props.formData.color}
                          label={color.label}
                          className={'mr-0'}
                          srOnly={true}
                          changeMethod={(ev: ChangeEvent<HTMLInputElement>) =>
                            this.setColor(ev, color.id)
                          }
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              {this.hasUnsavedColor() && (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component">
                    <li>
                      <Button
                        className="primary-button"
                        type="submit"
                        disabled={this.props.formUpdateStatus === 'loading'}
                        onClick={(
                          ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                        ) => {
                          this.triggerFormSubmit(ev);
                        }}
                      >
                        <RequestStatus status={this.props.formUpdateStatus} />
                        <span className="text">{t('applyChanges')}</span>
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="secondary-button"
                        disabled={this.props.formUpdateStatus === 'loading'}
                        onClick={() => {
                          this.triggerFormReset();
                        }}
                      >
                        <span className="text">{t('cancel')}</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              )}
            </Accordion>
            <Accordion
              accordionSlug="thumbnail"
              isOpen={false}
              iconClasses="fal fa-image icon"
              title={t('thumbnail')}
              subheading={t('usedOnHomepage')}
              translucent={true}
            >
              <div className="accordion-row py-sm">
                <ul className="control-list-component pt-xs">
                  {THUMBNAILS.map((thumbnail: TThumbnails) => {
                    return (
                      <li
                        className="text-center"
                        key={thumbnail.value}
                      >
                        <label
                          htmlFor={
                            thumbnail.label == '' ? 'default' : thumbnail.label
                          }
                          className="thumbnail-label"
                          title={
                            thumbnail.label == '' ? 'Default' : thumbnail.label
                          }
                        >
                          <div
                            className={unite(
                              'pe-none thumbnail-frame-component',
                              this.props.formData.color
                                ? `accent-${this.props.formData.color}`
                                : 'accent-green',
                            )}
                          >
                            <Thumbnail
                              thumbnailData={thumbnail.value}
                              classes="radius size-64"
                              title={thumbnail.label || this.props.boardName}
                            />
                          </div>
                        </label>
                        <br />
                        <Radio
                          id={
                            thumbnail.label == '' ? 'default' : thumbnail.label
                          }
                          name={thumbnail.label}
                          checked={
                            thumbnail.value === this.props.formData.thumbnail
                          }
                          label={
                            thumbnail.label == '' ? 'Default' : thumbnail.label
                          }
                          className={'mr-0'}
                          srOnly={true}
                          changeMethod={(ev: ChangeEvent<HTMLInputElement>) =>
                            this.setThumbnail(ev, thumbnail.value)
                          }
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              {this.hasUnsavedThumbnail() && (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component">
                    <li>
                      <Button
                        className="primary-button"
                        type="submit"
                        disabled={this.props.formUpdateStatus === 'loading'}
                        onClick={(
                          ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                        ) => {
                          this.triggerFormSubmit(ev);
                        }}
                      >
                        <RequestStatus status={this.props.formUpdateStatus} />
                        <span className="text">{t('applyChanges')}</span>
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="secondary-button"
                        disabled={this.props.formUpdateStatus === 'loading'}
                        onClick={() => {
                          this.triggerFormReset();
                        }}
                      >
                        <span className="text">{t('cancel')}</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              )}
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('appearanceTab')(AppearanceTab);
