import React from 'react';

interface MessageBarProps {
  type: 'info' | 'success' | 'danger' | 'warning';
  icon: string;
  children: React.ReactNode;
  extraClasses?: string;
  rightContent?: React.ReactNode;
}

// Moved mapping outside to avoid recreation on every render
const typeClassMapping = {
  info: 'message-bar-info',
  success: 'message-bar-success',
  danger: 'message-bar-danger',
  warning: 'message-bar-warning',
};

function MessageBar({
  type,
  icon,
  children,
  rightContent,
  extraClasses = '',
}: MessageBarProps) {
  const typeClass = typeClassMapping[type];

  return (
    <div className={`message-bar-component card ${typeClass} ${extraClasses}`}>
      <div className="message-content">
        <div className="mb-0 flex-v-start text-sm">
          <span className={`message-icon fas px-xs pt-3xs ${icon}`}></span>
          <span className="message-text">{children}</span>
        </div>
        {rightContent && <div className="right-content">{rightContent}</div>}
      </div>
    </div>
  );
}

export default MessageBar;
