import React, { ChangeEvent, FormEvent } from 'react';
import Button from '../../../controls/Button';
import TextBox from '../../../controls/TextBox';
import { addColumn } from '../../../../common/api/endpoints/column';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import { getScrollBehavior } from '../../../../common/helpers/getScrollBehavior';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import AppContext, {
  IAppContext,
} from '../../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../../common/contexts/NotificationsContext';
import { withContextAdapter } from '../../ContextAdapter/withContextAdapter';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface FormData {
  columnTitle: string;
}

interface ContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface ExternalProps {
  setNewColumn: (column: ColumnDTO) => void;
  routeBoardId: string;
  hasAccess: boolean;
}
interface BoardColumnFormProps
  extends ExternalProps,
    ContextProps,
    WithTranslation {}

interface BoardColumnFormState {
  showAddColumn: boolean;
  formData: FormData;
}

class BoardColumnForm extends React.Component<
  BoardColumnFormProps,
  BoardColumnFormState
> {
  columnPlaceholderRef: React.RefObject<HTMLDivElement>;
  addButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: BoardColumnFormProps) {
    super(props);
    this.columnPlaceholderRef = React.createRef();
    this.addButtonRef = React.createRef();

    this.state = {
      showAddColumn: false,
      formData: {
        columnTitle: '',
      },
    };
  }

  toggleShowAddColumn = () => {
    this.setState((prevState) => {
      return {
        showAddColumn: !prevState.showAddColumn,
      };
    });
  };

  handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      this.toggleShowAddColumn();
      this.setState({
        formData: {
          columnTitle: '',
        },
      });
    }
  };

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      },
    });
  }

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      if (this.state.formData.columnTitle === '') {
        this.setState({
          showAddColumn: false,
        });
        return;
      }

      const column = await addColumn(
        this.props.routeBoardId,
        this.state.formData.columnTitle,
      );
      this.toggleShowAddColumn();
      this.props.setNewColumn(column);
      this.setState(
        {
          formData: {
            columnTitle: '',
          },
        },
        () => {
          this.addButtonRef.current?.focus({
            preventScroll: true,
          });
          this.addForcedFocus();
          this.columnPlaceholderRef?.current?.scrollIntoView({
            behavior: getScrollBehavior(),
            block: 'nearest',
            inline: 'nearest',
          });
        },
      );
    } catch (err) {
      showErrorNotifications(err, this.props.setMessages);
    }
  };

  addForcedFocus() {
    this.addButtonRef.current?.classList.add('focus');
  }

  removeForcedFocus() {
    this.addButtonRef.current?.classList.remove('focus');
  }

  setColumnTitle = (ev: ChangeEvent<HTMLInputElement>) => {
    this.updateForm('columnTitle', ev.target.value);
  };

  render() {
    const { t, hasAccess } = this.props;

    return (
      <div
        className="column card-board-list"
        ref={this.columnPlaceholderRef}
      >
        <div className="column largest pt-xs">
          {this.state.showAddColumn ? (
            <form
              onSubmit={this.handleSubmit}
              onBlur={this.handleSubmit}
            >
              <TextBox
                id="newColumnTitle"
                name="columnTitle"
                label="Column Title"
                srOnly={true}
                type="text"
                value={this.state.formData.columnTitle}
                onChange={this.setColumnTitle}
                onKeyDown={this.handleInputKeyDown}
                autoFocus={true}
                placeholder={t('enterColumnTitle')}
                tight={true}
              />
            </form>
          ) : (
            <Button
              className={
                (hasAccess ? 'secondary-button' : 'secondary-button') +
                ' translucent fill'
              }
              onClick={this.toggleShowAddColumn}
              onBlur={() => this.removeForcedFocus()}
              disabled={!hasAccess}
              title={hasAccess ? '' : t('noAccess')}
            >
              <span className="fas fa-stream text-xs mr-xs"></span>
              <span className="text">{t('addColumn')}</span>
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default withContextAdapter<ExternalProps, IAppContext, ContextProps>(
  withStyledTranslation('boardColumnPlaceholder')(BoardColumnForm),
  AppContext,
  (ctx: IAppContext) => {
    return {
      setMessages:
        ctx.notifications.setMessages ??
        (() => {
          return false;
        }),
    };
  },
);
