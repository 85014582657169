import { useEffect, useRef } from 'react';

interface BladeObject {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  velocityX: number;
  velocityY: number;
  opacity: number;
  elongation: number;
}

const AnimatedBackground = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    if (!canvas) return;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const colors = [
      'rgba(255, 0, 128, 0.2)', // Adjust colors for more pronounced effect
      'rgba(0, 255, 255, 0.2)',
      'rgba(255, 255, 0, 0.2)',
    ];

    const blades: BladeObject[] = [];

    function createBlade() {
      return {
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        width: Math.random() * 20 + 280, // Width of the blade
        height: Math.random() * 300 + 500, // Height of the blade
        color: colors[Math.floor(Math.random() * colors.length)],
        velocityX: (Math.random() - 0.5) * 0.4, // Increase velocity
        velocityY: (Math.random() - 0.5) * 0.4,
        opacity: Math.random() * 0.4 + 0.2, // Random opacity
        elongation: Math.random() * 0.02 + 0.05, // Elongation speed
      };
    }

    for (let i = 0; i < 40; i++) {
      // Number of blades
      blades.push(createBlade());
    }

    function drawBlade(blade: BladeObject) {
      ctx.save(); // Save the current state
      ctx.translate(blade.x, 0); // Move to the blade's position
      const gradient = ctx.createLinearGradient(
        0,
        0,
        blade.width,
        blade.height,
      );
      gradient.addColorStop(0, blade.color);
      gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
      ctx.fillStyle = gradient;
      ctx.globalAlpha = blade.opacity; // Set opacity
      ctx.fillRect(0, 0, blade.width, blade.height);
      ctx.restore(); // Restore the previous state
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      blades.forEach((blade) => {
        drawBlade(blade);

        blade.x += blade.velocityX;
        blade.y += blade.velocityY;
        blade.height += blade.elongation; // Elongate the blade

        // Wrap around edges
        if (blade.x - blade.width > canvas.width) {
          blade.x = -blade.width;
        } else if (blade.x + blade.width < 0) {
          blade.x = canvas.width + blade.width;
        }

        if (blade.y - blade.height > canvas.height) {
          blade.y = -blade.height;
        } else if (blade.y + blade.height < 0) {
          blade.y = canvas.height + blade.height;
        }
      });

      requestAnimationFrame(animate);
    }

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    });

    animate();
  }, []);

  return (
    <div className="background">
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default AnimatedBackground;
