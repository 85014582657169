import React from 'react';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import dayjsHelper from '../../../../common/helpers/dayjsHelper';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import MessageBar from '../../../controls/MessageBar';
import Button from '../../../controls/Button';

interface SubscriptionStatusProps {
  team: TeamDTO;
}

const SubscriptionStatus: React.FC<SubscriptionStatusProps> = ({ team }) => {
  const { t } = useTranslation('home');

  const trialEndsAt = new Date(team.subscription.trialEndsAt ?? '');
  const daysLeft = trialEndsAt
    ? dayjsHelper(trialEndsAt).diff(dayjsHelper(), 'day')
    : 0;

  if (
    team.subscription.trialEndsAt &&
    !team.subscription.hasPaymentMethods &&
    daysLeft > 0
  ) {
    return (
      <div className="flex-row fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="warning"
            icon="fa-exclamation-triangle"
            rightContent={
              <NavLink
                to={`/team/${team.id}/plan-and-billing`}
                className={'secondary-button translucent no-wrap'}
              >
                {t('addPaymentMethod')}
              </NavLink>
            }
          >
            <Trans
              i18nKey="home:trialEndsInDays"
              values={{
                teamName: team.name || '',
                count: daysLeft,
              }}
              components={{ strong: <strong /> }}
            />
          </MessageBar>
        </div>
      </div>
    );
  }

  // Added Payment Issue branch based on PersonalBoardsSection logic
  if (team.subscription.period === 'suspended') {
    return (
      <div className="flex-row fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="warning"
            icon="fa-exclamation-circle"
          >
            <form
              target="_blank"
              method="POST"
              action={`${process.env.REACT_APP_API_BASE_URL}/team/${team.id}/updatePaymentMethod`}
            >
              <Trans
                i18nKey={
                  team.owned
                    ? 'home:teamPaymentIssue'
                    : 'home:unownedTeamPaymentIssue'
                }
                values={{
                  endDate: dayjsHelper(
                    team.subscription.paymentRetryUntil,
                  ).format('MMMM Do, YYYY'),
                }}
                components={{
                  Button: (
                    <Button
                      type="submit"
                      className="link-button"
                    />
                  ),
                  span: <span className="text"></span>,
                  strong: <strong />,
                }}
              />
            </form>
          </MessageBar>
        </div>
      </div>
    );
  }

  if (team.subscription.period == 'grace' && team.owned) {
    return (
      <div className="flex-row pt-0 fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="danger"
            icon="fa-exclamation-circle"
            rightContent={
              <NavLink
                to={`/team/${team.id}/plan-and-billing`}
                className={'secondary-button translucent no-wrap'}
              >
                {t('renewPlan')}
              </NavLink>
            }
          >
            <Trans
              i18nKey="home:planExpired"
              values={{
                teamName: team.name,
                deleteAt: dayjsHelper(team.subscription.deleteAt).format(
                  'MMMM Do, YYYY',
                ),
              }}
              components={{
                strong: <strong />,
              }}
            />
          </MessageBar>
        </div>
      </div>
    );
  }

  if (
    team.subscription.cancelAt &&
    team.subscription.period == 'active' &&
    team.owned
  ) {
    return (
      <div className="flex-row pt-0 fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="warning"
            icon="fa-exclamation-circle"
            rightContent={
              <NavLink
                to={`/team/${team.id}/plan-and-billing`}
                className={'secondary-button translucent no-wrap'}
              >
                {t('reactivate')}
              </NavLink>
            }
          >
            <Trans
              i18nKey={'home:reactivatePlan'}
              values={{
                teamName: team.name,
                cancelAt: dayjsHelper(team.subscription.cancelAt).format(
                  'MMMM Do, YYYY',
                ),
              }}
              components={{
                strong: <strong />,
              }}
            />
          </MessageBar>
        </div>
      </div>
    );
  }

  if (team.subscription.period == 'grace' && !team.owned) {
    return (
      <div className="flex-row pt-0 fill">
        <div className="column px-2xs pt-xs pb-0">
          <MessageBar
            type="danger"
            icon="fa-exclamation-circle"
          >
            {t('unownedTeamGracePeriod', {
              teamName: team.name,
              deleteAt: dayjsHelper(team.subscription.deleteAt).format(
                'MMMM Do, YYYY',
              ),
            })}
          </MessageBar>
        </div>
      </div>
    );
  }

  return null;
};

export default SubscriptionStatus;
