import React, { useState, useCallback, useMemo } from 'react';

interface PassErrors {
  message: string;
  isValid: boolean;
}

interface Props {
  label: string;
  type: string;
  name: string;
  id: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  ariaAutocomplete?: 'none' | 'inline' | 'list' | 'both';
  br?: boolean;
  placeholder?: string;
  srOnly?: boolean;
  value?: string | number;
  disabled?: boolean;
  required?: boolean;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  maxLength?: number;
  className?: string;
  tight?: boolean;
  autoFocus?: boolean; // renamed from autofocus
  formGroupClassNames?: string;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  showCounter?: boolean;
  inputIcon?: string;
  onIconClick?: (value: boolean) => void;
  togglePass?: boolean;
  passErrors?: PassErrors[];
  role?: string;
  defaultValue?: string;
  onPaste?: (ev: React.ClipboardEvent<HTMLInputElement>) => void;
  // Removed inputref prop in favor of forwarding refs
}

const TextBox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      props.onFocus?.(ev);
    },
    [props.onFocus],
  );

  const handleBlur = useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      props.onBlur?.(ev);
    },
    [props.onBlur],
  );

  const handleIconClick = useCallback(() => {
    props.onIconClick?.(!props.togglePass);
  }, [props.onIconClick, props.togglePass]);

  const formGroupClasses = useMemo(() => {
    return [
      'form-group',
      props.formGroupClassNames || '',
      props.tight ? 'tight' : '',
    ]
      .join(' ')
      .trim();
  }, [props.formGroupClassNames, props.tight]);

  return (
    <div className={formGroupClasses}>
      <label
        className={props.srOnly ? 'sr-only' : ''}
        htmlFor={props.id}
      >
        {props.label}
        {props.required ? '*' : ''}
      </label>
      {props.br && <br />}
      <div className="input-wrapper">
        {/* Review controlled vs uncontrolled: avoid using both value and defaultValue simultaneously */}
        <input
          type={props.type}
          name={props.name}
          id={props.id}
          defaultValue={props.defaultValue}
          autoComplete={props.autoComplete}
          aria-autocomplete={props.ariaAutocomplete}
          onChange={props.onChange}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value}
          required={props.required}
          onBlur={handleBlur}
          maxLength={props.maxLength || 255}
          aria-invalid={!!props.error}
          autoFocus={props.autoFocus} // updated prop name
          className={props.className}
          onKeyDown={props.onKeyDown}
          role={props.role}
          onFocus={handleFocus}
          ref={ref} // using forwarded ref
          onPaste={props.onPaste}
        />
        {props.inputIcon && (
          <button
            type="button"
            className="input-icon"
            onClick={handleIconClick}
          >
            <span className={props.inputIcon}></span>
          </button>
        )}
      </div>
      {props.showCounter && focused && typeof props.value === 'string' && (
        <span className="textarea-counter flag-text">
          {props.value.length}/{props.maxLength || 255}
        </span>
      )}
      {props.error ? (
        <ul className="error-list light">
          <li className="flex-v-center">
            <span className="fas fa-exclamation-circle mr-2xs text-sm"></span>
            <span className="text">{props.error}</span>
          </li>
        </ul>
      ) : props.passErrors ? (
        <ul className="error-list light">
          {props.passErrors.map((error) => (
            <li
              key={error.message}
              className={`flex-v-center ${error.isValid ? 'success' : 'error'}`}
            >
              <span
                className={
                  error.isValid
                    ? 'fas fa-check-circle mr-2xs text-sm'
                    : 'fas fa-exclamation-circle mr-2xs text-sm'
                }
              ></span>
              <span className="text">{error.message}</span>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
});

export default React.memo(TextBox);

TextBox.displayName = 'TextBox';
