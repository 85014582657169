import React, { useState, useEffect, useCallback } from 'react';
import { LexicalEditor } from 'lexical';
import { archiveCard, updateCard } from '../../../../common/api/endpoints/card';
import AppContext from '../../../../common/contexts/AppContext';
import BoardContext from '../../../../common/contexts/BoardContext';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import Button from '../../../controls/Button';
import CardFlyout from './CardFlyout';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import { History, Location } from 'history';
import ContextMenu from '../../../controls/ContextMenu';
import selectTutorial from '../../../../common/helpers/selectTutorial';
import { updateTutorialStep } from '../../../../common/helpers/tutorialHelper';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface FlyoutCardDetailsProps {
  cardNrPrefix: string;
  boardCard: BoardCard;
  onClose: () => void;
  columnTitles: Partial<ColumnDTO>[];
  history: History;
  location: Location;
  clipboardData: File | null;
  setUploadClipboardData: (value: boolean) => void;
  uploadClipboardData: boolean;
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  tagIds: string[];
  assigneeIds: string[];
  description: string;
  priorityId: string | null;
  numberOfAttachments: number;
  numberOfComments: number;
}

interface FormData {
  card: {
    title: string;
    description: string;
  };
  comment: string;
}

interface InitialCard {
  title: string;
  description: string;
  numberOfAttachments?: number;
  numberOfComments?: number;
}

export const FlyoutCardDetails: React.FC<FlyoutCardDetailsProps> = (props) => {
  const { boardCard } = props;
  const boardCTX = React.useContext(BoardContext);
  const appCTX = React.useContext(AppContext);
  const location = useLocation();
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [descriptionEditor, setDescriptionEditor] =
    useState<LexicalEditor | null>(null);
  const [initialCard, setInitialCard] = useState<InitialCard>({
    title: boardCard.title,
    description: boardCard.description,
    numberOfAttachments: boardCard.numberOfAttachments,
    numberOfComments: boardCard.numberOfComments,
  });
  const i18n = useTranslation('boardCardFlyout');
  const [, setFormData] = useState<FormData>({
    comment: '',
    card: boardCard,
  });

  const initFlyoutCard = useCallback(() => {
    setFormData((prev) => ({ ...prev, card: boardCard }));
  }, [boardCard]);

  useEffect(() => {
    initFlyoutCard();
  }, [boardCard, boardCTX.openCardId, initFlyoutCard, location.pathname]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const target = e.target as HTMLTextAreaElement;
      if (e.key === 'Escape') {
        e.preventDefault();
        if (Object.keys(initialCard).includes(target.name)) {
          const key = target.name as keyof InitialCard;
          if (boardCard[key] !== initialCard[key]) {
            const content: Partial<InitialCard> = { [key]: initialCard[key] };
            boardCTX.updateCard(boardCard.id, content);
            return;
          }
        }
        const isContextMenuOpened = Boolean(
          document.querySelector('.context-menu-component'),
        );
        const isImageViewerOpened = Boolean(
          document.querySelector('.image-viewer-component'),
        );
        const isDialogOpened = Boolean(
          document.querySelector('.dialog-component'),
        );
        if (isContextMenuOpened || isImageViewerOpened || isDialogOpened)
          return;
        props.onClose?.();
      }
    },
    [boardCard, initialCard, props],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      if (descriptionEditor?.isEditable()) {
        descriptionEditor.getEditorState().read(() => {
          false;
        });
      }
    };
  }, [
    handleKeyDown,
    descriptionEditor,
    boardCard.description,
    props.history.location.pathname,
  ]);

  const tutorialAction_updateDescription = async () => {
    const { loggedUser, notifications, updateClientData } = appCTX;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'solo') return;
      await updateTutorialStep(
        loggedUser,
        userType,
        'add_card_description',
        notifications.setMessages ?? (() => false),
        updateClientData ?? (() => false),
      );
    }
  };

  const tutorialAction_uploadAttachment = async () => {
    const { loggedUser, notifications, updateClientData } = appCTX;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'invited' && userType !== 'teamOwner') return;
      const stepId =
        userType === 'invited'
          ? 'upload_attachment_invited'
          : 'upload_attachment';
      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        notifications.setMessages ?? (() => false),
        updateClientData ?? (() => false),
      );
    }
  };

  const tutorialAction_addComment = async () => {
    const { loggedUser, notifications, updateClientData } = appCTX;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'invited' && userType !== 'teamOwner') return;
      const stepId =
        userType === 'invited' ? 'add_comment_invited' : 'add_comment';
      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        notifications.setMessages ?? (() => false),
        updateClientData ?? (() => false),
      );
    }
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    boardCTX.updateCard(boardCard.id, { title: e.target.value });
  };

  const saveTitle = async () => {
    if (boardCard.title !== initialCard.title) {
      saveCard({ title: boardCard.title });
    }
  };

  const onDescriptionChange = (value: string, cardId?: string) => {
    if (value === initialCard.description) return;
    saveCard({ description: value }, cardId);
    tutorialAction_updateDescription();
  };

  const saveCard = async (payload: Partial<InitialCard>, cardId?: string) => {
    try {
      const updatedCard = await updateCard(
        cardId ? cardId : boardCard.id,
        payload,
      );
      const updatedValues: Partial<InitialCard> = {
        title: updatedCard.title,
        description: updatedCard.description,
        numberOfAttachments: boardCard.numberOfAttachments,
        numberOfComments: boardCard.numberOfComments,
      };
      setInitialCard((prev) => ({ ...prev, ...updatedValues }));
      boardCTX.updateCard(cardId ? cardId : boardCard.id, updatedValues);
    } catch (error) {
      showErrorNotifications(
        error,
        appCTX.notifications.setMessages ??
          (() => {
            return false;
          }),
      );
    }
  };

  const handleArchiveCard = async () => {
    try {
      await archiveCard(boardCard.id);
      boardCTX.reloadBoard();
      props.onClose?.();
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      setServerErrors(error);
    }
  };

  const handleAddAttachment = async () => {
    boardCTX.addAttachment(boardCard.id);
    tutorialAction_uploadAttachment();
  };
  const handleRemoveAttachment = async () => {
    boardCTX.removeAttachment(boardCard.id);
  };
  const handleAddComment = async () => {
    boardCTX.addComment(boardCard.id);
    tutorialAction_addComment();
  };
  const handleDeleteComment = async () => {
    boardCTX.deleteComment(boardCard.id);
  };
  const handleAddSubtask = async () => {
    boardCTX.addSubtask(boardCard.id);
  };
  const handleUpdateSubtask = async (checked: boolean) => {
    boardCTX.updateSubtask(boardCard.id, checked);
  };
  const handleRemoveSubtask = async (checked: boolean) => {
    boardCTX.removeSubtask(boardCard.id, checked);
  };
  const handleCloseButtonKeyDown = (
    e: React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      props.onClose?.();
    }
  };

  return (
    <CardFlyout
      disabled={false}
      cardNrPrefix={props.cardNrPrefix}
      boardCard={boardCard}
      tags={boardCTX.board.tags}
      priorities={boardCTX.board.priorities}
      members={boardCTX.board.members}
      serverErrors={serverErrors}
      descriptionEditor={descriptionEditor}
      handleFlyoutClose={props.onClose}
      handleCloseButtonKeyDown={handleCloseButtonKeyDown}
      setMessages={appCTX.notifications.setMessages ?? (() => false)}
      setDescriptionEditor={setDescriptionEditor}
      onTitleChange={onTitleChange}
      saveTitle={saveTitle}
      onDescriptionChange={onDescriptionChange}
      columnTitles={props.columnTitles}
      boardUser={boardCTX.board.user}
      addWsListener={boardCTX.addWsListener}
      removeWsListener={boardCTX.removeWsListener}
      handleAddAttachment={handleAddAttachment}
      handleRemoveAttachment={handleRemoveAttachment}
      handleAddComment={handleAddComment}
      handleDeleteComment={handleDeleteComment}
      clipboardData={props.clipboardData}
      setUploadClipboardData={props.setUploadClipboardData}
      uploadClipboardData={props.uploadClipboardData}
      handleAddSubtask={handleAddSubtask}
      handleUpdateSubtask={handleUpdateSubtask}
      handleRemoveSubtask={handleRemoveSubtask}
      paid={boardCTX.board.paid}
    >
      <>
        <li>
          <ContextMenu
            dept={0}
            title={i18n.t('cardOptions')}
            triggerContent={<span className="fas fa-ellipsis-h"></span>}
            triggerClassDefault="ghost-button"
            triggerClassActive="secondary-button"
          >
            <li>
              <Button
                className="ghost-button"
                onClick={handleArchiveCard}
                disabled={
                  !['admin', 'owner'].includes(boardCTX.board.user.role)
                }
              >
                <span className="fal fa-archive icon"></span>
                <span className="text">{i18n.t('archiveCard')}</span>
              </Button>
            </li>
          </ContextMenu>
        </li>
      </>
    </CardFlyout>
  );
};
