import React, { useState, useEffect, useContext } from 'react';
import Joi from 'joi';
import {
  createSubtask,
  listSubtasks,
  updateSubtask,
  removeSubtask,
  moveSubtask,
  SubtaskDTO,
} from '../../../../../common/api/endpoints/subtask';
import CheckBox from '../../../../controls/CheckBox';
import Button from '../../../../controls/Button';
import TextBox from '../../../../controls/TextBox';
import ContextMenu from '../../../../controls/ContextMenu';
import Dialog from '../../../../controls/Dialog';
import BoardContext from '../../../../../common/contexts/BoardContext';
import LinkButton from '../../../../controls/LinkButton';
import LegacyBoardCard from '../../BoardCard/LegacyBoardCard';
import { ICard } from '../../../../../common/interfaces/BoardContext';
import AppContext from '../../../../../common/contexts/AppContext';
import { Trans, useTranslation } from 'react-i18next';
import MessageBar from '../../../../controls/MessageBar';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
} from '@wavemyth/react-beautiful-dnd';
import { unite } from '../../../../../common/helpers/unite';
import { showErrorNotifications } from '../../../../../common/helpers/showNotifications';

const validationSchema = Joi.string()
  .min(1)
  .max(1024)
  .required()
  .label('Title');

interface CardSubtasksProps {
  cardId: string;
  updateCardActivityItems: () => void;
  handleAddSubtaskWs?: () => void;
  handleUpdateSubtaskWs?: (checked: boolean) => void;
  handleRemoveSubtaskWs?: (checked: boolean) => void;
  disabled?: boolean;
}

const CardSubtasks: React.FC<CardSubtasksProps> = ({
  cardId,
  updateCardActivityItems,
  handleAddSubtaskWs,
  handleRemoveSubtaskWs,
  disabled = false,
}) => {
  const { t } = useTranslation('cardSubtask');
  const boardCTX = useContext(BoardContext);
  const appCTX = useContext(AppContext);
  const cardNrPrefix = boardCTX.board.cardNrPrefix;

  const [subtasks, setSubtasks] = useState<SubtaskDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newSubtaskTitle, setNewSubtaskTitle] = useState<string>('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const [editingSubtaskId, setEditingSubtaskId] = useState<string | null>(null);
  const [editedTitle, setEditedTitle] = useState<string>('');
  const [subtaskToDelete, setSubtaskToDelete] = useState<string | null>(null);

  useEffect(() => {
    fetchSubtasks();
  }, [cardId]);

  const fetchSubtasks = async () => {
    try {
      const subtasks = await listSubtasks(cardId);
      setSubtasks(subtasks);
      console.debug('subtasks', subtasks);
    } catch (err) {
      setError(t('errors.failedToLoadSubtasks'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (boardCTX.addWsListener) {
      boardCTX.addWsListener('add_subtask', fetchSubtasks);
      boardCTX.addWsListener('update_subtask', fetchSubtasks);
      boardCTX.addWsListener('remove_subtask', fetchSubtasks);
    }

    return () => {
      if (boardCTX.removeWsListener) {
        boardCTX.removeWsListener('add_subtask', fetchSubtasks);
        boardCTX.removeWsListener('update_subtask', fetchSubtasks);
        boardCTX.removeWsListener('remove_subtask', fetchSubtasks);
      }
    };
  }, [boardCTX]);

  const handleSubtaskToggle = async (id: string) => {
    const subtask = subtasks.find((s) => s.id === id);
    if (!subtask) return;

    try {
      const updatedSubtask = await updateSubtask(
        subtask.id,
        subtask.title,
        !subtask.checked,
      );
      setSubtasks((prev) =>
        prev.map((s) => (s.id === id ? updatedSubtask : s)),
      );
      updateCardActivityItems();
      boardCTX.updateSubtask(cardId, !subtask.checked);
    } catch (err) {
      if (appCTX.notifications.setMessages) {
        showErrorNotifications(
          t('errors.failedToUpdateSubtask'),
          appCTX.notifications.setMessages,
        );
      }
    }
  };

  const handleAddSubtask = async () => {
    const validationResult = validationSchema.validate(newSubtaskTitle);
    setNewSubtaskTitle('');

    if (validationResult.error) {
      setValidationError(validationResult.error.details[0].message);
      return;
    }

    try {
      const newSubtask = await createSubtask(cardId, newSubtaskTitle);
      setSubtasks((prev) => [...prev, newSubtask]);
      setValidationError(null);
      if (handleAddSubtaskWs) {
        handleAddSubtaskWs();
      }
      updateCardActivityItems();
    } catch (err) {
      if (appCTX.notifications.setMessages) {
        showErrorNotifications(
          t('errors.failedToAddSubtask'),
          appCTX.notifications.setMessages,
        );
      }
    }
  };

  const handleRemoveSubtask = async () => {
    if (!subtaskToDelete) return;
    const subtask = subtasks.find((subtask) => subtask.id === subtaskToDelete);

    try {
      await removeSubtask(subtaskToDelete);
      setSubtasks((prev) => prev.filter((s) => s.id !== subtaskToDelete));
      setSubtaskToDelete(null);
      if (handleRemoveSubtaskWs && subtask) {
        handleRemoveSubtaskWs(subtask.checked);
      }

      updateCardActivityItems();
    } catch (err) {
      if (appCTX.notifications.setMessages) {
        showErrorNotifications(
          t('errors.failedToRemoveSubtask'),
          appCTX.notifications.setMessages,
        );
      }
    }
  };

  const handleEditSubtask = async (id: string) => {
    const validationResult = validationSchema.validate(editedTitle);

    if (validationResult.error) {
      setValidationError(validationResult.error.details[0].message);
      return;
    }

    try {
      const subtask = subtasks.find((subtask) => subtask.id === id);

      const updatedSubtask = await updateSubtask(
        id,
        editedTitle,
        subtask?.checked ? subtask.checked : false,
      );
      setSubtasks((prev) =>
        prev.map((s) => (s.id === id ? updatedSubtask : s)),
      );
      setEditingSubtaskId(null);
      setEditedTitle('');
      setValidationError(null);
      updateCardActivityItems();
    } catch (err) {
      if (appCTX.notifications.setMessages) {
        showErrorNotifications(
          t('errors.failedToEditSubtask'),
          appCTX.notifications.setMessages,
        );
      }
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.index === destination.index) return;

    const reorderedSubtasks = Array.from(subtasks);
    const [movedSubtask] = reorderedSubtasks.splice(source.index, 1);
    reorderedSubtasks.splice(destination.index, 0, movedSubtask);

    setSubtasks(reorderedSubtasks);

    try {
      await moveSubtask(movedSubtask.id, destination.index);
    } catch (err) {
      setError(t('errors.failedToUpdateSubtask'));
      fetchSubtasks();
    }
  };

  const renderLimitUpsale = () => {
    const proSubtaskLimit = parseInt(
      process.env.REACT_APP_PRO_SUBTASK_LIMIT
        ? process.env.REACT_APP_PRO_SUBTASK_LIMIT
        : '0',
    );
    const freeSubtaskLimit = parseInt(
      process.env.REACT_APP_FREE_SUBTASK_LIMIT
        ? process.env.REACT_APP_FREE_SUBTASK_LIMIT
        : '0',
    );

    return (
      <>
        {!boardCTX.board.paid &&
          proSubtaskLimit > freeSubtaskLimit &&
          subtasks.length >= freeSubtaskLimit &&
          !disabled && (
            <MessageBar
              type="info"
              icon="fas fa-lock"
              extraClasses="opaque mb-xs"
              rightContent={
                <form
                  target="_blank"
                  method="POST"
                  action={`${process.env.REACT_APP_API_BASE_URL}/self/upgrade`}
                >
                  <button className="secondary-button translucent">
                    <span className="text no-wrap">
                      {t('cardActivity:upgradeButton')}
                    </span>
                  </button>
                </form>
              }
            >
              <Trans
                i18nKey="cardSubtask:tip"
                components={{
                  tip: <span />,
                  currentLimit: <strong />,
                  increaseLimit: <span />,
                  newLimit: <strong />,
                  perCard: <span />,
                  plan: <span />,
                  period: <span />,
                }}
                values={{
                  currentLimit: freeSubtaskLimit,
                  newLimit: proSubtaskLimit,
                }}
              />
            </MessageBar>
          )}
      </>
    );
  };

  const isAtLimit = (totalSubtasks: number): boolean => {
    const teamSubtaskLimit = parseInt(
      process.env.REACT_APP_TEAM_SUBTASK_LIMIT
        ? process.env.REACT_APP_TEAM_SUBTASK_LIMIT
        : '0',
    );
    const proSubtaskLimit = parseInt(
      process.env.REACT_APP_PRO_SUBTASK_LIMIT
        ? process.env.REACT_APP_PRO_SUBTASK_LIMIT
        : '0',
    );
    const freeSubtaskLimit = parseInt(
      process.env.REACT_APP_FREE_SUBTASK_LIMIT
        ? process.env.REACT_APP_FREE_SUBTASK_LIMIT
        : '0',
    );

    if (boardCTX.board.teamId) return totalSubtasks >= teamSubtaskLimit;
    if (boardCTX.board.paid) return totalSubtasks >= proSubtaskLimit;
    return totalSubtasks >= freeSubtaskLimit;
  };

  const parseTitleWithLinks = (title: string) => {
    const regex = new RegExp(`#${cardNrPrefix}-(\\d+)`, 'g');

    return title.split(regex).reduce<React.ReactNode[]>((acc, part, index) => {
      if (index % 2 === 0) {
        // Regular text part
        acc.push(
          <span
            key={`text-${index}`}
            className="text-sm"
          >
            {part}
          </span>,
        );
      } else {
        // This part is the card number from our capture group
        const cardNumber = part;
        const card: ICard | undefined = boardCTX.board.columns
          .flatMap((column) => column.cards)
          .find((c) => c.number.toString() === cardNumber);

        if (card) {
          acc.push(
            <ContextMenu
              dept={0}
              key={`context-${index}`}
              triggerContent={
                <button className="link-button text-sm">
                  <span
                    className="text"
                    title={card.title}
                  >
                    {' '}
                    {`#${cardNrPrefix}-${cardNumber}`}
                  </span>
                </button>
              }
              triggerClassDefault="link-button text-sm"
              contextMenuClassName="align-v-start"
            >
              <LinkButton
                to={`/board/${boardCTX.board.id}/view/card/${card.id}`}
              >
                <LegacyBoardCard
                  boardCard={card}
                  key={`board-card-${index}`}
                  selected={false}
                  disabled={true}
                  cardNrPrefix={cardNrPrefix}
                  columnId={null}
                  tags={boardCTX.board.tags}
                  priorities={boardCTX.board.priorities}
                  members={boardCTX.board.members}
                  setCardRef={() => {
                    return false;
                  }}
                  selectCard={() => {
                    return false;
                  }}
                  isPeekView={true}
                />
              </LinkButton>
            </ContextMenu>,
          );
        } else {
          acc.push(
            <span
              key={`text-sm missing-${index}`}
            >{`#${cardNrPrefix}-${cardNumber}`}</span>,
          );
        }
      }
      return acc;
    }, []);
  };

  const completedCount = subtasks.filter((subtask) => subtask.checked).length;
  const totalSubtasks = subtasks.length;
  const progressPercentage =
    totalSubtasks > 0 ? (completedCount / totalSubtasks) * 100 : 0;

  if (loading)
    return (
      <div className="flex-row accordion-row fill">
        <div className="column pb-xs pl-0">
          <div className="flex-v-center">
            <span className="loader"></span>
            <span className="ml-2xs faint-text">{t('loading.subtasks')}</span>
          </div>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="flex-row accordion-row fill">
        <div className="column pb-xs pl-0">
          <MessageBar
            type="danger"
            icon="fas fa-exclamation-triangle"
            rightContent={
              <Button
                className="secondary-button translucent"
                onClick={fetchSubtasks}
              >
                {t('retry')}
              </Button>
            }
          >
            <span className="text">{error}</span>
          </MessageBar>
        </div>
      </div>
    );

  return (
    <>
      {subtasks.length > 0 && (
        <div className="flex-row accordion-row fill">
          <div className="column pb-xs">
            <div
              className="progress-bar-component mb-xs"
              title={`${progressPercentage.toFixed(2)}%`}
              data-title={`${progressPercentage.toFixed(2)}%`}
            >
              <div
                className="segmentation"
                style={{
                  backgroundImage: `repeating-linear-gradient(
                    to right,
                    transparent,
                    transparent calc((100% / ${subtasks.length}) - 3px),
                    #aaa calc((100% / ${subtasks.length}) - 3px),
                    #aaa calc(100% / ${subtasks.length})
                  )`,
                }}
              ></div>
              <div className="track pe-none">
                <div
                  className="progress"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
            </div>
            <div className="flex-h-spread">
              <small className="faint-text">
                {t('progress.completedCount', { completedCount })}
              </small>
              <small className="faint-text">
                {t('progress.remainingCount', {
                  remainingCount: totalSubtasks - completedCount,
                })}
              </small>
            </div>
          </div>
        </div>
      )}
      <div className="accordion-row">
        <div className="flex-row fill">
          <div className="column flex-h-start flex-v-start pb-xs">
            {!disabled && !isAtLimit(subtasks.length) && (
              <>
                <TextBox
                  value={newSubtaskTitle}
                  onChange={(e) => setNewSubtaskTitle(e.target.value)}
                  placeholder={t('placeholders.enterSubtaskTitle')}
                  error={validationError as string}
                  label={t('labels.subtaskTitle')}
                  srOnly={true}
                  type="text"
                  name="subtaskTitle"
                  id="subtaskTitle"
                  formGroupClassNames="mt-2xs mb-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAddSubtask();
                    }
                    if (e.key === 'Escape' && newSubtaskTitle) {
                      e.stopPropagation();
                      setNewSubtaskTitle('');
                    }
                  }}
                />
                <Button
                  className="mt-2xs ml-xs primary-button"
                  onClick={handleAddSubtask}
                  disabled={!newSubtaskTitle}
                >
                  {t('buttons.addSubtask')}
                </Button>
              </>
            )}
          </div>
        </div>
        {renderLimitUpsale()}
        <div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="subtasks">
              {(provided: DroppableProvided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {subtasks.length > 0 && (
                    <>
                      <div className="flex-row">
                        <div className="column large">
                          <ul className="control-list-component vertical">
                            {subtasks.map((subtask, index) => (
                              <Draggable
                                key={subtask.id}
                                draggableId={subtask.id}
                                index={index}
                              >
                                {(
                                  provided: DraggableProvided,
                                  snapshot: DraggableStateSnapshot,
                                ) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={subtask.id}
                                    className={unite({
                                      fill: true,
                                      'subtask-drag-helper': true,
                                      dragging: snapshot.isDragging,
                                    })}
                                  >
                                    <div className="flex-v-center">
                                      <span className="fas fa-grip-vertical faint-text"></span>
                                      <div className="card py-xs px-xs opaque flex-v-center fill">
                                        {editingSubtaskId === subtask.id ? (
                                          <>
                                            <TextBox
                                              autoFocus={true}
                                              value={editedTitle}
                                              onChange={(e) =>
                                                setEditedTitle(e.target.value)
                                              }
                                              placeholder={t(
                                                'placeholders.editSubtaskTitle',
                                              )}
                                              className="subtask-input"
                                              error={validationError as string}
                                              label={t('labels.subtaskTitle')}
                                              srOnly={true}
                                              type="text"
                                              name="editSubtaskTitle"
                                              id="editSubtaskTitle"
                                              formGroupClassNames="mb-0"
                                              onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                  handleEditSubtask(subtask.id);
                                                }
                                                if (e.key === 'Escape') {
                                                  e.stopPropagation();
                                                  setEditingSubtaskId(null);
                                                }
                                              }}
                                            />
                                            <Button
                                              className="ml-xs primary-button text-center flex-h-center"
                                              onClick={() =>
                                                handleEditSubtask(subtask.id)
                                              }
                                            >
                                              <div className="pe-none">
                                                <span className="fas fa-check"></span>
                                              </div>
                                            </Button>
                                            <Button
                                              className="ml-xs ghost-button text-center flex-h-center"
                                              onClick={() =>
                                                setEditingSubtaskId(null)
                                              }
                                            >
                                              <div className="pe-none">
                                                <span className="fas fa-times"></span>
                                              </div>
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            <CheckBox
                                              className="mb-0 soft-disabled"
                                              id={subtask.id}
                                              name={subtask.title}
                                              checked={subtask.checked}
                                              disabled={disabled}
                                              changeMethod={() =>
                                                handleSubtaskToggle(subtask.id)
                                              }
                                              label={
                                                <span className="text-break-word">
                                                  {parseTitleWithLinks(
                                                    subtask.title,
                                                  )}
                                                </span>
                                              }
                                              indent={0}
                                              labelClassName="mx-xs fill"
                                            />
                                            {!disabled && (
                                              <ContextMenu
                                                dept={0}
                                                triggerContent={
                                                  <span className="fas fa-ellipsis-h"></span>
                                                }
                                                triggerClassDefault="secondary-button flex-h-end-self"
                                                contextMenuClassName="align-v-start"
                                              >
                                                <>
                                                  <li>
                                                    <Button
                                                      className="ghost-button"
                                                      onClick={() => {
                                                        setEditingSubtaskId(
                                                          subtask.id,
                                                        );
                                                        setEditedTitle(
                                                          subtask.title,
                                                        );
                                                      }}
                                                    >
                                                      <span className="fal fa-pencil icon"></span>
                                                      <span className="text">
                                                        {t(
                                                          'buttons.editSubtask',
                                                        )}
                                                      </span>
                                                    </Button>
                                                  </li>
                                                  <li>
                                                    <Button
                                                      className="ghost-button"
                                                      onClick={() =>
                                                        setSubtaskToDelete(
                                                          subtask.id,
                                                        )
                                                      }
                                                    >
                                                      <span className="fal fa-trash icon"></span>
                                                      <span className="text">
                                                        {t(
                                                          'buttons.deleteSubtask',
                                                        )}
                                                      </span>
                                                    </Button>
                                                  </li>
                                                </>
                                              </ContextMenu>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </ul>
                        </div>

                        {subtaskToDelete && (
                          <Dialog
                            title={t('dialogs.subtaskDeletion.title')}
                            message={
                              <>
                                {t('dialogs.subtaskDeletion.message')}{' '}
                                <strong>
                                  {subtasks.find(
                                    (subtask: SubtaskDTO) =>
                                      subtask.id === subtaskToDelete,
                                  )?.title ?? ''}
                                </strong>{' '}
                                {t('dialogs.subtaskDeletion.subtask')}
                              </>
                            }
                            cancelText={t('dialogs.subtaskDeletion.cancel')}
                            confirmText={t('dialogs.subtaskDeletion.confirm')}
                            info={
                              <p className="text-sm faint-text">
                                <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
                                <span>{t('dialogs.subtaskDeletion.info')}</span>
                              </p>
                            }
                            onConfirm={handleRemoveSubtask}
                            onCancel={() => setSubtaskToDelete(null)}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
  );
};

export default CardSubtasks;
