/* eslint-disable react/display-name */
import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import 'react-virtualized/styles.css';
import { List } from 'react-virtualized';
import {
  Draggable,
  Droppable,
  type DraggableProvided,
  type DraggableStateSnapshot,
  type DroppableProvided,
  type DroppableStateSnapshot,
  type DraggableRubric,
} from '@wavemyth/react-beautiful-dnd';
import { borderRadius, grid } from './common/constants';
import BoardContext from '../../../common/contexts/BoardContext';
import VElementScrollbar from '../Scrollbars/VElementScrollbar';
import SurfaceItem from './SurfaceItem';
import BoardColumnHeader from '../board/BoardColumn/BoardColumnHeader';
import { ICard } from '../../../common/interfaces/BoardContext';
import BoardCardForm from '../board/BoardCard/BoardCardForm';
import AppContext from '../../../common/contexts/AppContext';
import { unite } from '../../../common/helpers/unite';
import { TCardData } from '../../../common/types/CardData';
import scrollToItem from '../../../common/helpers/scrollToItem';

type SurfaceColumnProps = {
  columnId: string;
  numericIndex: number;
  listItems: ICard[];
  boardDropProvided: DroppableProvided;
  boardDropSnapshot: DroppableStateSnapshot;
  isDragging?: boolean;
};
type CardRefs = Record<string, HTMLElement | null>;

const getBackgroundColor = (isDraggingOver: boolean): string =>
  isDraggingOver ? 'rgba(255, 255, 255, 0.22)' : 'transparent';

const useRowRenderer = (
  listItems: ICard[],
  columnId: string,
  handleExpandColumn: (columnId: string) => void,
  cardRefs: React.MutableRefObject<CardRefs>,
) =>
  useCallback(
    ({ index, style }) => {
      const item = listItems[index];
      if (!item) return null;

      const patchedStyle = {
        ...style,
        left: style.left || 0,
        top: style.top || 0,
        width: `calc(${style.width} - ${grid}px)`,
        height: (style.height || 0) - grid,
      };

      return (
        <Draggable
          draggableId={item.id}
          index={index}
          key={item.id}
        >
          {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
            <SurfaceItem
              columnId={columnId}
              onExpandColumn={handleExpandColumn}
              itemDragProvided={provided}
              listItem={item}
              isDragging={snapshot.isDragging}
              style={patchedStyle}
              onCardRef={(ref) => {
                if (ref.current) {
                  cardRefs.current[item.id] = ref.current;
                }
              }}
            />
          )}
        </Draggable>
      );
    },
    [listItems, columnId, handleExpandColumn, cardRefs],
  );

const SurfaceColumn: React.FC<SurfaceColumnProps> = React.memo(
  ({ columnId, listItems, numericIndex, isDragging }) => {
    const {
      board,
      updateColumn,
      addCard,
      removeColumn,
      removeCardsFromColumn,
      cardPermalinkEntry,
    } = useContext(BoardContext);
    const appCTX = useContext(AppContext);
    const columnDTO = board.columns.find((column) => column.id === columnId);
    const [columnHeight, setColumnHeight] = useState(window.innerHeight - 146);
    const storageKey = `${appCTX.loggedUser?.id}-${board.id}-collapsedColumns`;
    const cardRefs = useRef<CardRefs>({});

    const [collapsedColumns, setCollapsedColumns] = useState<{
      [key: string]: boolean;
    }>(() => {
      return JSON.parse(localStorage.getItem(storageKey) || '{}');
    });
    const isCollapsed = collapsedColumns[columnId] || false;

    const handleCollapseColumn = useCallback(() => {
      const localStorageCollapsedColumns = JSON.parse(
        localStorage.getItem(storageKey) || '{}',
      );
      const newCollapsedColumns = {
        ...localStorageCollapsedColumns,
        [columnId]: true,
      };
      if (appCTX.loggedUser) {
        localStorage.setItem(storageKey, JSON.stringify(newCollapsedColumns));
        setCollapsedColumns(newCollapsedColumns);
      }
    }, [storageKey, columnId, appCTX.loggedUser, collapsedColumns]);

    const calculateRowHeight = useCallback(() => {
      let height = 77;
      const displayedCardData = board.cardData;
      if (displayedCardData) {
        if (
          [
            'description',
            'cardNumber',
            'priority',
            'comment',
            'attachment',
            'subtask',
          ].some((key) => displayedCardData.includes(key as TCardData))
        ) {
          height += 20;
        }
        if (displayedCardData.includes('tag')) height += 55;
        if (displayedCardData.includes('subtaskProgress')) height += 5;
        if (displayedCardData.includes('member')) height += 35;
      }
      return height;
    }, [columnDTO, board.cardData]);

    const handleExpandColumn = useCallback(() => {
      const localStorageCollapsedColumns = JSON.parse(
        localStorage.getItem(storageKey) || '{}',
      );
      const newCollapsedColumns = {
        ...localStorageCollapsedColumns,
        [columnId]: false,
      };
      if (appCTX.loggedUser) {
        localStorage.setItem(storageKey, JSON.stringify(newCollapsedColumns));
        setCollapsedColumns(newCollapsedColumns);
      }
    }, [storageKey, columnId, appCTX.loggedUser, collapsedColumns]);

    if (!columnDTO) return null;

    useEffect(() => {
      const handleResize = () => setColumnHeight(window.innerHeight - 146);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const rowRenderer = useRowRenderer(
      listItems,
      columnId,
      handleExpandColumn,
      cardRefs,
    );
    const dropDisabled =
      board.columnCardLimitState === 'enforced' &&
      columnDTO.cardLimit !== null &&
      listItems.length >= columnDTO.cardLimit!;

    // Add new state to hold effectiveDropDisabled value
    const [effectiveDropDisabledState, setEffectiveDropDisabledState] =
      useState(dropDisabled);

    // Create ref for the virtualized List
    const listRef = useRef<List>(null);

    // Guard to ensure the list expand and scroll effect runs only once on entry
    const didPermalinkEntry = useRef(false);

    useEffect(() => {
      if (didPermalinkEntry.current) return;
      didPermalinkEntry.current = true;
      const index = listItems.findIndex(
        (item) => item.id === cardPermalinkEntry,
      );

      if (index < 0) return;
      if (!cardPermalinkEntry) {
        return;
      }

      if (isCollapsed) {
        handleExpandColumn();
        return;
      }
      if (!listRef.current) return;

      listRef.current.scrollToRow(index);
      let frameId: number;
      const waitForRef = () => {
        const ref = cardRefs.current[cardPermalinkEntry];
        if (ref) {
          scrollToItem(ref);
        } else {
          frameId = requestAnimationFrame(waitForRef);
        }
      };
      frameId = requestAnimationFrame(waitForRef);
      return () => cancelAnimationFrame(frameId);
    }, []);

    // Inline updater component to lift the computed effectiveDropDisabled value
    const EffectiveDropDisabledUpdater: React.FC<{ value: boolean }> = ({
      value,
    }) => {
      useEffect(() => {
        setEffectiveDropDisabledState(value);
      }, [value]);
      return null;
    };

    return (
      <Draggable
        draggableId={columnId}
        index={numericIndex}
      >
        {(columnDragProvided, columnDragSnapshot) => (
          <div
            ref={columnDragProvided.innerRef}
            {...columnDragProvided.draggableProps}
            style={{ ...columnDragProvided.draggableProps.style }}
          >
            <div
              className={unite({
                'card-board-list': true,
                'collapsed-list': isCollapsed,
              })}
            >
              <div
                className="list-drag-head px-xs"
                {...columnDragProvided.dragHandleProps}
              >
                <BoardColumnHeader
                  boardColumn={columnDTO}
                  columnCardLimitState={board.columnCardLimitState}
                  setUpdatedColumn={updateColumn}
                  setDeletedColumn={removeColumn}
                  setAllArchivedCards={removeCardsFromColumn}
                  setMessages={appCTX.notifications.setMessages}
                  handleCollapseColumn={handleCollapseColumn}
                  isCollapsed={isCollapsed}
                  handleExpandColumn={handleExpandColumn}
                />
              </div>
              {isCollapsed ? (
                <Droppable
                  droppableId={columnId}
                  isDropDisabled={board.columnCardLimitState === 'enforced'}
                >
                  {(
                    columnDropProvided: DroppableProvided,
                    columnDropSnapshot: DroppableStateSnapshot,
                  ) => (
                    <div
                      className={unite({
                        'list-drag-helper': true,
                      })}
                      {...columnDropProvided.droppableProps}
                      ref={columnDropProvided.innerRef}
                      style={{
                        pointerEvents: 'none',
                        width: 'calc(100dvh - 144px)',
                        backgroundColor: getBackgroundColor(
                          columnDropSnapshot.isDraggingOver ||
                            columnDragSnapshot.isDragging,
                        ),
                      }}
                    >
                      {columnDropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              ) : (
                <div className="list-drag-helper">
                  <Droppable
                    droppableId={columnId}
                    mode="virtual"
                    // Pass computed effectiveDropDisabled state here
                    isDropDisabled={effectiveDropDisabledState}
                    renderClone={(
                      cardDragProvided: DraggableProvided,
                      cardDragSnapshot: DraggableStateSnapshot,
                      rubric: DraggableRubric,
                    ) => (
                      <SurfaceItem
                        columnId={columnId}
                        onExpandColumn={handleExpandColumn}
                        itemDragProvided={cardDragProvided}
                        isDragging={cardDragSnapshot.isDragging}
                        listItem={listItems[rubric.source.index]}
                        style={{ margin: 0 }}
                        onCardRef={(ref) => {
                          if (ref.current) {
                            const listItem = listItems[rubric.source.index];
                            cardRefs.current[listItem.id] = ref.current;
                          }
                        }}
                      />
                    )}
                  >
                    {(
                      columnDropProvided: DroppableProvided,
                      columnDropSnapshot: DroppableStateSnapshot,
                    ) => {
                      // Compute effectiveDropDisabled here
                      const computedEffectiveDropDisabled =
                        dropDisabled &&
                        !columnDropSnapshot.draggingFromThisWith;
                      return (
                        <>
                          <EffectiveDropDisabledUpdater
                            value={computedEffectiveDropDisabled}
                          />
                          <VElementScrollbar offsetY={48}>
                            <List
                              className={unite({
                                'static-border-danger-sticky': Boolean(
                                  board.columnCardLimitState !== 'disabled' &&
                                    columnDTO.cardLimit &&
                                    columnDTO.cardLimit <
                                      columnDTO.cards.length,
                                ),
                              })}
                              height={columnHeight}
                              rowCount={
                                columnDropSnapshot.isUsingPlaceholder
                                  ? listItems.length + 1
                                  : listItems.length
                              }
                              rowHeight={calculateRowHeight()}
                              width={316}
                              ref={(ref) => {
                                (
                                  listRef as React.MutableRefObject<List | null>
                                ).current = ref;
                                // Temporary workaround: react-virtualized doesn't forward refs.
                                // eslint-disable-next-line react/no-find-dom-node
                                const node = ReactDOM.findDOMNode(ref);
                                if (node instanceof HTMLElement)
                                  columnDropProvided.innerRef(node);
                              }}
                              style={{
                                borderRadius,
                                paddingTop: grid,
                                paddingLeft: grid,
                                backgroundColor: getBackgroundColor(
                                  columnDropSnapshot.isDraggingOver ||
                                    columnDragSnapshot.isDragging,
                                ),
                                background:
                                  isDragging && effectiveDropDisabledState
                                    ? 'repeating-linear-gradient(45deg, rgba(255,0,0,0.2), rgba(255,0,0,0.2) 5px, transparent 5px, transparent 10px)'
                                    : '',
                                transition: 'background-color 0.2s ease',
                              }}
                              rowRenderer={rowRenderer}
                            />
                          </VElementScrollbar>
                        </>
                      );
                    }}
                  </Droppable>
                  <BoardCardForm
                    boardColumn={columnDTO}
                    columnCardLimitState={board.columnCardLimitState}
                    setNewCard={addCard}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Draggable>
    );
  },
);

export default SurfaceColumn;
