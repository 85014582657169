import React, { useEffect, useRef, useCallback, forwardRef } from 'react';

const HorizontalDrag = forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode }
>(({ children }, ref) => {
  // useRef hooks for mutable state
  const isDraggingRef = useRef(false);
  const lastXRef = useRef(0);
  const lastYRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const scrollTopRef = useRef(0);
  const isRightClickRef = useRef(false);
  const scrollVelocityRef = useRef(0);
  const isScrollingRef = useRef(false);
  const animationFrameIdRef = useRef<number>(0);

  const handleMouseDown = useCallback((e: MouseEvent) => {
    if (e.buttons === 2 || e.buttons !== 1) return;
    const HTMLTag = document.documentElement;
    isDraggingRef.current = true;
    lastXRef.current = e.clientX;
    lastYRef.current = e.clientY;
    scrollLeftRef.current = HTMLTag.scrollLeft;
    scrollTopRef.current = HTMLTag.scrollTop;
  }, []);

  const handleMouseUp = useCallback(() => {
    if (isRightClickRef.current) {
      isRightClickRef.current = false;
      return;
    }
    isDraggingRef.current = false;
  }, []);

  const handleMouseLeave = useCallback(() => {
    isDraggingRef.current = false;
    isRightClickRef.current = false;
  }, []);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!isDraggingRef.current) return;
    const HTMLTag = document.documentElement;
    const deltaX = e.clientX - lastXRef.current;
    const deltaY = e.clientY - lastYRef.current;
    HTMLTag.scrollLeft = scrollLeftRef.current - deltaX;
    HTMLTag.scrollTop = scrollTopRef.current - deltaY;
  }, []);

  const animateScroll = useCallback(() => {
    const HTMLTag = document.documentElement;
    HTMLTag.scrollLeft += scrollVelocityRef.current;
    // Apply friction
    const friction = 0.9;
    scrollVelocityRef.current *= friction;
    // Clamp scroll position
    const maxScrollLeft = HTMLTag.scrollWidth - HTMLTag.clientWidth;
    if (HTMLTag.scrollLeft < 0) {
      HTMLTag.scrollLeft = 0;
      scrollVelocityRef.current = 0;
    } else if (HTMLTag.scrollLeft > maxScrollLeft) {
      HTMLTag.scrollLeft = maxScrollLeft;
      scrollVelocityRef.current = 0;
    }
    // Continue animating if velocity thresholds are met
    if (Math.abs(scrollVelocityRef.current) > 0.2) {
      animationFrameIdRef.current = requestAnimationFrame(animateScroll);
    } else {
      isScrollingRef.current = false;
      scrollVelocityRef.current = 0;
    }
  }, []);

  const handleWindowScroll = useCallback(
    (e: WheelEvent) => {
      if (!isRightClickRef.current) return;
      e.preventDefault();
      const delta = e.deltaY;
      scrollVelocityRef.current += delta * 0.05;
      const maxVelocity = 20;
      scrollVelocityRef.current = Math.max(
        -maxVelocity,
        Math.min(scrollVelocityRef.current, maxVelocity),
      );
      if (!isScrollingRef.current) {
        isScrollingRef.current = true;
        animationFrameIdRef.current = requestAnimationFrame(animateScroll);
      }
    },
    [animateScroll],
  );

  const handleWindowMouseDown = useCallback((e: MouseEvent) => {
    if (e.buttons === 2) {
      e.preventDefault();
      isRightClickRef.current = true;
    }
  }, []);

  const handleWindowMouseUp = useCallback(() => {
    if (isRightClickRef.current) {
      isRightClickRef.current = false;
      return;
    }
    isDraggingRef.current = false;
  }, []);

  useEffect(() => {
    // Attach listener to the scroll container
    const scrollElement = document.querySelector(
      '.fe-scroll-container',
    ) as HTMLDivElement | null;
    const onScrollMouseDown = (e: MouseEvent) => {
      // Check if target is part of an inner draggable element
      const innerDraggable = Array.from(
        document.querySelectorAll(
          '.board-column-card > [data-rbd-draggable-id], .list-drag-head, .virtual-custom-scrollbar-thumb, .custom-scrollbar-thumb, .root-scrollbar-thumb, .surface-card-board-button',
        ),
      );
      const isInnerDragging = innerDraggable.some((element) =>
        element.contains(e.target as Node),
      );
      if (!isInnerDragging) {
        handleMouseDown(e);
      }
    };
    if (scrollElement) {
      scrollElement.addEventListener('mousedown', onScrollMouseDown);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('mousedown', onScrollMouseDown);
      }
    };
  }, [handleMouseDown]);

  useEffect(() => {
    // Attach global event listeners
    window.addEventListener('wheel', handleWindowScroll, { passive: false });
    window.addEventListener('mousedown', handleWindowMouseDown);
    window.addEventListener('mouseup', handleWindowMouseUp);
    const mainElement = document.querySelector(
      '.main-component',
    ) as HTMLDivElement | null;
    if (mainElement) {
      mainElement.addEventListener('mouseup', handleMouseUp);
      mainElement.addEventListener('mouseleave', handleMouseLeave);
      mainElement.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('wheel', handleWindowScroll);
      window.removeEventListener('mousedown', handleWindowMouseDown);
      window.removeEventListener('mouseup', handleWindowMouseUp);
      if (mainElement) {
        mainElement.removeEventListener('mouseup', handleMouseUp);
        mainElement.removeEventListener('mouseleave', handleMouseLeave);
        mainElement.removeEventListener('mousemove', handleMouseMove);
      }
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
    };
  }, [
    handleWindowScroll,
    handleWindowMouseDown,
    handleWindowMouseUp,
    handleMouseUp,
    handleMouseLeave,
    handleMouseMove,
  ]);

  return <div ref={ref}>{children}</div>;
});

HorizontalDrag.displayName = 'HorizontalDrag';
export default HorizontalDrag;
