import React from 'react';
import { BoardItemDTO } from '../../../../common/api/dtos/Board';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import BoardListingCard from '../../../partials/board/BoardListing/BoardListingCard';
import NoBoardsMessage from './NoBoardsMessage';
import ArchivedBoardsPage from '../../Board/BoardArchive/ArchivedBoardsPage';
import ErrorList from '../../../error/ErrorList/ErrorList';
import SubscriptionStatus from './SubscriptionStatus';
import UtilityTeamBar from './UtilityTeamBar';
import ContentLoader from '../../../partials/ContentLoader/ContentLoader';
import { TRequestStatus } from '../../../../common/types/RequestStatus';

interface TeamBoardsSectionProps {
  team: TeamDTO;
  boards: BoardItemDTO[];
  showArchive: boolean;
  serverErrors: string[];
  toggleArchiveView: (teamId: string) => void;
  hideShelf: (teamId: string) => void;
  status: TRequestStatus;
  callback?: () => void;
}

const TeamSpaceShelf: React.FC<TeamBoardsSectionProps> = ({
  team,
  boards,
  showArchive,
  serverErrors,
  toggleArchiveView,
  hideShelf,
  status,
  callback,
}) => {
  return (
    <div className="flex-row fill">
      <div className="column">
        <div className="flex-row no-reflow fill flex-v-center flex-h-spread">
          <div className="column py-2xs">
            <h2 className="primary-title h3 normalcase multiline-ellipsis l2">
              <span>{team.name}</span>
              <ErrorList errors={serverErrors} />
            </h2>
          </div>
          <UtilityTeamBar
            boards={boards}
            team={team}
            showArchive={showArchive}
            toggleArchiveView={toggleArchiveView}
            hideShelf={hideShelf}
          />
        </div>
        <SubscriptionStatus team={team} />
        {status === 'loading' && !showArchive ? (
          <div className="flex-row card-list-component">
            <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
              <ContentLoader height="96px" />
            </div>
            <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
              <ContentLoader height="96px" />
            </div>
            <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
              <ContentLoader height="96px" />
            </div>
          </div>
        ) : showArchive ? (
          <ArchivedBoardsPage
            teamId={team.id}
            toggleArchiveView={() => toggleArchiveView(team.id)}
          />
        ) : boards && boards.length > 0 ? (
          <div className="flex-row card-list-component pt-0 fade-in-grow">
            {boards.map((board: BoardItemDTO) => (
              <div
                key={board.id}
                className="column px-2xs pt-xs pb-0 flex-v-stretch"
                style={{ height: '104px' }}
              >
                <BoardListingCard
                  board={board}
                  subscription={team.subscription}
                  disabled={
                    team.subscription.deleteAt !== null ||
                    (team.subscription.paymentRetryUntil !== null &&
                      team.subscription.paymentState === 'failed')
                  }
                  callback={() => {
                    callback && callback();
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoBoardsMessage
            disabled={
              (team.role !== 'admin' && team.role !== 'owner') ||
              team.subscription.period !== 'active'
            }
            team={team}
          />
        )}
      </div>
    </div>
  );
};

export default TeamSpaceShelf;
