import React, {
  ChangeEvent,
  FormEvent,
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { PRIORITY_COLORS } from '../../../common/configs/priority';
import Button from '../../controls/Button';
import TextBox from '../../controls/TextBox';
import { AddPriorityDTO, PriorityDTO } from '../../../common/api/dtos/Priority';
import { UserDTO } from '../../../common/api/dtos/User';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import Joi from 'joi';

interface FormData {
  id?: string;
  name: string;
  color: string;
}

interface Props extends WithTranslation {
  legendText: string;
  selectedPriority?: PriorityDTO | null;
  handleSubmit: (
    e: FormEvent<HTMLFormElement>,
    priorityData: AddPriorityDTO | PriorityDTO,
  ) => void;
  loggedUser: UserDTO;
  setSelectedContext?: (context: TContextMenu) => void;
}

const schema = Joi.object({
  id: Joi.string().allow(''),
  name: Joi.string()
    .pattern(/^[a-zA-Z0-9 ~\-'.]*$/)
    .min(1)
    .trim()
    .required()
    .messages({
      'string.pattern.base':
        "The name can only contain letters, numbers, spaces, apostrophes ('), hyphens (-), and periods (.)",
      'string.empty': 'The name cannot be empty',
      'any.required': 'The name is required',
    }),
  color: Joi.string().required(),
});

const PriorityForm: FC<Props> = ({
  t,
  legendText,
  selectedPriority,
  handleSubmit: parentHandleSubmit,
  loggedUser,
  setSelectedContext,
  children,
}) => {
  const defaultColor = useMemo(() => {
    return (
      localStorage.getItem(`${loggedUser.id}-lastPriorityColor`) ||
      PRIORITY_COLORS[0].id
    );
  }, [loggedUser.id]);

  const [formData, setFormData] = useState<FormData>({
    id: selectedPriority?.id || '',
    name: selectedPriority?.name || '',
    color: selectedPriority?.color || defaultColor,
  });
  const [formErrors, setFormErrors] = useState<
    Partial<Record<keyof FormData, string>>
  >({});

  const validateForm = useCallback((): boolean => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const errors: Partial<Record<keyof FormData, string>> = {};
      error.details.forEach((detail) => {
        const key = detail.path[0] as keyof FormData;
        errors[key] = detail.message;
      });
      setFormErrors(errors);
      return false;
    }
    setFormErrors({});
    return true;
  }, [formData]);

  const updateFormData = useCallback(
    <K extends keyof FormData>(field: K, value: FormData[K]) => {
      setFormData((prev) => ({ ...prev, [field]: value }));
    },
    [],
  );

  const setPriorityName = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      updateFormData('name', ev.target.value);
    },
    [updateFormData],
  );

  const setPriorityColor = useCallback(
    (value: string) => {
      localStorage.setItem(`${loggedUser.id}-lastPriorityColor`, value);
      updateFormData('color', value);
    },
    [loggedUser.id, updateFormData],
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (validateForm()) {
        parentHandleSubmit(e, formData);
      }
    },
    [formData, parentHandleSubmit, validateForm],
  );

  const translatedColorOptions = useMemo(() => {
    return PRIORITY_COLORS.map((option) => ({
      ...option,
      label: t(`colorOptions.${option.color}`),
    }));
  }, [t]);

  return (
    <fieldset id="editPriorityForm">
      <legend>{legendText}</legend>
      <ul
        className="control-list-component"
        style={{ maxWidth: '240px' }}
      >
        {translatedColorOptions.map((color) => {
          const isSelected = formData.color === color.id;
          return (
            <li key={color.id}>
              <Button
                className={`secondary-button ${isSelected ? 'active selection-border' : ''}`}
                value={formData.color}
                onClick={() => setPriorityColor(color.id)}
                title={color.label}
              >
                <span className={`pe-none icon accent-text-${color.color}`}>
                  <span className={`${color.symbol}`}></span>
                </span>
              </Button>
            </li>
          );
        })}
      </ul>
      <div className="flex-row fill">
        <div className="column">
          <form onSubmit={handleSubmit}>
            <TextBox
              label={t('priorityNameLabel')}
              type="text"
              name="priorityName"
              id="priorityName"
              value={formData.name}
              onChange={setPriorityName}
              srOnly
              placeholder={t('priorityNameLabel')}
              error={formErrors.name}
              maxLength={30}
            />
            <ul className="control-list-component">
              <li>
                <Button
                  className="primary-button"
                  type="submit"
                >
                  {t('savePriorityButton')}
                </Button>
              </li>
              <li>
                <Button
                  className="ghost-button"
                  title={t('closeFormButton')}
                  onClick={() =>
                    setSelectedContext && setSelectedContext('managePriorities')
                  }
                >
                  <span className="fas fa-times pe-none"></span>
                </Button>
              </li>
            </ul>
          </form>
          {children}
        </div>
      </div>
    </fieldset>
  );
};

export default withStyledTranslation('priorityForm')(PriorityForm);
