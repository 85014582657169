/* eslint-disable react/display-name */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import 'react-virtualized/styles.css';
import { DropResult, DragDropContext } from '@wavemyth/react-beautiful-dnd';
import HorizontalDrag from '../HorizontalDrag/HorizontalDrag';
import SurfaceColumn from './SurfaceColumn';
import { ICard, IColumn } from '../../../common/interfaces/BoardContext';
import BoardContext from '../../../common/contexts/BoardContext';
import {
  showErrorNotifications,
  showInfoNotifications,
} from '../../../common/helpers/showNotifications';
import AppContext from '../../../common/contexts/AppContext';
import { tutorialAction_moveCardBetweenColumns } from '../../../common/helpers/tutorialActions';
import { Droppable, DroppableProvided } from '@wavemyth/react-beautiful-dnd';
import { moveColumn } from '../../../common/api/endpoints/column';
import { moveCard } from '../../../common/api/endpoints/card';
import { DroppableStateSnapshot } from 'react-beautiful-dnd';
import BoardColumnPlaceholder from '../board/BoardColumn/BoardColumnForm';
import Flyout from '../Flyout/Flyout';
import { useHistory, useLocation } from 'react-router-dom';
import { unite } from '../../../common/helpers/unite';
import { useItemSlug } from '../../../common/helpers/useItemSlug';
import { FlyoutCardDetails } from '../board/BoardCardFlyout/FlyoutCardDetails';
import { extractItemId } from '../../pages/Board/BoardProvider/BoardProvider';
import {
  applyBranding,
  setLocalStorageBoardColor,
} from '../../../common/helpers/boardColor';

const SurfaceBoard: React.FC = () => {
  const boardCTX = useContext(BoardContext);
  const appCTX = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const itemSlug = useItemSlug();
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    applyBranding(boardCTX.board?.id);
    setLocalStorageBoardColor(boardCTX.board?.id, boardCTX.board?.color);
  }, [boardCTX.board]);

  const onDragStart = useCallback((): void => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(10);
    }
  }, []);

  const handleColumnDrag = useCallback(
    async (draggableId: string, destinationIndex: number): Promise<void> => {
      boardCTX.moveColumn(draggableId, destinationIndex);
      try {
        await moveColumn(draggableId, destinationIndex);
      } catch (err) {
        console.debug(err);
        boardCTX.reloadBoard();
      }
    },
    [boardCTX],
  );

  useEffect((): void => {
    const itemId = extractItemId();
    if (itemId && boardCTX.openCardId !== itemId) {
      boardCTX.setOpenCard(itemId);
    }
  }, [location.pathname]);

  const handleCardDrag = useCallback(
    async (
      draggableId: string,
      source: { droppableId: string; index: number },
      destination: { droppableId: string; index: number },
      columnChanged: boolean,
    ): Promise<void> => {
      const destIndex = boardCTX.moveCard(
        draggableId,
        source.droppableId,
        source.index,
        destination.droppableId,
        destination.index,
      );
      try {
        let initialCardCount = 0;
        if (boardCTX.board.columnCardLimitState === 'enabled') {
          const column = boardCTX.board.columns.find(
            (column) => column.id === destination.droppableId,
          );
          initialCardCount = column?.visibleCards.length || 0;
        }

        await moveCard(draggableId, destination.droppableId, destIndex);

        if (boardCTX.board.columnCardLimitState === 'enabled') {
          const updatedColumn = boardCTX.board.columns.find(
            (column) => column.id === destination.droppableId,
          );
          const newCardCount = updatedColumn?.visibleCards.length || 0;
          if (
            updatedColumn?.cardLimit &&
            newCardCount > updatedColumn.cardLimit &&
            newCardCount > initialCardCount
          ) {
            appCTX.notifications.setMessages &&
              showInfoNotifications(
                'msg.info.cardLimitReached',
                appCTX.notifications.setMessages,
              );
          }
        }

        if (columnChanged) {
          tutorialAction_moveCardBetweenColumns(appCTX);
        }
      } catch (err) {
        appCTX.notifications.setMessages &&
          showErrorNotifications(err, appCTX.notifications.setMessages);
        boardCTX.reloadBoard();
      }
    },
    [boardCTX, appCTX],
  );

  const onDragEnd = useCallback(
    async (result: DropResult): Promise<void> => {
      const { destination, source, draggableId, type } = result;
      if (!destination) return;
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }
      if (type === 'column') {
        await handleColumnDrag(draggableId, destination.index);
      } else {
        const columnChanged = source.droppableId !== destination.droppableId;
        await handleCardDrag(draggableId, source, destination, columnChanged);
      }
    },
    [handleColumnDrag, handleCardDrag],
  );

  const boardCard = React.useMemo(() => {
    return boardCTX.board.columns
      .map((c) => c.visibleCards)
      .flat()
      .find((c) => c.id === boardCTX.openCardId);
  }, [boardCTX]) as ICard;

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          setIsDragging(false);
          onDragEnd(result);
        }}
        onDragStart={() => {
          setIsDragging(true);
          onDragStart();
        }}
      >
        <HorizontalDrag />
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
        >
          {(
            boardDropProvided: DroppableProvided,
            boardDropSnapshot: DroppableStateSnapshot,
          ) => (
            <div
              className={unite({
                'flyout-sibling-inner': true,
                'fe-scroll-container': true,
                'flyout-open': boardCTX.openCardId !== null,
              })}
              style={{ display: 'flex' }}
              ref={boardDropProvided.innerRef}
              {...boardDropProvided.droppableProps}
            >
              {boardCTX.board?.columns.map((column: IColumn, index) => (
                <div key={column.id}>
                  <SurfaceColumn
                    numericIndex={index}
                    listItems={column.visibleCards}
                    columnId={column.id}
                    boardDropProvided={boardDropProvided}
                    boardDropSnapshot={boardDropSnapshot}
                    isDragging={isDragging}
                  />
                </div>
              ))}
              {boardDropProvided.placeholder}
              <BoardColumnPlaceholder
                setNewColumn={boardCTX.addColumn}
                routeBoardId={boardCTX.board.id}
                hasAccess={
                  boardCTX.board.user.role === 'owner' ||
                  boardCTX.board.user.role === 'admin'
                }
              />
              <div className="flyout-padder"></div>
            </div>
          )}
        </Droppable>
        {boardCTX.openCardId && boardCard && (
          <Flyout
            showFlyout={true}
            flyoutAnimation={true}
            pushback={isDragging}
          >
            <FlyoutCardDetails
              boardCard={boardCard}
              cardNrPrefix={boardCTX.board.cardNrPrefix}
              onClose={() => {
                itemSlug.removeItemSlug(boardCTX.board.name);
                boardCTX.setOpenCard(null);
              }}
              columnTitles={boardCTX.board.columns.map((c) => {
                return {
                  id: c.id,
                  title: c.title,
                };
              })}
              history={history}
              location={location}
              clipboardData={null}
              setUploadClipboardData={() => {
                false;
              }}
              uploadClipboardData={false}
            />
          </Flyout>
        )}
      </DragDropContext>
    </>
  );
};

export default SurfaceBoard;
