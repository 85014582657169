import { History, Location } from 'history';
import Joi from 'joi';
import {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { LoginUserDTO } from '../../../common/api/dtos/User';
import { login } from '../../../common/api/endpoints/user';
import { FormErrorMsgs } from '../../../common/configs/FormErrors';
import { ErrorNotificationPayload } from '../../../common/helpers/errorNotificationPayload';
import { processJoiError } from '../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Button from '../../controls/Button';
import TextBox from '../../controls/TextBox';
import { customEmailValidation } from '../../pages/Auth/Registration/helper/customEmailValidation';
import { t } from 'i18next';
import errorKeyFormatter from '../../../common/helpers/errorKeyFormatter';

interface Props {
  unlockApp: () => void;
  history: History;
  location: Location;
}

interface FormData {
  email: string;
  password: string;
}

type FormErrors = Partial<Record<keyof FormData, string>>;

const LoginForm: FC<Props> = ({ unlockApp }) => {
  // Access context if needed

  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [formStatus, setFormStatus] = useState<TRequestStatus>('idle');

  // Memoize the Joi schema definition.
  const formSchema = useMemo(() => {
    return Joi.object({
      email: Joi.string()
        .required()
        .trim(true)
        .email({ minDomainSegments: 2, tlds: { allow: false } })
        .custom(customEmailValidation)
        .messages(FormErrorMsgs.string),
      password: Joi.string().required().messages(FormErrorMsgs.string),
    });
  }, []);

  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const updateFormError = useCallback(
    <K extends keyof FormData>(field: K, value: string) => {
      setFormErrors((prev) => ({ ...prev, [field]: value }));
    },
    [],
  );

  const validateFormField = useCallback(
    <K extends keyof FormData>(field: K) => {
      const subSchema = formSchema.extract(field);
      const result = subSchema.validate(formData[field], { abortEarly: false });
      updateFormError(field, result.error ? result.error.message : '');
    },
    [formData, formSchema, updateFormError],
  );

  const validateForm = useCallback(() => {
    setFormErrors({});
    const result = formSchema.validate(formData, { abortEarly: false });
    if (result.error) {
      const errors = processJoiError(result.error) as FormErrors;
      setFormErrors(errors);
      return false;
    }
    return true;
  }, [formData, formSchema]);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (!validateForm()) return;

      setFormStatus('loading');
      setServerErrors([]);

      const body: LoginUserDTO = {
        email: formData.email,
        password: formData.password,
      };

      try {
        const loginData = await login(body);
        const sessionExpirationMs = Date.now() + loginData.expiresIn;
        localStorage.setItem(
          'session_expiration_ms',
          String(sessionExpirationMs),
        );
        unlockApp();
      } catch (err) {
        const errorMsg = errorKeyFormatter(
          (err as ErrorNotificationPayload[])[0],
        );
        setServerErrors((prev) => [...prev, errorMsg]);
        setFormStatus('error');
      }
    },
    [formData, validateForm, unlockApp],
  );

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
    >
      <TextBox
        label="Email"
        type="email"
        name="email"
        id="email"
        disabled={formStatus === 'loading'}
        onChange={handleInput}
        required
        br
        onBlur={({ target }) =>
          validateFormField(target.name as keyof FormData)
        }
        error={formErrors.email}
      />
      <TextBox
        label="Password"
        type="password"
        name="password"
        id="password"
        disabled={formStatus === 'loading'}
        onChange={handleInput}
        required
        br
        onBlur={({ target }) =>
          validateFormField(target.name as keyof FormData)
        }
        error={formErrors.password}
      />
      <Button
        type="submit"
        className="primary-button"
        disabled={formStatus === 'loading'}
        status={formStatus}
      >
        <span className="text">Log in</span>
      </Button>
      {serverErrors.length > 0 && (
        <ul
          role="alert"
          aria-live="assertive"
          className="error-list light flex-h-center"
        >
          {serverErrors.map((err) => (
            <li
              key={err}
              className="flex-v-center text-center"
            >
              <span className="text">{t(err)}</span>
            </li>
          ))}
        </ul>
      )}
    </form>
  );
};

export default LoginForm;
