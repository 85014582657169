import { Component } from 'react';
import Accordion from '../../controls/Accordion';
import { TeamDTO } from '../../../common/api/dtos/Team';
import { deleteTeam } from '../../../common/api/endpoints/team';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import convertBytes from '../../../common/helpers/convertBytes';
import { NavLink } from 'react-router-dom';
import { BoardDTO } from '../../../common/api/dtos/Board';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import LinkButton from '../../controls/LinkButton';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { Trans, WithTranslation } from 'react-i18next';
import MessageBar from '../../controls/MessageBar';
import SubscriptionMessage from '../../pages/Settings/Billing/SubscriptionMessage';
interface Props extends WithTranslation {
  data: TeamDTO | null;
  status: TRequestStatus;
  teamBoards: BoardDTO[];
  loadingTeamBoards: TRequestStatus;
  history: any;
}

interface State {
  deleteStatus: TRequestStatus;
}

export class TeamHousekeeping extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      deleteStatus: 'idle',
    };
  }

  handleSubmit = async () => {
    try {
      this.setState({
        deleteStatus: 'loading',
      });

      await deleteTeam(this.props.data!.id);

      this.setState({
        deleteStatus: 'success',
      });
    } catch (err) {
      this.setState({
        deleteStatus: 'error',
      });
      console.error(err);
    }
  };

  renderSize = (size: number) => {
    const temp = convertBytes(size);
    return temp.value + ' ' + temp.unit;
  };

  renderOcuppiedStorage = (usedStorage: number) => {
    return (
      (usedStorage * 100) / Number(process.env.REACT_APP_TEAM_STORAGE_LIMIT)
    );
  };

  computeStorageLimit = (planType: 'team' | 'pro' | 'free'): number => {
    switch (planType) {
      case 'team':
        return process.env.REACT_APP_TEAM_STORAGE_LIMIT
          ? Number(process.env.REACT_APP_TEAM_STORAGE_LIMIT)
          : 0;
      default:
        return process.env.REACT_APP_FREE_STORAGE_LIMIT
          ? Number(process.env.REACT_APP_FREE_STORAGE_LIMIT)
          : 0;
    }
  };

  renderAttachmentSpace = (board: BoardDTO) => {
    const { t } = this.props;
    const STORAGE_LIMIT = this.computeStorageLimit('team');

    return (
      <div className="accordion-row py-sm">
        <div className="flex-row fill">
          <div className="column">
            <p className="mb-xs">{board.name}</p>
            <div
              className="progress-bar-component mb-xs"
              title={`${this.renderOcuppiedStorage(board.storageUsage!)}%`}
            >
              <div className="track pe-none">
                <div
                  className="progress"
                  style={{
                    width: `${this.renderOcuppiedStorage(
                      board.storageUsage!,
                    )}%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="flex-h-spread">
              <small className="faint-text">
                {this.renderSize(board.storageUsage!)} {t('used')}
              </small>
              <small className="faint-text">
                {this.renderSize(STORAGE_LIMIT - board.storageUsage!)}{' '}
                {t('free')}
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="flex-row fill">
          <div className="column py-0">
            <Accordion
              accordionSlug="team-storage"
              isOpen={false}
              iconClasses="fal fa-database icon"
              title={t('storageTitle')}
              subheading={t('storageSubheading')}
              disabled={false}
              softDisabled={false}
              hideArrow={false}
              loading={this.props.loadingTeamBoards === 'loading'}
            >
              {this.props.teamBoards.length ? (
                this.props.teamBoards.map((board) => {
                  return this.renderAttachmentSpace(board);
                })
              ) : (
                <div className="accordion-row py-sm">
                  <p className="text-sm">{t('noBoardsMessage')}</p>
                  <LinkButton
                    to={
                      this.props.data
                        ? `/board-add/${this.props.data.id}/${this.props.data.name}`
                        : '/board-add'
                    }
                    className="secondary-button"
                  >
                    {t('createTeamBoard')}
                  </LinkButton>
                </div>
              )}
            </Accordion>
            <Accordion
              accordionSlug="delete-team"
              isOpen={false}
              iconClasses="fal fa-backspace icon"
              title={t('deleteTeamTitle')}
              subheading={t('deleteTeamSubheading')}
              disabled={false}
              softDisabled={false}
              hideArrow={false}
              loading={this.props.status === 'loading'}
            >
              {this.props.data?.subscription.period == 'grace' ? (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component vertical">
                    <li>
                      <span>{t('currentPlanStatus')}</span>
                    </li>
                    <li>
                      <ul className="control-list-component">
                        <li>
                          <span className="badge-text accent-blue">
                            <span className="text-sm">{t('teamText')}</span>
                          </span>
                        </li>
                        <li>
                          <span className="flag-text accent-red">
                            <span className="text-sm">{t('expiredText')}</span>
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <SubscriptionMessage badge="danger">
                        <Trans
                          i18nKey="subscriptions:teamPlans.expirationWarning"
                          values={{
                            deleteDate: dayjsHelper(
                              this.props.data?.subscription.deleteAt,
                            ).format('MMMM Do, YYYY'),
                          }}
                          components={{ strong: <strong /> }}
                        />
                      </SubscriptionMessage>
                    </li>
                    <li>
                      <NavLink
                        to={`./plan-and-billing`}
                        className="secondary-button"
                      >
                        <span className="text">{t('goToPlanAndBilling')}</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              ) : this.props.data?.subscription.cancelAt &&
                this.props.data?.subscription.period == 'active' ? (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component vertical">
                    <li>
                      <span>{t('currentPlanStatus')}</span>
                    </li>
                    <li>
                      <ul className="control-list-component">
                        <li>
                          <span className="badge-text accent-blue">
                            <span className="text-sm">{t('teamText')}</span>
                          </span>
                        </li>
                        <li>
                          <span className="flag-text accent-yellow">
                            <span className="text-sm">
                              {t('cancelledText')}
                            </span>
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="pt-xs">
                      <MessageBar
                        type="warning"
                        icon="fas fa-exclamation-circle"
                      >
                        <Trans
                          i18nKey="teamHousekeeping:cancelledSubscription"
                          values={{
                            date: dayjsHelper(
                              this.props.data.subscription.cancelAt,
                            ).format('MMMM Do, YYYY'),
                          }}
                          components={{ strong: <strong /> }}
                        />
                      </MessageBar>
                      <div className="flex-row">
                        <div className="column medium">
                          <p className="text-sm">
                            <Trans
                              i18nKey="teamHousekeeping:gracePeriodWarning"
                              values={{
                                date: dayjsHelper(
                                  this.props.data.subscription.cancelAt,
                                )
                                  .add(30, 'day')
                                  .format('MMMM Do, YYYY'),
                              }}
                              components={{ strong: <strong /> }}
                            />
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <NavLink
                        to={`./plan-and-billing`}
                        className="secondary-button"
                      >
                        <span className="text">{t('goToPlanAndBilling')}</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              ) : this.props.data?.subscription.period == 'suspended' ? (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component vertical">
                    <li>
                      <span>{t('currentPlanStatus')}</span>
                    </li>
                    <li>
                      <ul className="control-list-component">
                        <li>
                          <span className="badge-text accent-blue">
                            <span className="text-sm">{t('teamText')}</span>
                          </span>
                        </li>
                        <li>
                          <span className="flag-text accent-red">
                            <span className="text-sm">
                              {t('suspendedText')}
                            </span>
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <MessageBar
                        type="warning"
                        icon="fas fa-exclamation-circle"
                      >
                        <Trans
                          i18nKey="teamHousekeeping:paymentFailedWarning"
                          values={{
                            retryUntil: dayjsHelper(
                              this.props.data.subscription.paymentRetryUntil,
                            ).format('MMMM Do, YYYY'),
                          }}
                          components={{
                            strong: <strong />,
                            br: <br />,
                          }}
                        />
                      </MessageBar>
                    </li>
                    <li>
                      <NavLink
                        to={`./plan-and-billing`}
                        className="secondary-button"
                      >
                        <span className="text">{t('goToPlanAndBilling')}</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component vertical">
                    <li>
                      <span>{t('currentPlanStatus')}</span>
                    </li>
                    <li>
                      <ul className="control-list-component">
                        <li>
                          <span className="badge-text accent-blue">
                            <span className="text-sm">{t('teamText')}</span>
                          </span>
                        </li>
                        <li>
                          <span className="flag-text accent-green">
                            <span className="text-sm">{t('activeText')}</span>
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="pt-xs">
                      <MessageBar
                        type="info"
                        icon="fas fa-info-circle"
                      >
                        <Trans
                          i18nKey="teamHousekeeping:activeSubscriptionWarning"
                          components={{ strong: <strong /> }}
                        />
                      </MessageBar>
                      <div className="flex-row">
                        <div className="column medium">
                          <p className="text-sm">
                            {t('gracePeriodWarningGeneric')}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <NavLink
                        to={`./plan-and-billing`}
                        className="secondary-button"
                      >
                        <span className="text">{t('goToPlanAndBilling')}</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('teamHousekeeping')(TeamHousekeeping);
