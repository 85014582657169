import React, { Component } from 'react';
import Accordion from '../../controls/Accordion';
import { TeamDTO } from '../../../common/api/dtos/Team';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import {
  inviteMemberToTeam,
  removeMemberFromTeam,
  searchTeamUsers,
  updateMemberRole,
  updateTeamSettings,
} from '../../../common/api/endpoints/team';
import Joi from 'joi';
import TextBox from '../../controls/TextBox';
import Button from '../../controls/Button';
import { InviteeDTO, MemberDTO } from '../../../common/api/dtos/Member';
import ManageTeamMember from './ManageTeamMember';
import { Trans, WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { customEmailValidation } from '../../pages/Auth/Registration/helper/customEmailValidation';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import { NavLink } from 'react-router-dom';
import MessageBar from '../../controls/MessageBar';
import BorddoUser from '../BorddoUser';
import SubscriptionMessage from '../../pages/Settings/Billing/SubscriptionMessage';
import { t } from 'i18next';

interface Props extends WithTranslation {
  data: TeamDTO | null;
  fetchTeam: () => Promise<void>;
}

interface State {
  status: TRequestStatus;
  serverErrors: string[];
  isValidEmail: boolean;
  isValidEmailMember: boolean;
  isValidHandle: boolean;
  searchUsers: (MemberDTO | InviteeDTO)[] | null;
  memberQuery: string;
}
export class TeamMembers extends Component<Props, State> {
  emailSchema = Joi.string()
    .required()
    .trim(true)
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .custom(customEmailValidation);

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      serverErrors: [],
      isValidEmail: false,
      isValidEmailMember: false,
      isValidHandle: false,
      searchUsers: null,
      memberQuery: '',
    };
  }

  validateEmail = (email: string) => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    return emailRegex.test(email);
  };

  handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');

    const cleanedText = pastedText.replace(/\s+/g, '');

    if (this.validateEmail(cleanedText)) {
      this.setMemberQuery(cleanedText);
    } else {
      this.setMemberQuery(pastedText);
    }
  };

  renderMessageBar = () => {
    const { subscription } = this.props.data || {};
    if (subscription === null || typeof subscription === 'undefined')
      return <></>;

    if (subscription.period === 'grace') {
      return (
        <SubscriptionMessage badge="danger">
          <Trans
            i18nKey="teamMembers:restrictMessage"
            values={{
              deleteDate: dayjsHelper(
                this.props.data?.subscription.deleteAt,
              ).format('MMMM Do, YYYY'),
            }}
            components={{ strong: <strong /> }}
          />
        </SubscriptionMessage>
      );
    } else if (subscription.period === 'active' && subscription.cancelAt) {
      return (
        <SubscriptionMessage badge="warning">
          <Trans
            i18nKey="teamMembers:cancelMessage"
            values={{
              date: dayjsHelper(this.props.data?.subscription.cancelAt).format(
                'MMMM Do, YYYY',
              ),
              seats: this.props.data?.seats,
            }}
            components={{ strong: <strong /> }}
          />
        </SubscriptionMessage>
      );
    } else if (subscription.period === 'suspended') {
      return (
        <SubscriptionMessage badge="warning">
          {this.props.data?.owned ? (
            <form
              target="_blank"
              method="POST"
              action={`${process.env.REACT_APP_API_BASE_URL}/team/${this.props.data?.id}/updatePaymentMethod`}
            >
              <Trans
                i18nKey="teamMembers:paymentIssueMessage"
                values={{
                  endDate: dayjsHelper(
                    this.context.loggedUser?.subscription?.paymentRetryUntil,
                  ).format('MMMM Do, YYYY'),
                }}
                components={{
                  Button: (
                    <Button
                      type="submit"
                      className="link-button"
                    />
                  ),
                  span: <span className="text"></span>,
                }}
              />
            </form>
          ) : (
            t('teamMembers:paymentFailedMessageUnowned')
          )}
        </SubscriptionMessage>
      );
    }
  };

  searchMembers = async () => {
    try {
      const search = await searchTeamUsers(
        this.props.data?.id || '',
        this.state.memberQuery,
      );
      let searchMembers: (MemberDTO | InviteeDTO)[] = [];

      if (Object.prototype.hasOwnProperty.call(search, 'invitees')) {
        for (const invitee of search.invitees) {
          invitee.invited = true;
        }
      }

      if (
        Object.prototype.hasOwnProperty.call(search, 'invitees') &&
        Object.prototype.hasOwnProperty.call(search, 'members')
      ) {
        searchMembers = [...search.invitees, ...search.members];
      }

      if (search.isValidHandle) {
        this.setState({
          isValidHandle: true,
          searchUsers: [],
        });
      } else {
        this.setState({
          isValidHandle: false,
          searchUsers: searchMembers,
        });
      }
    } catch (err) {
      this.setState({
        isValidHandle: false,
        searchUsers: [],
      });
    }
  };

  inviteMember = async () => {
    this.setState({
      status: 'loading',
    });

    try {
      await inviteMemberToTeam(
        this.props.data?.id || '',
        this.state.memberQuery,
      );
      this.setState({
        status: 'success',
        memberQuery: '',
      });

      this.props.fetchTeam();
      this.searchMembers();
    } catch (err) {
      this.setState({
        status: 'error',
        memberQuery: '',
      });
    }
  };

  updateTeamMemberRole = async (id: string, role: string) => {
    this.setState({
      status: 'loading',
    });

    try {
      await updateMemberRole(id, role);

      this.setState({
        status: 'success',
      });

      this.props.fetchTeam();
      this.searchMembers();
    } catch (err) {
      this.setState({
        status: 'error',
      });
    }
  };

  removeMember = async (id: string) => {
    this.setState({
      status: 'loading',
    });

    try {
      await removeMemberFromTeam(id);

      this.setState({
        status: 'success',
      });

      this.props.fetchTeam();
      this.searchMembers();
    } catch (err) {
      this.setState({
        status: 'error',
      });
    }
  };

  handleInputEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.state.isValidEmail && e.key === 'Enter') {
      this.inviteMember();
    }
  };

  setIsValidEmail = () => {
    const result = this.emailSchema.validate(this.state.memberQuery, {
      abortEarly: false,
    });

    this.setState({
      isValidEmail: !result.error,
    });
  };

  setMemberQuery = (value: string) => {
    this.setState(
      {
        memberQuery: value,
      },
      () => {
        this.setIsValidEmail();
        this.searchMembers();
      },
    );
  };

  getSearchLabel = () => {
    let label = 'name';

    if (this.state.memberQuery.startsWith('@')) {
      label = 'handle';
    } else if (this.state.isValidEmail) {
      label = 'email';
    }

    return label;
  };

  purchaseAndInvite = async () => {
    if (this.props.data) {
      try {
        await updateTeamSettings(this.props.data.id, {
          name: this.props.data.name,
          seats: this.props.data.seats + 1,
        });

        this.inviteMember();

        this.setState({
          status: 'success',
        });
      } catch (err) {
        this.setState({
          status: 'error',
        });
      }
    }
  };

  renderNoSeatsInvite = () => {
    const { t } = this.props;
    return (
      <>
        {this.props.data?.owned ? (
          <>
            <div className="flex-row fill">
              <div className="column">
                <MessageBar
                  type="warning"
                  icon="fas fa-exclamation-circle"
                  rightContent={
                    <NavLink
                      to="general#team-seats"
                      className={'secondary-button translucent no-wrap'}
                    >
                      {t('noSeatsLeftButton')}
                    </NavLink>
                  }
                >
                  <span>{t('noSeatsLeftText')}</span>
                </MessageBar>
              </div>
            </div>
            {this.props.data?.subscription.cancelAt && (
              <p className="text-sm">
                {t('reactivateMessage', {
                  date: dayjsHelper(
                    this.props.data?.subscription.cancelAt,
                  ).format('MMMM Do'),
                  seats: this.props.data?.seats,
                })}
              </p>
            )}
          </>
        ) : (
          <>
            <Button
              className="primary-button mt-xs flex-h-start"
              disabled={true}
            >
              <>
                <span className="icon fas fa-user-plus"></span>
                <span className="text">
                  <span className="faint-text-green">{t('inviteText')}</span>{' '}
                  <span>{t('userText')}</span>
                  <span className="faint-text-green"> </span>
                </span>
              </>
            </Button>
            <div className="flex-row">
              <div className="column medium">
                <p className="flag-text-blue px-xs py-xs">
                  <span className="fas fa-exclamation-circle accent-text-blue"></span>{' '}
                  <span>{t('noSeatsLeftAdminText')}</span>
                </p>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  renderInviteUser = (isValidQuery: boolean, availableSeats: number) => {
    const { t } = this.props;
    return (
      <>
        <Button
          className="primary-button mt-xs flex-h-start"
          disabled={
            !isValidQuery ||
            this.state.status === 'loading' ||
            Boolean(this.props.data?.subscription.deleteAt)
          }
          onClick={this.inviteMember}
        >
          <>
            <span className="icon fas fa-user-plus"></span>
            <span className="text">
              <span className="faint-text-green">{t('inviteText')}</span>{' '}
              <span>{t('userText')}</span>
              <span className="faint-text-green"> </span>
            </span>
          </>
        </Button>
        <div className="flex-row">
          <div className="column medium">
            <p className="flag-text-green px-xs py-xs">
              <span className="fas fa-info-circle accent-text-green"></span>{' '}
              <span>{t('availableSeatsText', { count: availableSeats })}</span>
            </p>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { t } = this.props;
    const members = this.state.searchUsers || [];
    const isAlreadyATeamMember = members?.some((member) => {
      if ('email' in member) {
        return member.email === this.state.memberQuery;
      }

      return Boolean(member.avatar);
    });

    const isValidQuery =
      (this.state.isValidEmail || this.state.isValidHandle) &&
      !isAlreadyATeamMember;

    let availableSeats = 0;

    if (this.props.data) {
      availableSeats =
        this.props.data?.seats -
        this.props.data?.members.length -
        this.props.data?.invitees.length;
    }

    return (
      <>
        <div className="flex-row fill">
          <div className="column py-0">
            <Accordion
              accordionSlug="team-members"
              isOpen={true}
              iconClasses="fal fa-users icon"
              title={t('teamMembersTitle')}
              subheading={t('manageTeamMembersSubheading')}
              disabled={true}
              softDisabled={true}
              hideArrow={true}
            >
              <div className="accordion-row py-sm">
                <div className="flex-row">
                  <div className="column">
                    <p className="faint-text text-sm">
                      {t('filterOrInviteMembersText')}
                    </p>
                  </div>
                </div>
                <TextBox
                  type="text"
                  name="userSearch"
                  id="userSearch"
                  value={this.state.memberQuery}
                  onChange={(e) => this.setMemberQuery(e.target.value)}
                  srOnly={true}
                  label={t('nameEmailLabel')}
                  placeholder={t('nameEmailLabel')}
                  onKeyDown={this.handleInputEnterKey}
                  autoComplete="off"
                  ariaAutocomplete="none"
                  formGroupClassNames="mb-xs"
                  onPaste={this.handlePaste}
                />
                {this.renderMessageBar()}
                <div className="tableview-component fill table-h-scroll pt-xs">
                  <table>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="fill">
                          <span>{t('nameLabel')}</span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.memberQuery &&
                        members.map((member, index) => {
                          return (
                            <tr key={index}>
                              <td></td>
                              <td>
                                <BorddoUser
                                  avatarData={member.avatar}
                                  title={
                                    member.name || (member as InviteeDTO).email
                                  }
                                />
                              </td>
                              <td>
                                <ManageTeamMember
                                  member={member}
                                  subscription={
                                    this.props.data?.subscription ?? null
                                  }
                                  removeMember={this.removeMember}
                                  updateTeamMemberRole={
                                    this.updateTeamMemberRole
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      {!this.state.memberQuery &&
                        this.props.data?.members.map((member, index) => {
                          return (
                            <tr key={index}>
                              <td></td>
                              <td>
                                <BorddoUser
                                  avatarData={member.avatar}
                                  title={
                                    member.name || (member as InviteeDTO).email
                                  }
                                />
                              </td>
                              <td>
                                <ManageTeamMember
                                  member={member}
                                  subscription={
                                    this.props.data?.subscription ?? null
                                  }
                                  removeMember={this.removeMember}
                                  updateTeamMemberRole={
                                    this.updateTeamMemberRole
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      {!this.state.memberQuery &&
                        this.props.data?.invitees.map((member, index) => {
                          return (
                            <tr key={index}>
                              <td></td>
                              <td>
                                <BorddoUser
                                  avatarData={member.avatar}
                                  title={
                                    member.name || (member as InviteeDTO).email
                                  }
                                />
                              </td>
                              <td>
                                <ManageTeamMember
                                  member={member}
                                  removeMember={this.removeMember}
                                  subscription={
                                    this.props.data?.subscription ?? null
                                  }
                                  updateTeamMemberRole={
                                    this.updateTeamMemberRole
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {this.state.memberQuery && !members.length && (
                  <div className="fill">
                    <p className="faint-text separator text-chunk mt-sm text-sm">
                      <MessageBar
                        type="info"
                        icon="fas fa-info-circle"
                      >
                        {t('noMembersFoundText', {
                          searchLabel: this.getSearchLabel(),
                        })}
                      </MessageBar>
                      {availableSeats <= 0 && isValidQuery
                        ? this.renderNoSeatsInvite()
                        : availableSeats >= 1 &&
                          isValidQuery &&
                          this.renderInviteUser(isValidQuery, availableSeats)}
                    </p>
                  </div>
                )}
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('teamMembers')(TeamMembers);
