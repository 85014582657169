import React, { useContext } from 'react';
import { PriorityDTO } from '../../../../../common/api/dtos/Priority';
import ContextMenu from '../../../../controls/ContextMenu';
import { getColorSymbol } from '../../../../../common/helpers/getColorSymbol';
import { PRIORITY_COLORS } from '../../../../../common/configs/priority';
import RemovePriorityMenu from './partial/RemovePriorityMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../StyledTranslation/StyledTranslation';
import BoardContext from '../../../../../common/contexts/BoardContext';
import { TCardData } from '../../../../../common/types/CardData';

interface Props extends WithTranslation {
  cardId: string;
  title: string;
  number: number;
  prefix: string;
  description: string;
  priority?: PriorityDTO;
  numberOfAttachments?: number;
  numberOfComments?: number;
  maxLines?: number;
  subtasks?: {
    checked: number;
    unchecked: number;
  };
}

const BoardCardDescription: React.FC<Props> = ({
  cardId,
  title,
  number,
  prefix,
  description,
  priority,
  maxLines,
  numberOfAttachments = 0,
  numberOfComments = 0,
  subtasks = { checked: 0, unchecked: 0 },
  t,
}) => {
  // Inline computed values
  const cardIdString = prefix ? `#${prefix}-${number}` : `#${number}`;
  const symbol = getColorSymbol(PRIORITY_COLORS, priority?.color || '');
  const color = PRIORITY_COLORS.find((c) => c.id === priority?.color)?.color;
  const boardCTX = useContext(BoardContext);
  const cardData = boardCTX.board.cardData;

  // Calculate subtask progress
  const totalSubtasks = subtasks.checked + subtasks.unchecked;
  const progressPercentage =
    totalSubtasks > 0 ? (subtasks.checked / totalSubtasks) * 100 : 0;

  // Helper functions returning JSX per selectedCardData condition
  const renderPrioritySection = () => {
    if (!cardData.includes('priority')) return null;
    return priority ? (
      <ContextMenu
        dept={0}
        contextId={String(number)}
        contextMenuClassName="align-h-start"
        triggerClassDefault="ghost-button"
        triggerClassActive="secondary-button"
        isDisabled={!priority}
        title={priority.name}
        triggerContent={
          <div
            className={`${symbol} pe-none accent-text-${color} priority-icon pr-2xs`}
          ></div>
        }
        rightClickTrigger={true}
      >
        <RemovePriorityMenu cardId={cardId} />
      </ContextMenu>
    ) : (
      <div className="fal fa-circle pe-none priority-icon pr-2xs"></div>
    );
  };

  const renderCardNumber = () => {
    if (cardData.includes('cardNumber')) {
      return <small className="faint-text no-wrap">{cardIdString}</small>;
    }
    return null;
  };

  const renderDescriptionIcon = () => {
    if (cardData.includes('description') && description) {
      return (
        <li
          className="far fa-text"
          title={t('hasDescription')}
        ></li>
      );
    }
    return null;
  };

  const renderAttachmentIcon = () => {
    if (cardData.includes('attachment') && numberOfAttachments >= 1) {
      return (
        <li
          className="far fa-paperclip"
          title={t('hasAttachments', { count: numberOfAttachments })}
        ></li>
      );
    }
    return null;
  };

  const renderCommentIcon = () => {
    if (cardData.includes('comment') && numberOfComments > 0) {
      return (
        <li
          className="fal fa-comment"
          title={t('hasComments', { count: numberOfComments })}
        ></li>
      );
    }
    return null;
  };

  const renderSubtaskIcon = () => {
    if (cardData.includes('subtask') && totalSubtasks !== 0) {
      return (
        <li
          className="fal fa-tasks"
          title={t('hasSubtasks', { count: totalSubtasks })}
        ></li>
      );
    }
    return null;
  };

  const renderSubtaskProgress = () => {
    if (cardData.includes('subtaskProgress') && totalSubtasks > 0) {
      return (
        <div
          className="progress-bar-component translucent mt-xs mb-xs"
          title={`${progressPercentage.toFixed(2)}%`}
          data-title={`${progressPercentage.toFixed(2)}%`}
          style={{ width: '100%' }}
        >
          <div
            className="segmentation"
            style={{
              backgroundImage: `repeating-linear-gradient(
                    to right,
                    transparent,
                    transparent calc((100% / ${totalSubtasks}) - 2px),
                    #aaa calc((100% / ${totalSubtasks}) - 2px),
                    #aaa calc(100% / ${totalSubtasks})
                  )`,
              transform: `translateX(2px)`,
            }}
          ></div>
          <div className="track pe-none">
            <div
              className="progress"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
        </div>
      );
    }
    return null;
  };

  const isTopBarVisible = [
    'comment',
    'priority',
    'description',
    'cardNumber',
    'attachment',
    'subtask',
  ].some((key) => cardData.includes(key as TCardData));

  return (
    <>
      {isTopBarVisible && (
        <div className="pb-2xs flex-v-center">
          {renderPrioritySection()}
          {renderCardNumber()}
          <ul className="icon-list-component flex-h-end">
            {renderDescriptionIcon()}
            {renderAttachmentIcon()}
            {renderCommentIcon()}
            {renderSubtaskIcon()}
          </ul>
        </div>
      )}
      <div className={maxLines ? `multiline-ellipsis l${maxLines}` : ''}>
        {title}
      </div>

      {renderSubtaskProgress()}
    </>
  );
};

const MemoizedBoardCardDescription = React.memo(BoardCardDescription);

export default withStyledTranslation('boardCardDescription')(
  MemoizedBoardCardDescription,
);
