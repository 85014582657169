import selectTutorial from './selectTutorial';
import { updateTutorialStep } from './tutorialHelper';
import { IAppContext } from '../contexts/AppContext';

export async function tutorialAction_moveCardBetweenColumns(
  appCTX: IAppContext,
) {
  const loggedUser = appCTX.loggedUser;
  if (loggedUser) {
    const userType = selectTutorial(loggedUser);
    const stepId =
      userType === 'solo' || userType === 'invited'
        ? 'move_cards_between_columns'
        : 'move_cards_between_columns_team';

    await updateTutorialStep(
      loggedUser,
      userType,
      stepId,
      appCTX.notifications.setMessages
        ? appCTX.notifications.setMessages
        : () => {
            return false;
          },
      appCTX.updateClientData
        ? appCTX.updateClientData
        : () => {
            return false;
          },
    );
  }
}
