import React from 'react';
import SurfaceBoard from './SurfaceBoard';
import BoardTopbar from '../Topbar/BoardTopbar';

const Surface: React.FC = () => {
  return (
    <div className="card-board-component">
      <BoardTopbar
        enableFilter={true}
        toggleFavorite={() => false}
      />
      <SurfaceBoard />
    </div>
  );
};

export default Surface;
