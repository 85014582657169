import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  $createTextNode,
  $isTextNode,
  $isElementNode,
  PASTE_COMMAND,
  COMMAND_PRIORITY_LOW,
} from 'lexical';
import { useEffect } from 'react';
import { $createLinkNode, $isLinkNode } from '@lexical/link';

const BREAK_CHARS = [' ', '\n', ',', '!', ';', ':', ')'];

const URL_MATCHER = {
  regex:
    /\b((?:https?:\/\/)?(?:localhost|\d{1,3}(?:\.\d{1,3}){3}|(?:www\.)?[\w-]+(?:\.[\w-]+)+)(?::\d+)?(?:\/[^\s]*)?)/i,

  createNode: (text: string) => {
    const url = text.startsWith('http') ? text : `https://${text}`;
    const node = $createLinkNode(url);
    node.append($createTextNode(text));
    return node;
  },
  isNode: $isLinkNode,
};

export function SmartLinkifyPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const cleanupRootListener = editor.registerRootListener((rootElement) => {
      if (!rootElement) return;

      const handleKeyDown = (e: KeyboardEvent) => {
        if (!BREAK_CHARS.includes(e.key)) return;

        editor.update(() => {
          runLinkifyOnCursor();
        });
      };

      rootElement.addEventListener('keydown', handleKeyDown);
      return () => {
        rootElement.removeEventListener('keydown', handleKeyDown);
      };
    });

    const cleanupPaste = editor.registerCommand(
      PASTE_COMMAND,
      () => {
        setTimeout(() => {
          editor.update(() => {
            runLinkifyOnAllTextNodes();
          });
        }, 0);
        return false; // allow paste to continue
      },
      COMMAND_PRIORITY_LOW,
    );

    return () => {
      cleanupRootListener?.();
      cleanupPaste();
    };
  }, [editor]);

  const runLinkifyOnCursor = () => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) return;

    const anchor = selection.anchor;
    const node = anchor.getNode();
    if (!$isTextNode(node)) return;

    const offset = anchor.offset;
    const text = node.getTextContent();

    const left = text.slice(0, offset);
    const lastWordMatch = left.match(/[\S]+$/);
    if (!lastWordMatch) return;

    const fullWord = lastWordMatch[0];
    const wordStart = offset - fullWord.length;
    const wordEnd = offset;

    const match = URL_MATCHER.regex.exec(fullWord);
    if (!match || match.index !== 0 || match[0].length !== fullWord.length)
      return;

    const fullMatch = match[0];
    const parent = node.getParent();
    if (!parent || !$isElementNode(parent)) return;
    if (URL_MATCHER.isNode(parent)) return;

    const before = text.slice(0, wordStart);
    const after = text.slice(wordEnd);

    const linkNode = URL_MATCHER.createNode(fullMatch);
    const beforeNode = before ? $createTextNode(before) : null;
    const afterNode = after ? $createTextNode(after) : null;

    node.replace(linkNode);
    if (beforeNode) linkNode.insertBefore(beforeNode);
    if (afterNode) linkNode.insertAfter(afterNode);
    if (afterNode) afterNode.selectStart();
    else linkNode.selectEnd();
  };

  const runLinkifyOnAllTextNodes = () => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) return;

    const anchor = selection.anchor;

    const visited = new Set();
    const nodes = anchor.getNode().getTopLevelElementOrThrow().getChildren();

    nodes.forEach((node) => {
      if (!$isTextNode(node)) return;
      const text = node.getTextContent();
      const match = URL_MATCHER.regex.exec(text);
      if (!match || match.index !== 0 || match[0].length !== text.length)
        return;

      if (visited.has(node.getKey())) return;
      visited.add(node.getKey());

      const fullMatch = match[0];
      const parent = node.getParent();
      if (!parent || !$isElementNode(parent)) return;
      if (URL_MATCHER.isNode(parent)) return;

      const linkNode = URL_MATCHER.createNode(fullMatch);
      node.replace(linkNode);
    });
  };

  return null;
}
