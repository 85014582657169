import React, { useState, useRef, useEffect, useCallback } from 'react';
import { TRequestStatus } from '../../../../../common/types/RequestStatus';
import { AttachmentDTO } from '../../../../../common/api/dtos/Card';
import { NotificationMessage } from '../../../../../common/contexts/NotificationsContext';
import { showErrorNotifications } from '../../../../../common/helpers/showNotifications';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../StyledTranslation/StyledTranslation';

interface CardFileInputProps extends WithTranslation {
  cardId: string;
  disabled?: boolean;
  droppedAttachments: File[] | null;
  handleNewAttachment: (newAttachment: AttachmentDTO) => void;
  uploadClipboardData: boolean;
  clipboardData: File | null;
  setUploadClipboardData: (value: boolean) => void;
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  clearDroppedAttachments: () => void;
}

const CardFileInput: React.FC<CardFileInputProps> = ({
  cardId,
  disabled,
  droppedAttachments,
  handleNewAttachment,
  uploadClipboardData,
  clipboardData,
  setUploadClipboardData,
  setMessages,
  clearDroppedAttachments,
  t,
}) => {
  const [formStatus, setFormStatus] = useState<TRequestStatus>('idle');
  const [loader, setLoader] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // Helper: Make an XMLHttpRequest with progress events.
  const xmlHttpRequest = useCallback(
    (cardId: string, formData: FormData): Promise<XMLHttpRequest> => {
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.withCredentials = true;

        req.addEventListener('progress', (event) => {
          console.debug('download progress', event);
        });
        req.addEventListener('load', () => {
          console.debug('download load');
          resolve(req);
        });
        req.addEventListener('error', (event) => {
          console.debug('download error', event);
          reject(event);
        });
        req.addEventListener('abort', (event) => {
          console.debug('download abort', event);
          reject(event);
        });

        req.upload.addEventListener('progress', (event) => {
          console.debug('upload progress', event);
          setLoader(true);
        });
        req.upload.addEventListener('load', () => {
          console.debug('upload load');
        });
        req.upload.addEventListener('error', (event) => {
          console.debug('upload error', event);
          reject(event);
        });
        req.upload.addEventListener('abort', (event) => {
          console.debug('upload abort', event);
          reject(event);
        });

        req.open(
          'POST',
          `${process.env.REACT_APP_API_BASE_URL}/card/${cardId}/attachment`,
        );
        const conId = sessionStorage.getItem('borddo-wsconid');
        if (conId) {
          req.setRequestHeader('borddo-wsconid', conId);
        }
        req.send(formData);
      });
    },
    [cardId],
  );

  // Upload a single attachment.
  const uploadAttachment = useCallback(
    async (file: File) => {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await xmlHttpRequest(cardId, formData);

        if (response.status === 413) {
          showErrorNotifications(
            [{ message: 'File is too large' }],
            setMessages,
          );
        } else if (response.status === 400) {
          const responseJSON = JSON.parse(response.responseText);
          showErrorNotifications(responseJSON.errors, setMessages);
        } else if (response.status >= 200 && response.status < 300) {
          const newAttachment = JSON.parse(
            response.responseText,
          ) as AttachmentDTO;
          handleNewAttachment(newAttachment);
        } else {
          showErrorNotifications(
            [{ message: 'An error occurred during the upload' }],
            setMessages,
          );
        }
      } catch (error) {
        console.error(error);
        setFormStatus('error');
        showErrorNotifications(
          [{ message: (error as Error).message }],
          setMessages,
        );
      }
    },
    [cardId, handleNewAttachment, setMessages, xmlHttpRequest],
  );

  // Helper: Upload multiple files and update form status accordingly.
  const uploadFiles = useCallback(
    async (files: File[]) => {
      setFormStatus('loading');
      const uploadPromises = files.map((file) =>
        uploadAttachment(file).catch(() => null),
      );
      const results = await Promise.allSettled(uploadPromises);
      const allSuccessful = results.every(
        (result) => result.status === 'fulfilled',
      );
      setFormStatus(allSuccessful ? 'success' : 'error');
      setLoader(false);
    },
    [uploadAttachment],
  );

  // Event handler for file input change.
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = event.currentTarget; // persist the reference
    if (target.files && target.files.length > 0) {
      const files = Array.from(target.files);
      await uploadFiles(files);
      // reset input using the stored target reference
      target.value = '';
    }
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  // Paste event handler.
  const handlePaste = useCallback(
    async (event: ClipboardEvent) => {
      const items = event.clipboardData?.items;
      if (items) {
        const filePromises = Array.from(items)
          .filter((item) => item.kind === 'file')
          .map((item) => {
            const file = item.getAsFile();
            return file ? uploadAttachment(file).catch(() => null) : null;
          })
          .filter(Boolean) as Promise<unknown>[];
        const results = await Promise.allSettled(filePromises);
        const allSuccessful = results.every(
          (result) => result.status === 'fulfilled',
        );
        setFormStatus(allSuccessful ? 'success' : 'error');
        setLoader(false);
      }
    },
    [uploadAttachment],
  );

  // Add and remove paste event listener.
  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);

  // Handle uploadClipboardData prop change.
  useEffect(() => {
    (async () => {
      if (uploadClipboardData) {
        if (clipboardData) {
          await uploadAttachment(clipboardData);
        }
        setUploadClipboardData(false);
      }
    })();
  }, [
    uploadClipboardData,
    clipboardData,
    setUploadClipboardData,
    uploadAttachment,
  ]);

  // Handle droppedAttachments prop change.
  useEffect(() => {
    (async () => {
      if (droppedAttachments && droppedAttachments.length) {
        await uploadFiles(droppedAttachments);
        clearDroppedAttachments();
      }
    })();
  }, [droppedAttachments, clearDroppedAttachments, uploadFiles]);

  return (
    <div className="fill">
      <input
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        type="file"
        multiple
      />
      <div className="flex-v-center">
        {loader ? (
          <div
            className="py-sm px-xs"
            style={{ marginBottom: '-2px' }}
          >
            <span className="loader" />{' '}
            <small className="faint-text">{t('processing')}</small>
          </div>
        ) : (
          <div
            className="fill px-xs py-xs flex-v-center"
            style={{
              border: '1px dashed rgba(var(--primary), .5)',
              borderRadius: '6px',
            }}
          >
            <button
              className="secondary-button mr-2xs"
              type="button"
              onClick={handleClick}
              disabled={disabled || formStatus === 'loading'}
            >
              <span className="fas fa-paperclip icon"></span>
              <span>{t('attachFiles')}</span>
            </button>
            {!disabled && (
              <span className="faint-text text-sm">{t('dragFile')}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyledTranslation('fileInput')(CardFileInput);
