import { RangeSelection } from 'lexical';

export type FormatState = {
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  isStrikethrough: boolean;
  isCode: boolean;
  isLeft: boolean;
  isCenter: boolean;
  isRight: boolean;
  isJustify: boolean;
};

export const getInitialFormatState = (): FormatState => ({
  isBold: false,
  isItalic: false,
  isUnderline: false,
  isStrikethrough: false,
  isCode: false,
  isLeft: false,
  isCenter: false,
  isRight: false,
  isJustify: false,
});

export const updateFormatState = (
  selection: RangeSelection,
  elementFormat: string,
): FormatState => {
  try {
    return {
      isBold: selection?.hasFormat('bold') ?? false,
      isItalic: selection?.hasFormat('italic') ?? false,
      isUnderline: selection?.hasFormat('underline') ?? false,
      isStrikethrough: selection?.hasFormat('strikethrough') ?? false,
      isCode: selection?.hasFormat('code') ?? false,
      isLeft: elementFormat === 'left',
      isCenter: elementFormat === 'center',
      isRight: elementFormat === 'right',
      isJustify: elementFormat === 'justify',
    };
  } catch (error) {
    console.error('Error updating format state:', error);
    return getInitialFormatState();
  }
};
