import React from 'react';
import LinkButton from '../../../controls/LinkButton';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import { useTranslation } from 'react-i18next';
import MaterialIcon from '../../../../utils/MaterialIcon';

interface CreateBoardButtonProps {
  disabled: boolean;
  team?: TeamDTO;
  shrinkOnMobile?: boolean;
}

const CreateBoardButton: React.FC<CreateBoardButtonProps> = ({
  disabled,
  team,
  shrinkOnMobile,
}) => {
  const { t } = useTranslation('home');
  const boardText = t('createBoard');
  const icon = <MaterialIcon icon="library_add" />;

  return (
    <LinkButton
      to={team ? `/board-add/${team.id}/${team.name}` : '/board-add'}
      disabled={disabled}
      className="secondary-button"
    >
      {shrinkOnMobile ? (
        <>
          <div className="hidden-mobile">
            {icon}
            <span className="pl-xs text no-wrap">{boardText}</span>
          </div>
          <div className="visible-mobile">
            {icon}
            <span className="sr-only">{boardText}</span>
          </div>
        </>
      ) : (
        <>
          {icon}
          <span className="text no-wrap">{boardText}</span>
        </>
      )}
    </LinkButton>
  );
};

export default CreateBoardButton;
