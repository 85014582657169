import { EditorState, LexicalEditor } from 'lexical';
import { useEffect, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import { unite } from '../../../common/helpers/unite';
import { AttachmentNode } from './plugins/nodes/AttachmentNode';
import CONDUIT_BRIDGE from './themes/conduit';
import { MentionNode } from './plugins/nodes/MentionNode';

const theme = CONDUIT_BRIDGE;

interface EditorViewerProps {
  editorState?: string | null;
  onEditorStateChange?: (editorState: EditorState) => void;
  namespace?: string;
  resetTrigger?: any;
}

const onError = (error: unknown) => {
  console.error(error);
};

const EditorViewer: React.FC<EditorViewerProps> = ({
  editorState,
  namespace,
  resetTrigger,
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const initialConfig = {
    namespace: namespace ?? 'EditorViewer',
    theme,
    onError,
    editable: false,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      LinkNode,
      MentionNode,
      AttachmentNode,
    ],
    ...(editorState && {
      editorState: (editor: LexicalEditor) =>
        editor.parseEditorState(JSON.parse(editorState)),
    }),
  };

  return (
    <div className="flex-row fill">
      <div className="column pt-0 pb-2xs">
        <LexicalComposer initialConfig={initialConfig}>
          <EditorContent
            editorState={editorState}
            isDialogVisible={isDialogVisible}
            setIsDialogVisible={setIsDialogVisible}
            resetTrigger={resetTrigger}
          />
        </LexicalComposer>
      </div>
    </div>
  );
};

interface EditorContentProps {
  editorState?: string | null;
  isDialogVisible: boolean;
  setIsDialogVisible: (visible: boolean) => void;
  resetTrigger?: any;
}

const EditorContent: React.FC<EditorContentProps> = ({
  editorState,
  resetTrigger,
}) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (editorState && editor) {
      setTimeout(() => {
        editor.update(() => {
          const parsedState = editor.parseEditorState(JSON.parse(editorState));
          editor.setEditorState(parsedState);
        });
      }, 0);
    }
  }, [editorState, editor, resetTrigger]);

  return (
    <div className="lexical-control">
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              style={{
                paddingTop: '1rem',
              }}
              className={unite(
                'card',
                'editor-input',
                'opaque',
                'soft-disabled',
              )}
              spellCheck={false}
              disabled={true}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>
    </div>
  );
};

export default EditorViewer;
