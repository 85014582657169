import { useCallback, useEffect } from 'react';
import { $getSelection, $isRangeSelection } from 'lexical';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import ContextMenu from '../../ContextMenu';
import ToolbarLinkForm from './ToolbarLinkForm';
import { unite } from '../../../../common/helpers/unite';

type ToolbarLinkPluginProps = {
  editor: any;
  url: string;
  setUrl: (url: string) => void;
  position: { top: number; left: number };
  linkMenuRef: React.RefObject<ContextMenu>;
  setShowLinkMenu: (show: boolean) => void;
};

export default function ToolbarLinkPlugin({
  editor,
  url,
  setUrl,
  position,
  linkMenuRef,
  setShowLinkMenu,
}: ToolbarLinkPluginProps) {
  const isLink = useCallback(() => {
    let result = false;
    editor.read(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = selection.anchor.getNode();
        const parent = node.getParent();
        if (parent && parent.getType() === 'link') {
          result = true;
        }
      }
    });
    return result;
  }, [editor]);

  const handleUrlPrepopulate = useCallback(() => {
    editor.read(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = selection.anchor.getNode();
        const parent = node.getParent();
        if (parent && parent.getType() === 'link') {
          setTimeout(() => {
            setUrl((parent as any).getURL() ?? '');
          }, 0);
        }
      }
    });
  }, [editor, setUrl]);

  const handleUrlSubmit = () => {
    console.log('handleUrlSubmit', url);
    try {
      const trimmedUrl = url.trim();
      if (!trimmedUrl) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        setShowLinkMenu(false);
        return;
      }
      const formattedUrl = /^https?:\/\//i.test(trimmedUrl)
        ? trimmedUrl
        : `http://${trimmedUrl}`;
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, formattedUrl);
      setShowLinkMenu(false);
    } catch (error) {
      console.error('Error dispatching link command:', error);
    }
  };

  // Add effect to trigger the URL form on double-click of a link node
  useEffect(() => {
    // Get the editor's root element (assumes editor.getRootElement() is available)
    const rootElem = editor.getRootElement();
    if (!rootElem) return;
    const handleDoubleClick = (event: MouseEvent) => {
      // Check if the target is inside an anchor element
      const linkElement = (event.target as HTMLElement).closest('a');
      if (linkElement) {
        handleUrlPrepopulate();
        linkMenuRef.current?.open();
      }
    };
    rootElem.addEventListener('dblclick', handleDoubleClick);
    return () => rootElem.removeEventListener('dblclick', handleDoubleClick);
  }, [editor, linkMenuRef, handleUrlPrepopulate]);

  return (
    <ContextMenu
      dept={0}
      contextMenuClassName="align-v-start"
      triggerClassDefault={unite({
        'secondary-button': true,
        'active-border': isLink(),
      })}
      ref={linkMenuRef}
      triggerClassActive="secondary-button active-border"
      isDisabled={
        !isLink() &&
        !editor.getEditorState().read(() => {
          const sel = $getSelection();
          return $isRangeSelection(sel) && !sel.isCollapsed();
        })
      }
      onOpen={() => setTimeout(handleUrlPrepopulate, 0)}
      onClose={() => setTimeout(() => setUrl(''), 0)}
      title="Insert Link"
      name="linkMenu"
      triggerContent={
        <>
          <i className="fas fa-link pe-none" />
          <span className="icon fas fa-angle-down"></span>
        </>
      }
    >
      <li>
        <ToolbarLinkForm
          onCancel={() => {
            linkMenuRef.current?.close();
          }}
          url={url}
          setUrl={setUrl}
          position={position}
          handleUrlSubmit={() => {
            handleUrlSubmit();
            linkMenuRef.current?.close();
          }}
        />
      </li>
    </ContextMenu>
  );
}
