import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { unite } from '../../../common/helpers/unite';

interface FlyoutProps {
  showFlyout?: boolean;
  flyoutAnimation?: boolean;
  pushback?: boolean;
  children?: React.ReactNode;
}

const Flyout: React.FC<FlyoutProps> = (props) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const animationFrame = useRef<number | null>(null);
  const resetTimeout = useRef<number | null>(null);
  const [portalReady, setPortalReady] = useState(false);

  useEffect(() => {
    rootRef.current = document.querySelector(
      '.main-component',
    ) as HTMLDivElement;
    setPortalReady(true);
    return () => {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
      if (resetTimeout.current) {
        clearTimeout(resetTimeout.current);
      }
    };
  }, []);

  if (!portalReady || !rootRef.current) {
    return null;
  }

  return ReactDOM.createPortal(
    props.showFlyout && (
      <div
        className={unite(
          'card-flyout-component',
          props.pushback ? 'pushback' : '',
          props.showFlyout && 'show-flyout',
          { instant: props.flyoutAnimation === false },
        )}
      >
        <div className="card pt-xs">{props.showFlyout && props.children}</div>
      </div>
    ),
    rootRef.current,
  );
};

export default Flyout;
