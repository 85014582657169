import Joi from 'joi';
import React, { ChangeEvent, Component } from 'react';
import { UserDTO } from '../../../../common/api/dtos/User';
import {
  cancelChangeEmail,
  changeEmailRequest,
  cancelDeleteSelf,
  deleteSelf,
  getLoggedUser,
  updateName,
  updateAvatar,
} from '../../../../common/api/endpoints/user';
import AppContext from '../../../../common/contexts/AppContext';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import {
  showErrorNotifications,
  showSuccessNotifications,
} from '../../../../common/helpers/showNotifications';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import Button from '../../../controls/Button';
import TextBox from '../../../controls/TextBox';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import Accordion from '../../../controls/Accordion';
import { RouteComponentProps } from 'react-router-dom';
import PasswordForm from '../../../forms/PasswordForm/PasswordForm';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { customEmailValidation } from '../../Auth/Registration/helper/customEmailValidation';
import { USER_AVATARS } from '../../../../common/configs/UserAvatars';
import Radio from '../../../controls/Radio';
import { TThumbnails } from '../../../../common/types/Thumbnails';
import Thumbnail from '../../../partials/Thumbnail/Thumbnail';
import errorKeyFormatter from '../../../../common/helpers/errorKeyFormatter';
import { ErrorNotificationPayload } from '../../../../common/helpers/errorNotificationPayload';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import { TFunction } from 'i18next';
import TeamPlanRow from '../../../partials/Team/TeamPlanRow';
import { listTeams } from '../../../../common/api/endpoints/team';
import MessageBar from '../../../controls/MessageBar';
import ISubscription from '../../../../common/interfaces/Subscription';

interface Props extends Partial<RouteComponentProps>, WithTranslation {
  loggedUser: UserDTO;
  setLoggedUser: (loggedUser: UserDTO) => void;
  history: any;
  setLockedAccount: (lockedAccount: boolean) => void;
  lockedAccount: boolean;
}

interface State {
  teams: TeamDTO[];
  pageStatus: TRequestStatus;
  nameChangeStatus: TRequestStatus;
  nameFormData: NameFormData;
  nameFormErrors: Record<string, string>; // Provide a type for nameFormErrors
  emailFormData: EmailFormData;
  passwordFormData: PasswordFormData;
  emailFormErrors: Record<string, string>; // Provide a type for emailFormErrors
  avatarFormData: AvatarFormData;
  avatarFormError: Record<string, string>; // Provide a type for avatarFormError
  loggedUser?: UserDTO[];
  nameServerErrors: string[];
  emailServerErrors: string[];
  passwordServerErrors: string[];
  passwordChangeStatus: TRequestStatus;
  serverErrors: string[];
  emailChangeStatus: TRequestStatus;
  avatarChangeStatus: TRequestStatus;
  cancelEmailChangeStatus: TRequestStatus;
  activeEmail: string;
  deleteAccountStatus: TRequestStatus;
  cancelDeleteAccountStatus: TRequestStatus;
  loadingMore: boolean;
  nextPage: string | null;
  status: TRequestStatus;
}

interface NameFormData {
  name: string;
}

interface EmailFormData {
  email: string;
}

interface PasswordFormData {
  password: string;
}

interface AvatarFormData {
  avatar: string;
}

class AccountGeneral extends Component<Props, State> {
  nameFormSchema = Joi.object({
    name: Joi.string()
      .trim()
      .pattern(/^[a-zA-Z0-9-'. ]+$/)
      .required()
      .messages(FormErrorMsgs.name),
  });

  emailFormSchema = Joi.object({
    email: Joi.string()
      .required()
      .trim(true)
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .custom(customEmailValidation)
      .messages(FormErrorMsgs.string),
  });

  context!: React.ContextType<typeof AppContext>;

  private scrollPromise: Promise<void> | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
      teams: [],
      loadingMore: false,
      nextPage: null,
      pageStatus: 'idle',
      nameFormData: {
        name: '',
      },
      nameFormErrors: {},
      emailFormData: {
        email: '',
      },
      passwordFormData: {
        password: '',
      },
      emailFormErrors: {},
      avatarFormData: {
        avatar: '',
      },
      avatarFormError: {},
      nameServerErrors: [],
      passwordServerErrors: [],
      passwordChangeStatus: 'idle',
      emailServerErrors: [],
      serverErrors: [],
      emailChangeStatus: 'idle',
      avatarChangeStatus: 'idle',
      cancelEmailChangeStatus: 'idle',
      activeEmail: '',
      deleteAccountStatus: 'idle',
      cancelDeleteAccountStatus: 'idle',
      nameChangeStatus: 'idle',
    };
  }

  fetchUserName = async () => {
    this.setState({
      pageStatus: 'loading',
    });

    try {
      const { user: loggedUser } = await getLoggedUser();

      this.setState({
        pageStatus: 'success',
        nameFormData: {
          name: loggedUser.name,
        },
        emailFormData: {
          email: loggedUser.email,
        },
        avatarFormData: {
          avatar: loggedUser.avatar,
        },
        activeEmail: loggedUser.email,
      });
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];

      this.setState({
        pageStatus: 'error',
        serverErrors: error,
      });
    }
  };

  updateNameEntity = async (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    ev.preventDefault();

    const isValid = this.validateNameForm();

    if (!isValid) {
      return;
    }

    this.setState({
      nameChangeStatus: 'loading',
    });

    try {
      const loggedUser = await updateName(this.state.nameFormData.name);

      this.setState(
        {
          nameChangeStatus: 'success',
        },
        () => {
          this.context.setLoggedUser!({
            ...loggedUser.user,
            accountSettings: this.context!.loggedUser!.accountSettings,
          });
        },
      );
    } catch (err) {
      const nameServerErrors = [];
      nameServerErrors.push(
        errorKeyFormatter((err as ErrorNotificationPayload[])[0]),
      );
      this.setState({
        nameChangeStatus: 'error',
        nameServerErrors,
      });
    }
  };

  updateEmailEntity = async (e: React.FormEvent) => {
    e.preventDefault();

    const isValid = this.validateEmailForm();

    if (!isValid) {
      return;
    }

    this.setState({
      emailChangeStatus: 'loading',
    });

    try {
      await changeEmailRequest(this.state.emailFormData.email);

      this.setState(
        {
          emailChangeStatus: 'success',
          emailFormData: {
            ...this.state.emailFormData,
            email: '',
          },
        },
        () => {
          this.context.updateLoggedUser!({
            pendingChangeEmail: true,
          });
        },
      );
    } catch (err) {
      const emailServerErrors = [];
      emailServerErrors.push(
        errorKeyFormatter((err as ErrorNotificationPayload[])[0]),
      );
      this.setState({
        emailChangeStatus: 'error',
        emailServerErrors,
      });
    }
  };

  validateNameForm = () => {
    this.setState({
      nameFormErrors: {},
    });

    const result = this.nameFormSchema.validate(this.state.nameFormData, {
      abortEarly: false,
    });

    if (result.error) {
      const nameFormErrors = processJoiError(result.error);
      this.setState({
        nameFormErrors,
      });

      return false;
    }

    return true;
  };

  validateEmailForm = () => {
    this.setState({
      emailFormErrors: {},
    });

    const result = this.emailFormSchema.validate(this.state.emailFormData, {
      abortEarly: false,
    });

    if (result.error) {
      const emailFormErrors = processJoiError(result.error);
      this.setState({
        emailFormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError(field: string, value: string) {
    this.setState((prevState) => {
      return {
        nameFormErrors: {
          ...prevState.nameFormErrors,
          [field]: value,
        },
      };
    });
  }

  updateNameFormData<K extends keyof NameFormData>(
    field: K,
    value: NameFormData[K],
  ) {
    const propsFormData = this.state.nameFormData;
    this.setState({
      nameFormData: {
        ...propsFormData,
        [field]: value,
      },
    });
  }

  updateEmailFormData<K extends keyof EmailFormData>(
    field: K,
    value: EmailFormData[K],
  ) {
    const propsFormData = this.state.emailFormData;
    this.setState({
      emailFormData: {
        ...propsFormData,
        [field]: value,
      },
    });
  }

  updatePasswordFormData<K extends keyof PasswordFormData>(
    field: K,
    value: PasswordFormData[K],
  ) {
    const propsFormData = this.state.passwordFormData;
    this.setState({
      passwordFormData: {
        ...propsFormData,
        [field]: value,
      },
    });
  }

  updateAvatarFormData<K extends keyof AvatarFormData>(
    field: K,
    value: AvatarFormData[K],
  ) {
    const propsFormData = this.state.avatarFormData;
    this.setState({
      avatarFormData: {
        ...propsFormData,
        [field]: value,
      },
    });
  }

  componentDidMount() {
    this.fetchUserName();
    this.fetchAllTeams();

    window.addEventListener('scroll', this.handleScroll); // Add scroll listener
    window.addEventListener('resize', this.handleResize); // Add resize listener

    // Check if we need to load more teams on mount
    this.checkIfMoreContentNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up scroll listener
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  }

  checkIfMoreContentNeeded = () => {
    if (this.state.loadingMore) return;
    if (
      document.documentElement.scrollHeight - 100 <= window.innerHeight &&
      this.state.nextPage
    ) {
      this.setState({ loadingMore: true }, () => {
        this.fetchMoreTeams();
      });
    }
  };

  handleResize = () => {
    this.checkIfMoreContentNeeded(); // Trigger content check on resize
  };

  handleScroll = () => {
    if (this.scrollPromise) return;

    this.scrollPromise = new Promise((resolve) => {
      if (!this.state.nextPage) {
        this.scrollPromise = null;
        return resolve();
      }

      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        this.setState({ loadingMore: true }, async () => {
          await this.fetchMoreTeams();
          this.scrollPromise = null;
          resolve();
        });
      } else {
        this.scrollPromise = null;
        resolve();
      }
    });
  };

  fetchMoreTeams = async () => {
    const { nextPage } = this.state;

    try {
      const { teams: newTeams, nextPage: newNextPage } = await listTeams(
        process.env.REACT_APP_TEAM_LAZYLOAD_PAGE_SIZE,
        nextPage,
      );

      this.setState(
        (prevState) => ({
          teams: [...prevState.teams, ...newTeams],
          loadingMore: false,
          nextPage: newNextPage,
        }),
        () => {
          // Check if more content is needed after updating state
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        loadingMore: false,
        serverErrors: [err as string],
      });
    }
  };

  fetchAllTeams = async () => {
    this.setState({ status: 'loading' });
    try {
      const { teams, nextPage } = await listTeams(
        process.env.REACT_APP_TEAM_LAZYLOAD_PAGE_SIZE,
      );

      this.setState(
        {
          teams,
          status: 'success',
          nextPage,
        },
        () => {
          // Check if more content is needed after the first fetch
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        status: 'error',
        serverErrors: [err as string],
      });
    }
  };

  setUserName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateNameFormData('name', ev.target.value);

  setUserEmail = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateEmailFormData('email', ev.target.value);

  setPassword = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updatePasswordFormData('password', ev.target.value);

  setUserAvatar = (
    ev: React.MouseEvent<HTMLLIElement> | ChangeEvent<HTMLInputElement>,
    avatar: string | TThumbnails,
  ) => this.updateAvatarFormData('avatar', avatar as string); // Handler for avatar

  updateAvatarEntity = async (e: React.FormEvent) => {
    e.preventDefault();

    this.setState({
      avatarChangeStatus: 'loading',
    });

    try {
      await updateAvatar(this.state.avatarFormData.avatar);

      this.setState(
        {
          avatarChangeStatus: 'success',
        },
        () => {
          this.context.updateLoggedUser!({
            avatar: this.state.avatarFormData.avatar,
          });
        },
      );
    } catch (err) {
      this.setState({
        emailChangeStatus: 'error',
      });
      showErrorNotifications(err, this.context.notifications.setMessages!);
    }
  };

  cancelEmailChange = async (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({
      cancelEmailChangeStatus: 'loading',
    });
    const formData = this.state.emailFormData;

    try {
      await cancelChangeEmail();

      this.setState(
        {
          cancelEmailChangeStatus: 'success',
          emailChangeStatus: 'idle',
          emailFormData: {
            ...formData,
            email: this.state.activeEmail,
          },
        },
        () => {
          this.context.updateLoggedUser!({
            pendingChangeEmail: false,
          });
        },
      );
    } catch (err) {
      this.setState({
        cancelEmailChangeStatus: 'error',
      });
      console.debug(err);
    }
  };

  deleteAccount = async (e: React.MouseEvent) => {
    e.preventDefault();

    this.setState({
      deleteAccountStatus: 'loading',
    });

    try {
      await deleteSelf({
        email: this.state.emailFormData.email,
        password: this.state.passwordFormData.password,
      });

      this.setState(
        {
          deleteAccountStatus: 'success',
        },
        () => {
          this.context.updateLoggedUser!({
            pendingDelete: true,
          });
        },
      );
      window.location.assign('/login');
    } catch (err) {
      this.setState({
        deleteAccountStatus: 'error',
      });
      const passwordServerErrors = [];
      passwordServerErrors.push(
        errorKeyFormatter((err as ErrorNotificationPayload[])[0]),
      );
      this.setState({
        passwordChangeStatus: 'error',
        passwordServerErrors,
      });
    }
  };

  cancelDeleteAccount = async (e: React.MouseEvent) => {
    const { t } = this.props;
    e.preventDefault();
    this.setState({
      cancelDeleteAccountStatus: 'loading',
    });

    try {
      await cancelDeleteSelf();
      this.setState(
        {
          cancelDeleteAccountStatus: 'success',
        },
        () => {
          if (this.context.notifications.setMessages) {
            showSuccessNotifications(
              t('accountDeletion.successCancel'),
              this.context.notifications.setMessages,
            );
          }
          this.context.updateLoggedUser!({
            pendingDelete: false,
          });
        },
      );
      this.props.setLockedAccount(false);
    } catch (err) {
      this.setState({
        cancelDeleteAccountStatus: 'error',
      });
      console.debug(err);
    }
  };

  renderBeforeDeleteMessages = (
    personalSubscription:
      | 'subscribed'
      | 'unsubscribed'
      | 'willCancel'
      | 'willDelete',
    teamSubscription: any,
    teamSubscriptions: any,
    teams: TeamDTO[],
    t: TFunction<'translation', undefined>,
  ) => {
    if (
      personalSubscription === 'subscribed' ||
      teamSubscription === 'subscribed'
    ) {
      return (
        <>
          <MessageBar
            type="warning"
            icon="fal fa-exclamation-triangle"
          >
            {t('accountDeletion.activeSubscriptionMessage')}
          </MessageBar>
          {this.context.loggedUser?.subscription?.cancelAt === null && (
            <div className="pt-sm">
              {t('accountDeletion.proPlan')}
              <p className="pt-xs">
                <span className="badge-text accent-yellow">
                  <strong className="black-text text-sm">PRO</strong>
                </span>
              </p>
              <form
                target="_blank"
                method="POST"
                action={`${process.env.REACT_APP_API_BASE_URL}/self/changeBilling`}
              >
                <button className="secondary-button">
                  <span className="text">Manage subscription</span>
                </button>
              </form>
            </div>
          )}
          {teams.map((team) => (
            <div
              key={team.id}
              className="pt-sm"
            >
              <TeamPlanRow
                data={team}
                status={'idle'}
              />
            </div>
          ))}
        </>
      );
    } else if (
      (personalSubscription === 'unsubscribed' ||
        personalSubscription === 'willCancel' ||
        personalSubscription === 'willDelete') &&
      teamSubscription === 'unsubscribed' &&
      teamSubscriptions.length
    ) {
      return <>{t('accountDeletion.confirmationActiveSubscription')}</>;
    } else if (
      (personalSubscription === 'unsubscribed' ||
        personalSubscription === 'willCancel' ||
        personalSubscription === 'willDelete') &&
      teamSubscription === 'unsubscribed' &&
      !teamSubscriptions.length
    ) {
      return <>{t('accountDeletion.confirmationFreeProUnsubscribed')}</>;
    }
  };

  renderAfterDeleteMessages = (t: TFunction<'translation', undefined>) => {
    return <>{t('accountDeletion.gracePeriodMessage')}</>;
  };

  render() {
    const { t } = this.props;

    const activeTeams = this.state.teams.filter(
      (team) =>
        team.role === 'owner' &&
        team.subscription &&
        team.subscription.cancelAt === null &&
        team.subscription.deleteAt === null,
    );

    let personalSubscription:
      | 'subscribed'
      | 'unsubscribed'
      | 'willCancel'
      | 'willDelete'
      | 'none' = 'none';

    if (this.context.loggedUser!.subscription) {
      if (this.context.loggedUser!.subscription.cancelAt) {
        personalSubscription = 'willCancel';
      } else if (this.context.loggedUser!.subscription.deleteAt) {
        personalSubscription = 'willDelete';
      } else {
        personalSubscription = 'subscribed';
      }
    } else {
      personalSubscription = 'unsubscribed';
    }

    const getTeamSubscriptionStatus = (subscription: ISubscription) => {
      if (subscription) {
        if (subscription.cancelAt) {
          return 'willCancel';
        } else if (subscription.deleteAt) {
          return 'willDelete';
        } else {
          return 'subscribed';
        }
      } else {
        return 'unsubscribed';
      }
    };

    const teamSubscriptions = this.state.teams
      .filter((team) => team.role === 'owner')
      .map((team) => {
        return {
          id: team.id,
          name: team.name,
          subscriptionStatus: getTeamSubscriptionStatus(team.subscription),
        };
      });

    const overallTeamSubscription = teamSubscriptions.some(
      (sub) => sub.subscriptionStatus === 'subscribed',
    )
      ? 'subscribed'
      : 'unsubscribed';

    return (
      <>
        <div className="flex-row">
          <div className="column pb-xs">
            <h1 className="primary-title h3 normalcase">{t('accountTitle')}</h1>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column py-0">
            <Accordion
              accordionSlug="public-profile"
              isOpen={false}
              iconClasses="fal fa-id-badge icon"
              title={t('publicProfile.title')}
              subheading={t('publicProfile.subtitle')}
            >
              <div className="accordion-row">
                <div className="flex-row fill">
                  <div className="column">
                    <form>
                      <TextBox
                        label={t('publicProfile.nameLabel')}
                        type="text"
                        name="name"
                        id="name"
                        disabled={this.state.nameChangeStatus === 'loading'}
                        onChange={this.setUserName}
                        value={this.state.nameFormData.name}
                        br={true}
                        required={true}
                        maxLength={50}
                        onBlur={(ev) =>
                          this.updateFormError(
                            ev.target.name as keyof NameFormData,
                            '',
                          )
                        }
                        error={this.state.nameFormErrors.name}
                      />
                      <Button
                        className="primary-button"
                        type="submit"
                        disabled={this.state.nameChangeStatus === 'loading'}
                        onClick={this.updateNameEntity}
                      >
                        <RequestStatus status={this.state.nameChangeStatus} />
                        <span className="text">
                          {t('publicProfile.updateButton')}
                        </span>
                      </Button>
                      {this.state.nameServerErrors.length > 0 && (
                        <ul className="error-list light">
                          {this.state.nameServerErrors.map((err: string) => {
                            return (
                              <li
                                key={err}
                                className="flex-v-center"
                              >
                                <span className="text">{t('app:' + err)}</span>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column py-0">
            {/* Avatar Accordion */}
            <Accordion
              accordionSlug="avatar"
              isOpen={false}
              iconClasses="fal fa-user-circle icon"
              title={t('accountAvatar.title')}
              subheading={t('accountAvatar.subtitle')}
            >
              <div className="accordion-row py-sm">
                <ul className="control-list-component pt-xs">
                  {USER_AVATARS.map((avatar) => {
                    return (
                      <li
                        className="text-center"
                        key={avatar.value}
                      >
                        <label
                          htmlFor={
                            avatar.label == '' ? 'default' : avatar.label
                          }
                          className="thumbnail-label"
                          title={avatar.label == '' ? 'Default' : avatar.label}
                        >
                          <Thumbnail
                            avatarData={avatar.value}
                            title={
                              avatar.label || this.context.loggedUser?.name
                            }
                            classes="radius size-96"
                          />
                        </label>
                        <br />
                        <Radio
                          id={avatar.label == '' ? 'default' : avatar.label}
                          name="avatar"
                          checked={
                            avatar.value === this.state.avatarFormData.avatar
                          }
                          label={avatar.label == '' ? 'Default' : avatar.label}
                          className={'mr-0'}
                          srOnly={true}
                          changeMethod={(ev: ChangeEvent<HTMLInputElement>) =>
                            this.setUserAvatar(ev, avatar.value)
                          }
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* Apply and Cancel Buttons */}
              {this.state.avatarFormData.avatar !==
                this.context.loggedUser?.avatar && (
                <div className="accordion-row py-sm">
                  <ul className="control-list-component">
                    <li>
                      <Button
                        className="primary-button"
                        type="submit"
                        disabled={this.state.avatarChangeStatus === 'loading'}
                        onClick={(
                          ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                        ) => {
                          this.updateAvatarEntity(ev);
                        }}
                      >
                        <RequestStatus status={this.state.avatarChangeStatus} />
                        <span className="text">
                          {t('accountAvatar.applyButton')}
                        </span>
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="secondary-button"
                        disabled={this.state.avatarChangeStatus === 'loading'}
                        onClick={() => {
                          this.updateAvatarFormData(
                            'avatar',
                            this.context.loggedUser?.avatar || '',
                          );
                        }}
                      >
                        <span className="text">
                          {t('accountAvatar.cancelButton')}
                        </span>
                      </Button>
                    </li>
                  </ul>
                </div>
              )}
            </Accordion>
          </div>
        </div>
        {/* Email Change Form */}
        <div className="flex-row fill">
          <div className="column py-0">
            <Accordion
              accordionSlug="account-email"
              isOpen={false}
              iconClasses="fal fa-envelope icon"
              title={t('accountEmail.title')}
              subheading={t('accountEmail.subtitle')}
            >
              <div className="accordion-row">
                <div className="flex-row fill">
                  <div className="column">
                    {this.context.loggedUser?.pendingChangeEmail ? (
                      <>
                        <p className="text-sm">
                          {t('accountEmail.emailChangeRequest')}
                        </p>
                        <Button
                          className="secondary-button"
                          type="submit"
                          disabled={this.state.emailChangeStatus === 'loading'}
                          onClick={this.cancelEmailChange}
                        >
                          <RequestStatus
                            status={this.state.cancelEmailChangeStatus}
                          />
                          <span className="text">
                            {t('accountEmail.cancelEmailChangeButton')}
                          </span>
                        </Button>
                      </>
                    ) : (
                      <>
                        <form
                          onSubmit={this.updateEmailEntity}
                          noValidate
                        >
                          <TextBox
                            label="Email"
                            type="text"
                            name="email"
                            id="email"
                            disabled={
                              this.state.emailChangeStatus === 'loading'
                            }
                            onChange={this.setUserEmail}
                            value={this.state.emailFormData.email}
                            br={true}
                            required={true}
                            onBlur={(ev) =>
                              this.updateFormError(
                                ev.target.name as keyof EmailFormData,
                                '',
                              )
                            }
                            error={this.state.emailFormErrors.email}
                          />

                          <Button
                            className="secondary-button"
                            type="submit"
                            disabled={
                              this.state.emailChangeStatus === 'loading' ||
                              this.state.emailFormData.email ===
                                this.state.activeEmail
                            }
                          >
                            <RequestStatus
                              status={this.state.emailChangeStatus}
                            />
                            <span className="text">
                              {t('accountEmail.requestEmailChangeButton')}
                            </span>
                          </Button>
                          {this.state.emailServerErrors.length > 0 && (
                            <ul className="error-list light">
                              {this.state.emailServerErrors.map(
                                (err: string) => {
                                  return (
                                    <li
                                      key={err}
                                      className="flex-v-center"
                                    >
                                      <span className="text">
                                        {t('app:' + err)}
                                      </span>
                                    </li>
                                  );
                                },
                              )}
                            </ul>
                          )}
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column pt-sm pb-0">
            <h2 className="primary-title h5 normalcase">{t('security')}</h2>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column pb-0">
            <Accordion
              accordionSlug="change-password"
              isOpen={false}
              iconClasses="fal fa-lock icon"
              title={t('changePassword.title')}
              subheading={t('changePassword.subtitle')}
            >
              <div className="accordion-row">
                <PasswordForm enforced={true} />
              </div>
            </Accordion>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column pt-sm pb-0">
            <h2 className="primary-title h5 normalcase">{t('housekeeping')}</h2>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column pb-0">
            <Accordion
              accordionSlug="account-deletion"
              isOpen={false}
              iconClasses="fal fa-user-times icon"
              title={t('accountDeletion.title')}
              subheading={t('accountDeletion.subtitle')}
            >
              <div className="accordion-row">
                <div className="flex-row fill">
                  <div className="column">
                    {this.props.lockedAccount ? (
                      <>
                        <p className="text-sm">
                          {this.renderAfterDeleteMessages(t)}
                        </p>
                        <Button
                          className="secondary-button"
                          type="submit"
                          disabled={
                            this.state.deleteAccountStatus === 'loading'
                          }
                          onClick={this.cancelDeleteAccount}
                        >
                          <RequestStatus
                            status={this.state.cancelDeleteAccountStatus}
                          />
                          <span className="text">
                            {t('accountDeletion.cancelAccountDeletionButton')}
                          </span>
                        </Button>
                      </>
                    ) : (
                      <>
                        <div className="flex-row fill">
                          <div className="column">
                            {this.renderBeforeDeleteMessages(
                              personalSubscription,
                              overallTeamSubscription,
                              teamSubscriptions,
                              activeTeams,
                              t,
                            )}
                          </div>
                        </div>
                        <div className="flex-row fill">
                          <div className="column pb-0">
                            <form autoComplete="new-password">
                              <TextBox
                                label="Enter Current Password"
                                type="password"
                                name="delete-account-password"
                                id="delete-account-password"
                                disabled={
                                  this.state.deleteAccountStatus === 'loading'
                                }
                                onChange={this.setPassword}
                                required={true}
                                br={true}
                                autoComplete="new-password"
                              />
                            </form>
                          </div>
                        </div>
                        <div className="flex-row fill">
                          <div className="column pb-0 pt-0">
                            <Button
                              className="secondary-button"
                              type="submit"
                              onClick={this.deleteAccount}
                              disabled={
                                this.state.passwordFormData.password === ''
                              }
                            >
                              <RequestStatus
                                status={this.state.deleteAccountStatus}
                              />
                              <span className="text">
                                {t(
                                  'accountDeletion.initiateAccountDeletionButton',
                                )}
                              </span>
                            </Button>
                            {this.state.passwordServerErrors.length > 0 && (
                              <ul className="error-list light">
                                {this.state.passwordServerErrors.map(
                                  (err: string) => {
                                    return (
                                      <li
                                        key={err}
                                        className="flex-v-center"
                                      >
                                        <span className="text">
                                          {t('app:' + err)}
                                        </span>
                                      </li>
                                    );
                                  },
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('accountGeneral')(AccountGeneral);
AccountGeneral.contextType = AppContext;
