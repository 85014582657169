import { DecoratorNode, SerializedLexicalNode, Spread } from 'lexical';
import * as React from 'react';

export type SerializedMentionNode = Spread<
  {
    mention: string;
    type: 'mention';
    version: 1;
  },
  SerializedLexicalNode
>;

export class MentionNode extends DecoratorNode<JSX.Element> {
  __mention: string;

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mention, node.__key);
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return new MentionNode(serializedNode.mention);
  }

  exportJSON(): SerializedMentionNode {
    return {
      type: 'mention',
      version: 1,
      mention: this.__mention,
    };
  }

  constructor(mention: string, key?: string) {
    super(key);
    this.__mention = mention;
  }

  createDOM(): HTMLElement {
    const span = document.createElement('span');
    span.className = 'mention-node';
    span.style.color = '#0088cc';
    span.style.fontWeight = 'bold';
    span.style.cursor = 'pointer';
    return span;
  }

  updateDOM(): boolean {
    return false;
  }

  decorate(): JSX.Element {
    return <span>@{this.__mention}</span>;
  }

  isInline(): boolean {
    return true;
  }

  static importDOM(): null {
    return null; // not parsed from DOM yet
  }
}

export function $createMentionNode(mention: string): MentionNode {
  return new MentionNode(mention);
}

export function $isMentionNode(node: unknown): node is MentionNode {
  return node instanceof MentionNode;
}
