import { useState, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ContextMenu from '../../controls/ContextMenu';
import MemberMenu from '../../menus/Member/MemberMenu';
import BoardOperationsMenu from '../../menus/Board/BoardOperationsMenu';
import Toolbar from '../Toolbar/Toolbar';
import PriorityOperationsMenu from '../../menus/Priority/PriorityOperationsMenu';
import TagOperationsMenu from '../../menus/Tag/TagOperationsMenu';
import { TContextMenu } from '../../../common/types/ContextMenu';
import CardFilterMenu from '../../menus/Board/CardFilterMenu';
import PriorityDetailMenu from '../../menus/Priority/PriorityDetailMenu';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import { TagDTO } from '../../../common/api/dtos/Tag';
import TagDetailMenu from '../../menus/Tag/TagDetailMenu';
import LinkButton from '../../controls/LinkButton';
import LeaveOperationsMenu from '../../menus/Leave/LeaveOperationsMenu';
import CardDataContext from '../../menus/CardData/CardDataContext';
import BoardContext, {
  CardFilterField,
} from '../../../common/contexts/BoardContext';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface ExternalProps {
  toggleFavorite: () => void;
  routeUrl?: string;
  filterNumber?: number;
  enableFilter: boolean;
  addOrRemoveField?: (field: string) => void;
  resetFields?: () => void;
  children?: React.ReactNode;
}

interface Props extends ExternalProps, WithTranslation {}

const BoardTopbar: React.FC<Props> = ({
  toggleFavorite,
  enableFilter,
  addOrRemoveField,
  resetFields,
  filterNumber,
  children,
  ...props
}) => {
  const [selectedContext, setSelectedContext] =
    useState<TContextMenu>('operations');
  const [selectedPriority, setSelectedPriority] = useState<PriorityDTO | null>(
    null,
  );
  const [selectedTag, setSelectedTag] = useState<TagDTO | null>(null);
  const [focusableItem, setFocusableItem] = useState<HTMLElement | null>(null);
  const context = useContext(BoardContext);
  const history = useHistory();
  const location = useLocation();

  const handleFilter = useCallback(
    (value: string | null, fields: CardFilterField[]) => {
      if (!enableFilter) return;
      if (value === '') {
        context.filterCards(null, fields);
      } else {
        context.filterCards(value, fields);
      }
    },
    [context, enableFilter],
  );

  const getFilterTitle = (): string => {
    const { t } = props;
    if (filterNumber) {
      return t('cardsAreFilteredFound', { count: filterNumber });
    } else if (context.board.cardFilter.value) {
      return t('cardsAreFilteredFound', {
        count: context.board.cardFilter.count,
      });
    } else {
      return t('filterCards');
    }
  };

  const evaluateDept = (): number => {
    switch (selectedContext) {
      case 'managePriorities':
      case 'leaveBoard':
      case 'TagManageMenus':
        return 1;
      case 'addPriority':
      case 'editPriority':
      case 'addTag':
      case 'editTag':
        return 2;
      default:
        return 0;
    }
  };

  const contextComponents: { [key: string]: React.ReactElement } = {
    operations: (
      <BoardOperationsMenu
        favorite={context.board.favorite}
        toggleFavorite={toggleFavorite}
        setSelectedContext={setSelectedContext}
        routeBoardId={context.board.id}
      />
    ),
    managePriorities: (
      <PriorityOperationsMenu
        directionalButton={{
          text: props.t('backToBoardOptions'),
          direction: 'left',
          onClick: () => setSelectedContext('operations'),
        }}
        fixedPositionParent
        setSelectedContext={setSelectedContext}
        setSelectedPriority={setSelectedPriority}
      />
    ),
    leaveBoard: (
      <LeaveOperationsMenu
        isOwner={context.board.user.role === 'owner'}
        directionalButton={{
          text: props.t('backToBoardOptions'),
          direction: 'left',
          onClick: () => setSelectedContext('operations'),
        }}
        fixedPositionParent
        setSelectedContext={setSelectedContext}
        setSelectedPriority={setSelectedPriority}
      />
    ),
    TagManageMenus: (
      <TagOperationsMenu
        directionalButton={{
          text: props.t('backToBoardOptions'),
          direction: 'left',
          onClick: () => setSelectedContext('operations'),
        }}
        setSelectedContext={setSelectedContext}
        setSelectedTag={setSelectedTag}
      />
    ),
    addPriority: (
      <PriorityDetailMenu
        directionalButton={{
          text: props.t('backToManagePriorities'),
          direction: 'left',
          onClick: () => setSelectedContext('managePriorities'),
        }}
        selectedContext={selectedContext}
        setSelectedContext={setSelectedContext}
        legendText={props.t('addPriority')}
      />
    ),
    editPriority: (
      <PriorityDetailMenu
        directionalButton={{
          text: props.t('backToManagePriorities'),
          direction: 'left',
          onClick: () => setSelectedContext('managePriorities'),
        }}
        selectedContext={selectedContext}
        setSelectedContext={setSelectedContext}
        selectedPriority={selectedPriority}
        legendText={props.t('editPriority')}
      />
    ),
    addTag: (
      <TagDetailMenu
        directionalButton={{
          text: props.t('backToManageTags'),
          direction: 'left',
          onClick: () => setSelectedContext('TagManageMenus'),
        }}
        selectedContext={selectedContext}
        setSelectedContext={setSelectedContext}
        legendText={props.t('addTag')}
      />
    ),
    editTag: (
      <TagDetailMenu
        directionalButton={{
          text: props.t('backToManageTags'),
          direction: 'left',
          onClick: () => setSelectedContext('TagManageMenus'),
        }}
        selectedContext={selectedContext}
        setSelectedContext={setSelectedContext}
        selectedTag={selectedTag}
        legendText={props.t('editTag')}
      />
    ),
  };

  return (
    <Toolbar>
      <>
        <div className="form-group">
          <ContextMenu
            context={selectedContext}
            resetSelectedContext={() => setSelectedContext('operations')}
            contextMenuClassName="align-h-start"
            title={props.t('boardOptions')}
            triggerClassDefault="ghost-button reveal-up-1"
            triggerContent={<span className="fas fa-ellipsis-h"></span>}
            triggerClassActive="secondary-button reveal-up-1"
            dept={evaluateDept()}
          >
            {contextComponents[selectedContext]}
          </ContextMenu>
        </div>
        <div className="form-group reveal-up-2">
          <LinkButton
            to={`/board/${context.board.id}/view/`}
            className="ghost-button"
            activeClassName="disabled"
            disabled={location.pathname.includes(
              `board/${context.board.id}/view`,
            )}
          >
            <h1 className="primary-title h3 normalcase no-wrap">
              {context.board.name}
            </h1>
          </LinkButton>
        </div>
        {context.board.teamId && (
          <div className="form-group">
            <ContextMenu
              contextMenuClassName="align-h-start"
              triggerClassDefault="link-button reveal-up-3"
              title={props.t('manageMembers')}
              dept={0}
              triggerContent={
                <span className="text pe-none">
                  {props.t('memberCount', {
                    count: context.board.members.length || 0,
                  })}
                </span>
              }
              setFocusableItem={setFocusableItem}
            >
              <MemberMenu
                manage={true}
                focusableItem={focusableItem ?? undefined}
              />
            </ContextMenu>
          </div>
        )}
        {enableFilter && (
          <div className="form-group">
            <ContextMenu
              contextMenuClassName="align-h-start"
              title={getFilterTitle()}
              name="filterMenu"
              triggerClassActive="secondary-button reveal-up-3"
              dept={0}
              triggerClassDefault={`${
                context.board.cardFilter.value === null
                  ? 'ghost-button'
                  : 'secondary-button'
              } reveal-up-3`}
              triggerContent={
                <>
                  <span
                    className={`pe-none icon fas fa-filter ${
                      context.board.cardFilter.value === null
                        ? 'mr-0'
                        : 'accent-text'
                    }`}
                  ></span>
                  {context.board.cardFilter.value !== null && (
                    <span className="text">
                      {history.location.pathname.includes(
                        'board-archived-cards',
                      )
                        ? filterNumber
                        : context.board.cardFilter.count}
                    </span>
                  )}
                  <span className="pe-none icon fas fa-angle-down"></span>
                </>
              }
            >
              <CardFilterMenu
                filter={handleFilter}
                initialValue={context.board.cardFilter.value}
                filteredCount={context.board.cardFilter.count}
                fields={context.board.cardFilter.fields}
                addOrRemoveField={addOrRemoveField}
                resetFields={resetFields}
              />
            </ContextMenu>
          </div>
        )}
        {context.board.cardData && context.setCardData && (
          <div className="column text-right reveal-up-3">
            <CardDataContext
              disabled={false}
              contextMenuClassName="align-h-start"
              setSelectedCardData={context.setCardData}
              selectedCardData={context.board.cardData}
            />
          </div>
        )}
      </>
      {children}
    </Toolbar>
  );
};

export default withStyledTranslation('boardTopbar')(BoardTopbar);
