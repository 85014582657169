import React, { Component } from 'react';
import Accordion from '../../../controls/Accordion';
import getProgressFromClientData from '../../../../common/helpers/getProgressFromClientData';
import AppContext from '../../../../common/contexts/AppContext';
import { ChecklistItem, getChecklistItems } from './tutorialData';
import {
  completeTutorial,
  patchClientData,
} from '../../../../common/api/endpoints/user';
import { IClientData } from '../../../../common/interfaces/ClientData';
import selectTutorial from '../../../../common/helpers/selectTutorial';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';

type State = {
  progress: number;
  steps: ChecklistItem[];
  isMinimised: boolean;
  isCompleted: boolean;
  initialized: boolean;
  isHidden: boolean;
  stepsState: { [id: string]: boolean };
  clientData?: IClientData;
  completionAttempted: boolean;
};

class Tutorial extends Component<Record<string, never>, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Record<string, never>) {
    super(props);
    this.state = {
      progress: 0,
      steps: [],
      isMinimised: false,
      isCompleted: false,
      initialized: false,
      isHidden: false,
      stepsState: {},
      completionAttempted: false,
    };
  }

  componentDidMount() {
    if (this.context?.loggedUser) {
      this.initializeState();
    }
  }

  componentDidUpdate(prevProps: Record<string, never>, prevState: State) {
    if (
      JSON.stringify(this.context?.loggedUser?.clientData) !==
      JSON.stringify(prevState.clientData)
    ) {
      this.updateStepsFromClientData();
    }
  }

  processClientData(
    clientData: IClientData,
    userType: 'solo' | 'teamOwner' | 'invited',
  ): ChecklistItem[] {
    if (!this.context?.loggedUser) return [];
    const steps = getChecklistItems(userType);

    const tutorialSteps = getProgressFromClientData(
      this.context?.loggedUser?.onboarding || {},
      clientData,
      userType,
    );

    return this.normalizeSteps(steps, tutorialSteps);
  }

  normalizeSteps(
    steps: ChecklistItem[],
    tutorialSteps: {
      [id: string]: {
        quantity: number;
        completed?: boolean;
      };
    },
  ): ChecklistItem[] {
    return steps.map((step) => {
      const stepData = tutorialSteps[step.id] || {
        quantity: step.quantity,
      };
      return {
        ...step,
        quantity: stepData.quantity,
        completed: (stepData.quantity ?? 0) >= step.requiredQuantity,
      };
    });
  }

  calculateProgress(steps: ChecklistItem[]): number {
    const completedSteps = steps.filter((step) => step.completed).length;
    return (completedSteps / steps.length) * 100;
  }

  async initializeState() {
    if (!this.context?.loggedUser?.id) return;

    const userType = selectTutorial(this.context?.loggedUser);
    const clientData = getProgressFromClientData(
      this.context?.loggedUser?.onboarding || {},
      this.context?.loggedUser?.clientData || {},
      userType,
    );

    const steps = this.processClientData(clientData, userType);
    const progress = this.calculateProgress(steps);
    const isCompleted = progress === 100;

    if (isCompleted && !this.state.completionAttempted) {
      try {
        await completeTutorial();
      } catch (error) {
        if (this.context.notifications.setMessages) {
          showErrorNotifications(error, this.context.notifications.setMessages);
        }
      }
    }

    const stepsStateKey = `${
      this.context?.loggedUser?.id ?? 'default'
    }_tutorial_stepsState`;
    const savedStepsState = localStorage.getItem(stepsStateKey);
    const stepsState = savedStepsState ? JSON.parse(savedStepsState) : {};

    this.setState({
      progress,
      steps,
      isMinimised:
        localStorage.getItem(
          `${this.context?.loggedUser?.id ?? 'default'}_tutorial_isMinimised`,
        ) === 'true',
      isCompleted,
      initialized: true,
      isHidden: this.context?.loggedUser?.clientData?.tutorialClosed ?? false,
      stepsState,
      completionAttempted: isCompleted, // Update the flag
    });
  }

  async updateStepsFromClientData() {
    if (!this.context?.loggedUser?.id) return;
    const userType = selectTutorial(this.context?.loggedUser);
    const clientData = this.context?.loggedUser?.clientData || {};
    const steps = this.processClientData(clientData, userType);

    const progress = this.calculateProgress(steps);
    const isCompleted = progress === 100;

    if (isCompleted && !this.state.completionAttempted) {
      try {
        await completeTutorial();
      } catch (error) {
        if (this.context.notifications.setMessages) {
          showErrorNotifications(
            error,
            this.context?.notifications.setMessages,
          );
        }
      }
    }

    this.setState((prevState) => {
      const stepsChanged =
        JSON.stringify(prevState.steps) !== JSON.stringify(steps);
      const progressChanged = prevState.progress !== progress;
      const isCompletedChanged = prevState.isCompleted !== isCompleted;

      if (stepsChanged || progressChanged || isCompletedChanged) {
        return {
          steps,
          progress,
          isCompleted,
          completionAttempted: isCompleted, // Update the flag
        };
      }
      return null;
    });
  }

  toggleMinimised = () => {
    this.setState((prevState) => {
      const isMinimised = !prevState.isMinimised;
      const isMinimisedKey = `${
        this.context?.loggedUser?.id ?? 'default'
      }_tutorial_isMinimised`;
      localStorage.setItem(isMinimisedKey, isMinimised.toString());
      return { isMinimised };
    });
  };

  toggleStep = (id: string) => {
    this.setState((prevState) => {
      const stepsState = {
        ...prevState.stepsState,
        [id]: !prevState.stepsState[id],
      };

      const stepsStateKey = `${
        this.context?.loggedUser?.id ?? 'default'
      }_tutorial_stepsState`;

      localStorage.setItem(stepsStateKey, JSON.stringify(stepsState));

      return { stepsState };
    });
  };

  tutorialAction_resetAll = async () => {
    if (!this.context?.loggedUser?.id) return;

    const userType = selectTutorial(this.context?.loggedUser);
    const steps = getChecklistItems(userType);

    this.setState(() => {
      const resetSteps = steps.map((step) => ({
        ...step,
        quantity: step.quantity,
        completed: false,
      }));

      const stepsStateKey = `${
        this.context?.loggedUser?.id ?? 'default'
      }_tutorial_stepsState`;
      const stepsState = resetSteps.reduce(
        (acc, step) => {
          acc[step.id] = true;
          return acc;
        },
        {} as { [id: string]: boolean },
      );
      localStorage.setItem(stepsStateKey, JSON.stringify(stepsState));

      const isMinimisedKey = `${
        this.context?.loggedUser?.id ?? 'default'
      }_tutorial_isMinimised`;
      localStorage.removeItem(isMinimisedKey);

      return {
        steps: resetSteps,
        progress: 0,
        isCompleted: false,
        isHidden: false,
        isMinimised: false,
        stepsState,
      };
    });

    try {
      const updatedClientData: IClientData = {};
      await patchClientData(updatedClientData);
      if (this.context?.updateClientData) {
        this.context?.updateClientData(
          updatedClientData as Partial<IClientData>,
        );
      }
    } catch (error) {
      console.error('Failed to update client data', error);
    }
  };

  tutorialAction_completeAll = async () => {
    if (!this.context?.loggedUser?.id) return;

    const userType = selectTutorial(this.context?.loggedUser);
    const steps = getChecklistItems(userType);
    const isCompleted = true;
    this.setState(() => {
      const resetSteps = steps.map((step) => ({
        ...step,
        quantity: step.requiredQuantity,
        completed: true,
      }));

      const stepsStateKey = `${
        this.context?.loggedUser?.id ?? 'default'
      }_tutorial_stepsState`;
      const stepsState = resetSteps.reduce(
        (acc, step) => {
          acc[step.id] = false;
          return acc;
        },
        {} as { [id: string]: boolean },
      );
      localStorage.setItem(stepsStateKey, JSON.stringify(stepsState));

      const isMinimisedKey = `${
        this.context?.loggedUser?.id ?? 'default'
      }_tutorial_isMinimised`;
      localStorage.removeItem(isMinimisedKey);

      return {
        steps: resetSteps,
        progress: 100,
        isCompleted,
        isMinimised: false,
        stepsState,
      };
    });

    try {
      const updatedClientData: IClientData = {
        ...(this.context?.loggedUser?.clientData ?? {}),
        [`tutorial_${userType}`]: {
          steps: steps.reduce(
            (acc, step) => {
              acc[step.id] = {
                quantity: step.requiredQuantity,
                completed: true,
              };
              return acc;
            },
            {} as { [id: string]: { quantity: number; completed: boolean } },
          ),
        },
      };
      await patchClientData(updatedClientData);
      if (this.context?.updateClientData) {
        this.context?.updateClientData(
          updatedClientData as Partial<IClientData>,
        );
      }
    } catch (error) {
      console.error('Failed to update client data', error);
    }
  };

  tutorialAction_closeForever = async () => {
    this.setState({ isHidden: true });

    try {
      const updatedClientData: IClientData = {
        ...(this.context?.loggedUser?.clientData ?? {}),
        tutorialClosed: true,
      };
      await patchClientData(updatedClientData);
      if (this.context?.updateClientData) {
        this.context?.updateClientData(
          updatedClientData as Partial<IClientData>,
        );
      }
    } catch (error) {
      console.error('Failed to update client data', error);
      this.setState({ isHidden: false });
    }
  };

  renderProgress() {
    const { progress } = this.state;
    return (
      <div
        className="progress-bar-component mb-xs"
        title={`${Math.ceil(progress)}% completed`}
      >
        <div className="track pe-none">
          <div
            className="progress"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    );
  }

  render() {
    const {
      steps,
      isMinimised,
      isCompleted,
      initialized,
      isHidden,
      stepsState,
    } = this.state;
    const remainingSteps = steps.filter((step) => !step.completed).length;

    if (isHidden && this.isTutorialPage()) {
      return (
        <button
          className="secondary-button"
          style={{
            position: 'fixed',
            bottom: '16px',
            zIndex: 1000,
            right: '16px',
          }}
          onClick={this.tutorialAction_resetAll}
          title={`Rewind all progress`}
        >
          <span className="pe-none fas fa-fast-backward icon"></span>
        </button>
      );
    }

    if (!this.context?.loggedUser?.id || !initialized || isHidden) {
      return null;
    }

    if (!this.isTutorialPage() || isHidden) {
      return null;
    }

    return (
      <div
        className={`card tutorial-component ${
          isMinimised ? 'mini reveal-down-1' : 'reveal-up-1'
        }`}
      >
        {isMinimised ? (
          <button
            className="secondary-button"
            onClick={this.toggleMinimised}
          >
            <span
              className={`fas fa-badge accent-text fa-lg`}
              style={{ position: 'relative' }}
            >
              <span
                className="text-xs text-stroke-xs"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'rgb(var(--button-text))',
                  fontFamily: 'Inter',
                  width: '20px',
                  height: '20px',
                  lineHeight: '20px',
                  textAlign: 'center',
                }}
              >
                {remainingSteps}
              </span>
            </span>
            <span className="text pl-sm">Getting started</span>
          </button>
        ) : (
          <div>
            {isCompleted ? (
              <div>
                <ul className="control-list-component top-tight right-tight align-h-end">
                  <li>
                    <button
                      className="secondary-button"
                      onClick={this.tutorialAction_resetAll}
                      title={`Rewind all progress`}
                    >
                      <span className="pe-none fas fa-fast-backward icon"></span>
                    </button>
                  </li>
                  <li>
                    <button
                      className="secondary-button"
                      onClick={this.tutorialAction_completeAll}
                      title={`Complete all steps`}
                    >
                      <span className="pe-none fas fa-fast-forward icon"></span>
                    </button>
                  </li>
                  <li>
                    <button
                      className="ghost-button"
                      onClick={this.toggleMinimised}
                      title="Hide tutorial"
                    >
                      <span className="fas fa-horizontal-rule icon pe-none"></span>
                    </button>
                  </li>
                </ul>
                <div className="py-xs">
                  <h2 className="primary-title normalcase">Awesome work!</h2>
                </div>
                {this.renderProgress()}
                <p>
                  {`You've completed all the steps. You're now prepared to take
                  full advantage of Borddo's capabilities.`}
                </p>
                <ul className="control-list-component">
                  <li>
                    <a
                      href="mailto:feedback@borddo.com"
                      className="primary-button"
                    >
                      Email us your Feedback
                    </a>
                  </li>
                  <li>
                    <button
                      className="secondary-button"
                      onClick={this.tutorialAction_closeForever}
                    >
                      Close forever
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              <div>
                <div
                  className="py-xs"
                  style={{
                    position: 'sticky',
                    top: '-16px',
                    zIndex: 10,
                    background: 'rgb(var(--card-bg-opaque))',
                  }}
                >
                  <ul className="control-list-component top-tight right-tight align-h-end">
                    <li>
                      <button
                        className="secondary-button"
                        onClick={this.tutorialAction_resetAll}
                        title={`Rewind all progress`}
                      >
                        <span className="pe-none fas fa-fast-backward icon"></span>
                      </button>
                    </li>
                    <li>
                      <button
                        className="secondary-button"
                        onClick={this.tutorialAction_completeAll}
                        title={`Complete all steps`}
                      >
                        <span className="pe-none fas fa-fast-forward icon"></span>
                      </button>
                    </li>
                    <li>
                      <button
                        className="ghost-button"
                        onClick={this.toggleMinimised}
                        title="Hide tutorial"
                      >
                        <span className="fas fa-horizontal-rule icon pe-none"></span>
                      </button>
                    </li>
                  </ul>
                  <div className="py-xs">
                    <h2 className="primary-title normalcase">
                      Getting started
                    </h2>
                  </div>
                  {this.renderProgress()}
                </div>
                {steps.map((step) => (
                  <Accordion
                    key={step.id}
                    accordionSlug={`accordion-${step.id}`}
                    title={step.text}
                    isOpen={stepsState[step.id]}
                    softDisabled={true}
                    hideArrow={step.completed}
                    disabled={step.completed}
                    iconClasses={
                      step.completed
                        ? 'fas fa-check-circle accent-text'
                        : 'fal fa-circle'
                    }
                    onToggle={() => this.toggleStep(step.id)}
                  >
                    {!step.completed && (
                      <div className="accordion-row">
                        <div className="py-sm">
                          <p>{step.description}</p>
                          {step.requiredQuantity > 1 && (
                            <div
                              className="progress-bar-component mb-xs"
                              title={`${Math.ceil(
                                ((step.quantity ?? 0) / step.requiredQuantity) *
                                  100,
                              )}% completed`}
                            >
                              <div className="track pe-none">
                                <div
                                  className="progress"
                                  style={{
                                    width: `${
                                      ((step.quantity ?? 0) /
                                        step.requiredQuantity) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>
                              <p className="text-xs pt-xs">
                                {step.quantity ?? 0} of {step.requiredQuantity}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Accordion>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  toggleDescription = (id: string) => {
    this.setState((prevState) => {
      const steps = [...prevState.steps];
      const step = steps.find((step) => step.id === id);
      if (step) {
        step.showDescription = !step.showDescription;
      }
      return { steps };
    });
  };

  isTutorialPage() {
    return (
      window.location.pathname.includes('/board/') &&
      window.location.pathname.includes('/view')
    );
  }

  hideForever = () => {
    this.setState({ isHidden: true });
  };
}

export default Tutorial;

Tutorial.contextType = AppContext;
