import ContextMenu from '../../ContextMenu';
import BlockMenuOptions, {
  BlockType,
  blockTypeToBlockName,
  supportedBlockTypes,
} from './BlockMenuOptions';

type BlockMenuPluginProps = {
  // Ideally type your editor but using any here for brevity
  editor: any;
  toolbarRef: React.RefObject<HTMLDivElement>;
  blockType: BlockType;
  setBlockType: (type: BlockType) => void;
  formatMenuRef: React.RefObject<ContextMenu>;
};

export default function BlockMenuPlugin({
  editor,
  toolbarRef,
  blockType,
  setBlockType,
  formatMenuRef,
}: BlockMenuPluginProps) {
  return supportedBlockTypes.has(blockType) ? (
    <ContextMenu
      ref={formatMenuRef}
      dept={0}
      contextMenuClassName="align-v-start"
      triggerClassDefault="secondary-button"
      triggerContent={
        <>
          <span className="text">{blockTypeToBlockName[blockType]}</span>
          <span className="icon fas fa-angle-down"></span>
        </>
      }
    >
      <BlockMenuOptions
        editor={editor}
        blockType={blockType}
        formatMenuRef={formatMenuRef}
        toolbarRef={toolbarRef}
        setBlockType={setBlockType}
      />
    </ContextMenu>
  ) : null;
}
