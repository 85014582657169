import React, {
  RefObject,
  useContext,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { borderRadius, grid } from './common/constants';
import type { DraggableProvided } from '@wavemyth/react-beautiful-dnd';
import { ICard } from '../../../common/interfaces/BoardContext';
import BoardCardMembers from '../board/BoardCard/BoardCardContent/BoardCardMembers';
import BoardContext from '../../../common/contexts/BoardContext';
import BoardCardDescription from '../board/BoardCard/BoardCardContent/BoardCardDescription';
import BoardCardTags from '../board/BoardCard/BoardCardContent/BoardCardTags';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { unite } from '../../../common/helpers/unite';
import scrollToItem from '../../../common/helpers/scrollToItem';
import { useItemSlug } from '../../../common/helpers/useItemSlug';

type SurfaceItemProps = {
  listItem: ICard;
  isDragging: boolean;
  itemDragProvided: DraggableProvided;
  isClone?: boolean;
  isGroupedOver?: boolean;
  style?: unknown;
  index?: number;
  columnId: string;
  onExpandColumn: (columnId: string) => void;
  onCardRef: (ref: RefObject<HTMLDivElement>) => void;
};

const getBackgroundColor = (isDragging: boolean, isGroupedOver: boolean) =>
  isDragging ? 'rgba(var(--app-bg-color), 1)' : isGroupedOver ? '#EBECF0' : '';

const getStyle = (provided: DraggableProvided, style: unknown) =>
  style
    ? { ...provided.draggableProps.style, ...style }
    : provided.draggableProps.style;

const SurfaceItem: React.FC<SurfaceItemProps> = (props) => {
  const {
    listItem,
    isDragging,
    itemDragProvided: provided,
    isGroupedOver,
    style,
    index,
    onCardRef: emitCardRef,
  } = props;
  const boardCTX = useContext(BoardContext);
  const selectedTags = useMemo(
    () =>
      listItem.tagIds.map((tagId) =>
        boardCTX.board.tags.find((tag) => tag.id === tagId),
      ) as TagDTO[],
    [listItem.tagIds, boardCTX.board.tags],
  );
  const priority = useMemo(
    () => boardCTX.board.priorities.find((p) => p.id === listItem.priorityId),
    [boardCTX.board.priorities, listItem.priorityId],
  );

  const itemSlug = useItemSlug();

  const itemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (itemRef.current) {
      emitCardRef(itemRef);
    }
  }, []);

  const openItem = () => {
    boardCTX.setOpenCard(listItem.id);
    itemSlug.addItemSlug(listItem, boardCTX.board.cardNrPrefix);
    scrollToItem(itemRef.current, boardCTX.openCardId === listItem.id);
  };

  return (
    <div
      className={unite({
        'surface-card-board-button': true,
        'flashing-border': boardCTX.openCardId === listItem.id,
      })}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          openItem();
        }
      }}
      onClick={(e) => {
        // if click happened within the context menu, do nothing
        if ((e.target as HTMLElement).closest('#context-menu-root')) return;
        openItem();
      }}
      style={{
        borderRadius: borderRadius,
        backgroundColor: getBackgroundColor(isDragging, Boolean(isGroupedOver)),
        boxShadow: isDragging ? `0 0 8px rgba(0,0,0,.1)` : 'none',
        boxSizing: 'border-box',
        marginBottom: grid,
        userSelect: 'none',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'stretch',
        transition: 'none !important',
        cursor: 'pointer',
        ...getStyle(provided, style),
      }}
      data-is-dragging={isDragging}
      data-testid={listItem.id}
      data-index={index}
      aria-label={`${listItem.title}`}
    >
      <div
        className="text-sm"
        ref={itemRef}
        style={{
          flexGrow: 1,
          flexBasis: '100%',
          display: 'flex',
          alignItems: 'stretch',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <BoardCardDescription
          priority={priority}
          cardId={listItem.id}
          prefix={boardCTX.board.cardNrPrefix}
          number={listItem.number}
          title={listItem.title}
          description={listItem.description}
          maxLines={2}
          numberOfAttachments={listItem.numberOfAttachments}
          numberOfComments={listItem.numberOfComments}
          subtasks={listItem.subtasks}
        />
        {boardCTX.board.cardData.includes('member') && (
          <BoardCardMembers
            cardId={listItem.id}
            assigneeIds={listItem.assigneeIds}
            members={boardCTX.board.members}
          />
        )}
        {boardCTX.board.cardData.includes('tag') && (
          <BoardCardTags
            tags={selectedTags}
            cardId={listItem.id}
            tagIds={listItem.tagIds}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(SurfaceItem);
