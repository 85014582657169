import { grid } from '../../components/partials/surface/common/constants';
import { getScrollBehavior } from './getScrollBehavior';

export default function scrollToItem(
  element: HTMLElement | null,
  isNull?: boolean,
) {
  const animationTimeout = 200; //miliseconds
  console.log('Scroll to item', element, isNull);
  if (!element) return;

  // Helper function to find the first scrollable parent
  function findScrollableParent(el: HTMLElement): HTMLElement | null {
    let parent = el.parentElement;
    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY;
      if (overflowY === 'auto' || overflowY === 'scroll') {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  }

  // Horizontal handling for cards (for flyout behavior)
  const flyoutRect = document
    .querySelector('.card-flyout-component')
    ?.getBoundingClientRect();
  const flyoutOffset = (window.innerWidth / 100) * 51;
  const elementPositionX =
    element.getBoundingClientRect().left + window.scrollX;
  let posLeft =
    elementPositionX - flyoutOffset + element.offsetWidth + grid * 2;
  const elementRect = element.getBoundingClientRect();
  let shouldHorizontalScroll = false;
  const visibleBoardScreenWidth =
    window.innerWidth - (flyoutRect ? flyoutRect.width : flyoutOffset);
  const isMobile = window.innerWidth < 767;

  if (
    elementPositionX + elementRect.width >
    window.scrollX + visibleBoardScreenWidth - grid * 3
  ) {
    shouldHorizontalScroll = true;
  }

  if (elementPositionX < window.scrollX + grid * 3) {
    shouldHorizontalScroll = true;
    posLeft = elementPositionX - grid * 3;
  }

  if (isMobile) {
    posLeft =
      elementPositionX - (window.innerWidth / 2 - elementRect.width / 2);
  }

  if (shouldHorizontalScroll) {
    setTimeout(
      () => {
        window.scrollTo({
          left: posLeft,
          behavior: getScrollBehavior(),
        });
      },
      isNull ? animationTimeout : 0,
    );
  }

  // Vertical handling
  const listBody = findScrollableParent(element);
  const elementPos = element.getBoundingClientRect();
  const offsetTop = listBody
    ? listBody.getBoundingClientRect().top + grid * 2
    : 0;
  const offsetBottom = listBody
    ? listBody.getBoundingClientRect().bottom - grid * 2
    : 0;
  const elementPositionY =
    element.getBoundingClientRect().top + (listBody ? listBody.scrollTop : 0);
  let posTop = 0;
  let shouldScroll = false;

  if (elementPos.top < offsetTop) {
    posTop = elementPositionY - offsetTop;
    shouldScroll = true;
  }

  if (elementPos.top + elementPos.height > offsetBottom) {
    posTop = elementPositionY + elementPos.height - offsetBottom;
    shouldScroll = true;
  }

  if (shouldScroll) {
    listBody?.scrollTo({
      top: posTop,
      behavior: getScrollBehavior(),
    });
  }
}
