import React, { Component } from 'react';
import Accordion from '../../../controls/Accordion';
import dayjsHelper from '../../../../common/helpers/dayjsHelper';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import AppContext from '../../../../common/contexts/AppContext';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import { Trans, WithTranslation } from 'react-i18next';
import { listTeams } from '../../../../common/api/endpoints/team'; // Import the listTeams function
import SubscriptionMessage from './SubscriptionMessage';
import TeamPlanRow from '../../../partials/Team/TeamPlanRow';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import Button from '../../../controls/Button';
import ISubscription from '../../../../common/interfaces/Subscription';

interface Props extends WithTranslation {
  history: any;
}

interface State {
  teams: TeamDTO[];
  status: TRequestStatus;
  serverErrors: string[];
  loadingMore: boolean;
  nextPage: string | null;
}

class Subscriptions extends Component<Props, State> {
  context!: React.ContextType<typeof AppContext>;
  private scrollPromise: Promise<void> | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      teams: [],
      status: 'loading',
      serverErrors: [],
      loadingMore: false,
      nextPage: null,
    };
  }

  componentDidMount() {
    this.fetchAllTeams();

    window.addEventListener('scroll', this.handleScroll); // Add scroll listener
    window.addEventListener('resize', this.handleResize); // Add resize listener

    // Check if we need to load more teams on mount
    this.checkIfMoreContentNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up scroll listener
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  }

  checkIfMoreContentNeeded = () => {
    if (this.state.loadingMore) return;
    if (
      document.documentElement.scrollHeight - 100 <= window.innerHeight &&
      this.state.nextPage
    ) {
      this.setState({ loadingMore: true }, () => {
        this.fetchMoreTeams();
      });
    }
  };

  handleResize = () => {
    this.checkIfMoreContentNeeded(); // Trigger content check on resize
  };

  handleScroll = () => {
    if (this.scrollPromise) return;

    this.scrollPromise = new Promise((resolve) => {
      if (!this.state.nextPage) {
        this.scrollPromise = null;
        return resolve();
      }

      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        this.setState({ loadingMore: true }, async () => {
          await this.fetchMoreTeams();
          this.scrollPromise = null;
          resolve();
        });
      } else {
        this.scrollPromise = null;
        resolve();
      }
    });
  };

  fetchMoreTeams = async () => {
    const { nextPage } = this.state;

    try {
      const { teams: newTeams, nextPage: newNextPage } = await listTeams(
        process.env.REACT_APP_TEAM_LAZYLOAD_PAGE_SIZE,
        nextPage,
      );

      this.setState(
        (prevState) => ({
          teams: [...prevState.teams, ...newTeams],
          loadingMore: false,
          nextPage: newNextPage,
        }),
        () => {
          // Check if more content is needed after updating state
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        loadingMore: false,
        serverErrors: [err as string],
      });
    }
  };

  fetchAllTeams = async () => {
    this.setState({ status: 'loading' });
    try {
      const { teams, nextPage } = await listTeams(
        process.env.REACT_APP_TEAM_LAZYLOAD_PAGE_SIZE,
      );

      this.setState(
        {
          teams,
          status: 'success',
          nextPage,
        },
        () => {
          // Check if more content is needed after the first fetch
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        status: 'error',
        serverErrors: [err as string],
      });
    }
  };

  renderPlan(subscription: ISubscription | null) {
    return subscription ? (
      <span className="badge-text accent-yellow mr-2xs">
        <span className="black-text text-sm text-700">PRO</span>
      </span>
    ) : (
      <span className="badge-text accent-green mr-2xs">
        <span className="black-text text-sm text-700">FREE</span>
      </span>
    );
  }

  render() {
    const { t } = this.props;
    const { teams, status, loadingMore } = this.state;
    const subscription: ISubscription | null =
      this.context.loggedUser!.subscription || null;

    const deleteDate = this.context.loggedUser?.subscription?.deleteAt
      ? new Date(this.context.loggedUser?.subscription?.deleteAt)
      : null;

    const cancelDate = this.context.loggedUser?.subscription?.cancelAt
      ? new Date(this.context.loggedUser?.subscription?.cancelAt)
      : null;

    return (
      <>
        <div className="flex-row">
          <div className="column pb-xs">
            <h1 className="primary-title h3 normalcase">{t('plansTitle')}</h1>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column pt-0">
            <Accordion
              accordionSlug="personal-plan"
              isOpen={false}
              iconClasses="fal fa-stars icon"
              title={t('personalPlan.title')}
              subheading={t('personalPlan.subheading')}
              loading={status === 'loading'}
            >
              <div className="accordion-row py-sm">
                <ul className="control-list-component vertical">
                  <li>{t('personalPlan.boardsPlanLabel')}</li>
                  <ul className="control-list-component">
                    {subscription && this.renderPlan(subscription)}
                    {subscription?.period === 'grace' ? (
                      <li>
                        <span className="flag-text accent-red">
                          <span className="text-sm">
                            {t('teamPlans.expiredBadge')}
                          </span>
                        </span>
                      </li>
                    ) : subscription?.period === 'active' &&
                      subscription.cancelAt ? (
                      <li>
                        <span className="flag-text accent-yellow">
                          <span className="text-sm">
                            {t('teamPlans.cancelledBadge')}
                          </span>
                        </span>
                      </li>
                    ) : subscription?.period === 'suspended' ? (
                      <li>
                        <span className="flag-text accent-red">
                          <span className="text-sm">
                            {t('teamPlans.paymentIssueBadge')}
                          </span>
                        </span>
                      </li>
                    ) : null}
                  </ul>{' '}
                  {subscription?.period === 'grace' ? (
                    <li>
                      <SubscriptionMessage badge="danger">
                        <Trans
                          i18nKey="subscriptions:personalPlan.deletionMessage"
                          values={{
                            date: dayjsHelper(deleteDate).format(
                              'MMMM Do, YYYY',
                            ),
                          }}
                          components={{ strong: <strong /> }}
                        />
                      </SubscriptionMessage>
                    </li>
                  ) : subscription?.period === 'active' &&
                    subscription.cancelAt ? (
                    <li>
                      <SubscriptionMessage badge="warning">
                        <Trans
                          i18nKey="subscriptions:personalPlan.cancelingMessage"
                          values={{
                            date: dayjsHelper(cancelDate).format(
                              'MMMM Do, YYYY',
                            ),
                          }}
                          components={{ strong: <strong /> }}
                        />
                      </SubscriptionMessage>
                    </li>
                  ) : (
                    subscription?.period === 'suspended' && (
                      <li>
                        <SubscriptionMessage badge="warning">
                          <Trans
                            i18nKey="subscriptions:personalPlan.paymentIssueMessage"
                            values={{
                              endDate: dayjsHelper(
                                this.context.loggedUser?.subscription
                                  ?.paymentRetryUntil,
                              ).format('MMMM Do, YYYY'),
                            }}
                            components={{ strong: <strong /> }}
                          />
                        </SubscriptionMessage>
                      </li>
                    )
                  )}
                  <li className="pt-xs">
                    {subscription === null ? (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/upgrade`}
                      >
                        <Button
                          className="secondary-button"
                          type="submit"
                        >
                          <span className="text">
                            {t('personalPlan.upgradeButton')}
                          </span>
                        </Button>
                      </form>
                    ) : subscription?.period === 'grace' ? (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/upgrade`}
                      >
                        <Button
                          className="secondary-button"
                          type="submit"
                        >
                          <span className="text">
                            {t('personalPlan.reactivateSubscriptionButton')}
                          </span>
                        </Button>
                      </form>
                    ) : subscription.period == 'active' &&
                      subscription.cancelAt ? (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/changeBilling`}
                      >
                        <Button
                          className="secondary-button"
                          type="submit"
                        >
                          <span className="text">
                            {t('personalPlan.manageSubscriptionButton')}
                          </span>
                        </Button>
                      </form>
                    ) : subscription?.period === 'suspended' ? (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/updatePaymentMethod`}
                      >
                        <Button
                          className="secondary-button"
                          type="submit"
                        >
                          <span className="text">
                            {t('personalPlan.fixPaymentIssue')}
                          </span>
                        </Button>
                      </form>
                    ) : subscription.period === 'active' ? (
                      <form
                        target="_blank"
                        method="POST"
                        action={`${process.env.REACT_APP_API_BASE_URL}/self/changeBilling`}
                      >
                        <Button
                          className="secondary-button"
                          type="submit"
                        >
                          <span className="text">
                            {t('personalPlan.manageSubscriptionButton')}
                          </span>
                        </Button>
                      </form>
                    ) : null}
                  </li>
                </ul>
              </div>
            </Accordion>
            <Accordion
              accordionSlug="team-plans"
              isOpen={false}
              iconClasses="fal fa-users icon"
              title={t('teamPlans.title')}
              subheading={t('teamPlans.subheading')}
              loading={status === 'loading'}
            >
              {!teams.filter((team) => team.owned).length &&
                status !== 'loading' && (
                  <div className="accordion-row py-sm">
                    <p className="faint-text">
                      {t('teamPlans.noTeamsMessage')}
                    </p>
                  </div>
                )}

              {teams
                .filter((team) => team.owned)
                .map((team, index) => (
                  <div
                    key={index}
                    className="accordion-row py-sm"
                  >
                    <TeamPlanRow
                      data={team}
                      status={this.state.status}
                    />
                  </div>
                ))}
              {loadingMore == false && status === 'loading' && (
                <div className="accordion-row py-sm">
                  <span className="loader mr-xs"></span>
                  <span className="faint-text text-xs">Loading...</span>
                </div>
              )}
              {loadingMore && (
                <div className="accordion-row py-sm">
                  <span className="loader mr-xs"></span>
                  <span className="faint-text text-xs">Loading...</span>
                </div>
              )}
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('subscriptions')(Subscriptions);
Subscriptions.contextType = AppContext;
