import React, { useRef, useCallback, useState } from 'react';
import TextBox from '../../TextBox';
import Button from '../../Button';

interface UrlFormProps {
  url: string;
  setUrl: (url: string) => void;
  position: { top: number; left: number };
  handleUrlSubmit: () => void;
  onCancel?: () => void;
}

const ToolbarLinkForm: React.FC<UrlFormProps> = ({
  url,
  setUrl,
  handleUrlSubmit,
  onCancel,
}) => {
  const formRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string>('');

  const onUrlChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(e.target.value);
      if (error) setError('');
    },
    [setUrl, error],
  );

  const handleSave = () => {
    handleUrlSubmit();
  };

  const handleCancel = () => {
    // Prefer a provided onCancel callback if exists.
    if (onCancel) {
      onCancel();
    } else {
      setUrl('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    } else if (e.key === 'Escape') {
      handleCancel();
    }
  };

  return (
    <div ref={formRef}>
      <TextBox
        type="text"
        value={url}
        onChange={onUrlChange}
        onKeyDown={handleKeyDown}
        placeholder="Type URL here..."
        label="Link URL"
        name="url"
        id="url"
        br={true}
      />
      {error && <p className="error-text">{error}</p>}
      <ul className="control-list-component">
        <li>
          <Button
            className="primary-button"
            onClick={handleSave}
            title="Save"
          >
            Save
          </Button>
        </li>
        <li>
          <Button
            className="ghost-button"
            onClick={handleCancel}
            title="Cancel"
          >
            <span className="fas fa-times pe-none"></span>
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default ToolbarLinkForm;
