import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  ActivityDTO,
  ActivityAddRemoveSubtaskDTO,
  ActivityUpdateSubtaskDTO,
  ActivityAssignTagDTO,
  ActivityAssignUserDTO,
  ActivityAtCardCreationDTO,
  ActivityMoveCardDTO,
  ActivityRestoreCardDTO,
  ActivityUnassignTagDTO,
  ActivityUnassignUserDTO,
  ActivityUpdatePriorityDTO,
  ActivityUpdateTitleDTO,
  ActivityAddAttachmentDTO,
  ActivityRemoveAttachmentDTO,
} from '../../../../../common/api/dtos/Activity';
import { THistory } from '../../../../../common/types/Activity';
import { CommentDTO } from '../../../../../common/api/dtos/Comment';
import { InviteeDTO, MemberDTO } from '../../../../../common/api/dtos/Member';
import { TagDTO } from '../../../../../common/api/dtos/Tag';
import { ColumnDTO } from '../../../../../common/api/dtos/Column';
import Thumbnail from '../../../Thumbnail/Thumbnail';
import UserComment from '../editors/UserCommentEditor';
import { IBoardCurrentUser } from '../../../../../common/interfaces/BoardCurrentUser';
import { MsgWs } from '../../../../pages/Board/BoardProvider/wsHandlers';
import { PriorityDTO } from '../../../../../common/api/dtos/Priority';
import { GroupedActivityItem, IActivity, IComment } from '../CardFlyout';
import { TRequestStatus } from '../../../../../common/types/RequestStatus';
import { AttachmentDTO } from '../../../../../common/api/dtos/Card';
import getTime from '../../../../../common/helpers/getTime';
import Button from '../../../../controls/Button';
import AppContext from '../../../../../common/contexts/AppContext';
import MessageBar from '../../../../controls/MessageBar';

interface CardActivityProps {
  disabled: boolean;
  members: (MemberDTO | InviteeDTO)[];
  tags: TagDTO[];
  columnTitles: Partial<ColumnDTO>[];
  boardCard: BoardCard;
  boardUser: IBoardCurrentUser;
  reachedBottom: boolean;
  resetReachedBottom: () => void;
  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  priorities: PriorityDTO[];
  groupedActivityItems: GroupedActivityItem[];
  activityStatus: TRequestStatus;
  updateComment: (commentData: Partial<CommentDTO>) => void;
  historyType: THistory;
  setHistoryType: (historyType: THistory) => void;
  attachments: AttachmentDTO[];
  removeComment: (commentData: Partial<CommentDTO>) => void;
  handleDeleteComment?: () => void;
  groupedActivities: GroupedActivityItem[];
  groupedComments: GroupedActivityItem[];
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];
  description: string;
  tagIds: string[];
  priorityId: string | null;
}

const CardActivity: React.FC<CardActivityProps> = (props) => {
  const { t } = useTranslation('cardActivity');
  const appContext = useContext(AppContext);
  const timeFormat: number =
    appContext.loggedUser?.accountSettings.timeFormat ?? 12;

  const getMemberName = (id?: string) => {
    const member = props.members.find((m) => m.id === id);
    if (member) {
      return member.name || (member as InviteeDTO).email || 'Unknown';
    }
    return 'Unknown';
  };

  const getMemberAvatar = (id: string) => {
    const member = props.members.find((m) => m.id === id);
    return member?.avatar;
  };

  const getColumnTitle = (id: string) => {
    const column = props.columnTitles.find((c) => c.id === id);
    return column ? column.title : undefined;
  };

  const getTagName = (id: string) => {
    const tag = props.tags.find((t) => t.id === id);
    return tag ? tag.name : 'Unknown';
  };

  const renderAvatar = (item: ActivityDTO | CommentDTO) => {
    const authorId =
      (item as CommentDTO).deletedBy || (item as ActivityDTO).authorId;
    return (
      <Thumbnail
        classes="size-24 icon"
        avatarData={getMemberAvatar(authorId)}
        title={getMemberName(authorId)}
      />
    );
  };

  const renderComment = (comment: CommentDTO) => {
    if (comment.deletedAt) {
      if (
        getMemberName(comment.deletedBy) === getMemberName(comment.authorId)
      ) {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:commentRemovedByUser"
              values={{
                time: getTime(comment.createdAt, timeFormat),
                user: getMemberName(comment.deletedBy),
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
              ]}
            />
          </p>
        );
      } else {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:removedComment"
              values={{
                time: getTime(comment.createdAt, timeFormat),
                deleter: getMemberName(comment.deletedBy),
                author: getMemberName(comment.authorId),
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                />,
                <strong key="2" />,
                <strong key="3" />,
              ]}
            />
          </p>
        );
      }
    }
    return (
      <>
        <p
          className="mb-2xs"
          style={{ position: 'relative', zIndex: 3 }}
        >
          <Trans
            i18nKey="cardActivity:commentWritten"
            values={{
              time: getTime(comment.createdAt, timeFormat),
              author: getMemberName(comment.authorId),
              edited:
                comment.createdAt !== comment.updatedAt ? t('edited') : '',
            }}
            components={[
              <small
                key="0"
                className="faint-text"
              />,
              <strong key="1" />,
              <span key="2" />,
            ]}
          />
        </p>
        <div>
          <UserComment
            disabled={props.disabled}
            boardCard={props.boardCard}
            boardUser={props.boardUser}
            comment={comment}
            updateComment={props.updateComment}
            removeComment={props.removeComment}
            handleDeleteComment={props.handleDeleteComment}
            members={props.members}
            cardAttachments={props.attachments}
          />
        </div>
      </>
    );
  };

  const renderActivity = (activity: ActivityDTO) => {
    switch (activity.kind) {
      case 'create': {
        const data = activity.data as ActivityAtCardCreationDTO;
        const assignedTags = data.tagIds.map((id) => getTagName(id)).join(', ');
        const assignedMembers = data.assigneeIds
          .map((id) => getMemberName(id))
          .join(', ');
        return (
          <p>
            <Trans
              i18nKey="cardActivity:activityCardCreated"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                user: getMemberName(activity.authorId),
                title: data.title,
                column: getColumnTitle(data.columnId),
                tags:
                  assignedTags.length > 0
                    ? t('withTags', { tags: assignedTags })
                    : '',
                priority: data.priorityName
                  ? t('withPriority', { priority: data.priorityName })
                  : '',
                members:
                  assignedMembers.length > 0
                    ? t('withMembers', { members: assignedMembers })
                    : '',
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                />,
                <strong key="2" />,
                <strong key="3" />,
                <strong key="4" />,
                assignedTags.length > 0 ? <strong key="5" /> : <></>,
                data.priorityId ? <strong key="6" /> : <></>,
                assignedMembers.length > 0 ? <strong key="7" /> : <></>,
              ]}
            />
          </p>
        );
      }
      case 'update_title':
        return (
          <p>
            <Trans
              i18nKey="cardActivity:titleChanged"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                user: getMemberName(activity.authorId),
                newTitle: (activity.data as ActivityUpdateTitleDTO).title,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                />,
                <strong key="2" />,
                <strong key="3" />,
              ]}
            />
          </p>
        );
      case 'add_subtask':
        return (
          <p>
            <Trans
              i18nKey="cardActivity:subtaskAdded"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                author: getMemberName(activity.authorId),
                title: (activity.data as unknown as ActivityAddRemoveSubtaskDTO)
                  .subtaskTitle,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                />,
                <strong key="2" />,
                <strong key="3" />,
              ]}
            />
          </p>
        );
      case 'update_subtask':
        return (
          <p>
            <Trans
              i18nKey={
                (activity.data as unknown as ActivityUpdateSubtaskDTO)
                  .subtaskChecked
                  ? 'cardActivity:subtaskComplete'
                  : 'cardActivity:subtaskIncomplete'
              }
              values={{
                time: getTime(activity.createdAt, timeFormat),
                author: getMemberName(activity.authorId),
                title: (activity.data as unknown as ActivityUpdateSubtaskDTO)
                  .subtaskTitle,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                />,
                <strong key="2" />,
                <strong key="3" />,
              ]}
            />
          </p>
        );
      case 'remove_subtask':
        return (
          <p>
            <Trans
              i18nKey="cardActivity:subtaskRemoved"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                author: getMemberName(activity.authorId),
                title: (activity.data as unknown as ActivityAddRemoveSubtaskDTO)
                  .subtaskTitle,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                />,
                <strong key="2" />,
                <strong key="3" />,
              ]}
            />
          </p>
        );
      case 'update_description':
        return (
          <p>
            <Trans
              i18nKey="cardActivity:descriptionChanged"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                changer: getMemberName(activity.authorId),
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
              ]}
            />{' '}
          </p>
        );
      case 'update_priority':
        return (
          <p>
            <Trans
              i18nKey="cardActivity:priorityChanged"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                changer: getMemberName(activity.authorId),
                priority:
                  (activity.data as ActivityUpdatePriorityDTO).priorityName ||
                  t('none'),
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
                <strong key="2" />,
              ]}
            />
          </p>
        );
      case 'archive':
        return (
          <p>
            <Trans
              i18nKey="cardActivity:cardArchived"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                archiver: getMemberName(activity.authorId),
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
              ]}
            />
          </p>
        );
      case 'restore':
        return (
          <p>
            <Trans
              i18nKey="cardActivity:cardRestored"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                restorer: getMemberName(activity.authorId),
                column: (activity.data as ActivityRestoreCardDTO).columnTitle,
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
                <strong key="2" />,
              ]}
            />
          </p>
        );
      case 'move':
        return (
          <p>
            <Trans
              i18nKey="cardActivity:cardMoved"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                mover: getMemberName(activity.authorId),
                column: (activity.data as ActivityMoveCardDTO).columnTitle,
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
                <strong key="2" />,
              ]}
            />
          </p>
        );
      case 'assigne_user': {
        const data = activity.data as ActivityAssignUserDTO;
        return (
          <p>
            {activity.authorId === data.memberId ? (
              <Trans
                i18nKey="cardActivity:userAssignedSelfToCard"
                values={{
                  time: getTime(activity.createdAt, timeFormat),
                  assigner: getMemberName(activity.authorId),
                }}
                components={[
                  <small
                    key="1"
                    className="faint-text"
                  />,
                  <strong key="2" />,
                ]}
              />
            ) : (
              <Trans
                i18nKey="cardActivity:userAssignedToCard"
                values={{
                  time: getTime(activity.createdAt, timeFormat),
                  assigner: getMemberName(activity.authorId),
                  assignee: getMemberName(data.memberId),
                }}
                components={[
                  <small
                    key="1"
                    className="faint-text"
                  />,
                  <strong key="2" />,
                  <strong key="3" />,
                ]}
              />
            )}
          </p>
        );
      }
      case 'unassigne_user': {
        const data = activity.data as ActivityUnassignUserDTO;
        return (
          <p>
            {activity.authorId === data.memberId ? (
              <Trans
                i18nKey="cardActivity:userUnassignedSelfFromCard"
                values={{
                  time: getTime(activity.createdAt, timeFormat),
                  assigner: getMemberName(activity.authorId),
                }}
                components={[
                  <small
                    key="1"
                    className="faint-text"
                  />,
                  <strong key="2" />,
                ]}
              />
            ) : (
              <Trans
                i18nKey="cardActivity:userUnassignedFromCard"
                values={{
                  time: getTime(activity.createdAt, timeFormat),
                  unassigner: getMemberName(activity.authorId),
                  unassigned: getMemberName(data.memberId),
                }}
                components={[
                  <small
                    key="1"
                    className="faint-text"
                  />,
                  <strong key="2" />,
                  <strong key="3" />,
                ]}
              />
            )}
          </p>
        );
      }
      case 'assigne_tag': {
        const data = activity.data as ActivityAssignTagDTO;
        return (
          <p>
            <Trans
              i18nKey="cardActivity:tagAddedToCard"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                assigner: getMemberName(activity.authorId),
                tagName: data.tagName,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                />,
                <strong key="2" />,
                <strong key="3" />,
              ]}
            />
          </p>
        );
      }
      case 'unassigne_tag': {
        const data = activity.data as ActivityUnassignTagDTO;
        return (
          <p>
            <Trans
              i18nKey="cardActivity:tagRemovedFromCard"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                remover: getMemberName(activity.authorId),
                tagName: data.tagName,
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
                <strong key="2" />,
              ]}
            />
          </p>
        );
      }
      case 'add_attachment': {
        const data = activity.data as ActivityAddAttachmentDTO;
        return (
          <p>
            <Trans
              i18nKey="cardActivity:attachmentAdded"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                adder: getMemberName(activity.authorId),
                attachmentFilename: data.attachmentFilename,
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
                <strong key="2" />,
              ]}
            />
          </p>
        );
      }
      case 'remove_attachment': {
        const data = activity.data as ActivityRemoveAttachmentDTO;
        return (
          <p>
            <Trans
              i18nKey="cardActivity:attachmentRemoved"
              values={{
                time: getTime(activity.createdAt, timeFormat),
                remover: getMemberName(activity.authorId),
                attachmentFilename: data.attachmentFilename,
              }}
              components={[
                <small
                  key="0"
                  className="faint-text"
                />,
                <strong key="1" />,
                <strong key="2" />,
              ]}
            />
          </p>
        );
      }
      default:
        return null;
    }
  };

  const renderMessageByType = (item: ActivityDTO | CommentDTO) => {
    if (!('kind' in item)) {
      return renderComment(item as CommentDTO);
    }
    return renderActivity(item as ActivityDTO);
  };

  const renderActivitiesAndComments = (groupedItem: GroupedActivityItem) => {
    return (
      <div
        className="flex-row fill reveal-up-1"
        key={groupedItem.date}
      >
        <div className="column pb-2xs">
          <div className="flex-row">
            <div className="column">
              <small className="faint-text">{groupedItem.date}</small>
            </div>
          </div>
          {groupedItem.items.map((item: IActivity | IComment) => (
            <div
              className="flex-row no-reflow squeeze flex-v-top"
              key={item.key}
            >
              <div className="column pt-0 pr-0 pb-2xs">
                {renderAvatar(item)}
              </div>
              <div className="column pt-2xs pb-2xs fill">
                {renderMessageByType(item)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderNoActivitiesOrComments = () => {
    switch (props.historyType) {
      case 'all':
        if (
          !props.groupedActivities.length &&
          props.activityStatus === 'success'
        )
          return (
            <div className="mt-xs card opaque">
              <span className="text-xs faint-text">{t('noEntries')}</span>
            </div>
          );
        break;
      case 'activity':
        if (
          !props.groupedActivities.length &&
          props.activityStatus === 'success'
        )
          return (
            <div className="mt-xs card opaque">
              <span className="text-xs faint-text">{t('noActivity')}</span>
            </div>
          );
        break;
      case 'comments':
        if (!props.groupedComments.length && props.activityStatus === 'success')
          return (
            <div className="mt-xs card opaque">
              <span className="text-xs faint-text">{t('noComments')}</span>
            </div>
          );
    }
  };

  return (
    <div>
      <div className="flex-row fill">
        <div className="column py-0">
          <div className="flex-row squeeze">
            <div className="column py-0">
              <div className="navigation-component horizontal">
                <ul className="nav-list">
                  <li className="nav-li pl-0">
                    <Button
                      onClick={() => props.setHistoryType('all')}
                      className={`nav-link ${props.historyType === 'all' ? 'active' : ''}`}
                    >
                      <div className="content-wrapper pt-2xs pb-3xs">
                        {t('allHistory')}
                      </div>
                    </Button>
                  </li>
                  <li className="nav-li">
                    <Button
                      onClick={() => props.setHistoryType('activity')}
                      className={`nav-link ${props.historyType === 'activity' ? 'active' : ''}`}
                    >
                      <div className="content-wrapper pt-2xs pb-3xs">
                        {t('activity')}
                      </div>
                    </Button>
                  </li>
                  <li className="nav-li">
                    <Button
                      onClick={() => props.setHistoryType('comments')}
                      className={`nav-link ${props.historyType === 'comments' ? 'active' : ''}`}
                    >
                      <div className="content-wrapper pt-2xs pb-3xs">
                        {t('comments')}
                      </div>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-break-word">
        {renderNoActivitiesOrComments()}
        {props.historyType === 'all' &&
          props.groupedActivityItems.map(renderActivitiesAndComments)}
        {props.historyType === 'activity' &&
          props.groupedActivities.map(renderActivitiesAndComments)}
        {props.historyType === 'comments' &&
          props.groupedComments.map(renderActivitiesAndComments)}
        {props.activityStatus === 'loading' && (
          <div className="flex-v-center py-sm">
            <p>
              <span className="loader"></span>{' '}
              <span className="ml-xs">{t('loadingEntries')}</span>
            </p>
          </div>
        )}
        {props.activityStatus === 'error' && (
          <div className="py-sm">
            <MessageBar
              type="danger"
              icon="exclamation-triangle accent-text-red"
            >
              <span className="fas fa-exclamation-triangle"></span>{' '}
              <span>{t('problemFetchingErrors')}</span>
            </MessageBar>
          </div>
        )}
        {(props.activityStatus === 'success' ||
          props.activityStatus === 'idle') && (
          <div
            className="flex-v-center py-sm"
            style={{ visibility: 'hidden' }}
          >
            <p>
              <span className="loader"></span>{' '}
              <span className="ml-xs">&nbsp;</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardActivity;
