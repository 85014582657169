import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import AppContext, { IAppContext } from '../../common/contexts/AppContext';
import { NotificationMessage } from '../../common/contexts/NotificationsContext';
import { withContextAdapters } from '../partials/ContextAdapter/withContextAdapter';
import BoardContext from '../../common/contexts/BoardContext';
import { IBoardContext } from '../../common/interfaces/BoardContext';
import { unite } from '../../common/helpers/unite';
import { useHistory } from 'react-router-dom';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}

interface ExternalProps {
  accordionSlug: string;
  title?: string;
  subheading?: string | JSX.Element;
  disabled?: boolean;
  isOpen?: boolean;
  iconClasses?: string;
  hideArrow?: boolean;
  softDisabled?: boolean;
  loading?: boolean;
  onToggle?: (isOpen: boolean) => void;
  translucent?: boolean;
  opaque?: boolean;
}

interface BoardContextProps {
  updateCard: (cardId: string, data: Record<string, unknown>) => void;
}

interface Props extends ExternalProps, AppContextProps, BoardContextProps {}

const Accordion: React.FC<Props> = ({
  accordionSlug,
  title,
  subheading,
  disabled,
  isOpen: initialIsOpen = false,
  iconClasses,
  hideArrow,
  softDisabled,
  loading,
  onToggle,
  translucent,
  children,
  opaque,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [isPristine, setIsPristine] = useState(true);
  const accordionRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  // Updated updateAccordionOnId with a null-check for accordionRef.current.
  const updateAccordionOnId = useCallback(() => {
    if (!accordionRef.current) return;
    setIsOpen(true);
    window.scrollTo({
      top: accordionRef.current.offsetTop,
      behavior: 'smooth',
    });

    // Clear URL hash
    const { pathname } = history.location;
    history.replace(pathname);
  }, [history]);

  // Replace useEffect with useLayoutEffect for scroll-related side effect.
  useLayoutEffect(() => {
    if (window.location.href.includes(accordionSlug)) {
      updateAccordionOnId();
    }
  }, [accordionSlug, updateAccordionOnId]);

  const toggleAccordion = useCallback(() => {
    setIsOpen((prev) => {
      const newIsOpen = !prev;
      setIsPristine((pristine) => (pristine ? false : pristine));
      if (onToggle) {
        onToggle(newIsOpen);
      }
      return newIsOpen;
    });
  }, [onToggle]);

  return (
    <div
      className="form-group mb-0"
      ref={accordionRef}
      id={accordionSlug}
    >
      <div
        className={unite({
          accordion: true,
          open: isOpen && !loading,
          'initially-open': initialIsOpen && isPristine,
          'fade-in-1': loading,
        })}
      >
        <button
          className={unite({
            'accordion-button': true,
            'soft-disabled': softDisabled,
            'hide-arrow': hideArrow,
            translucent: translucent && !opaque,
            opaque: opaque,
          })}
          disabled={disabled}
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-controls={`${accordionSlug}-content`}
        >
          <div className="flex-row no-reflow squeeze flex-v-center">
            <div className="column py-0">
              <span
                className={iconClasses}
                data-testid="accordion-icon"
              ></span>
            </div>
            <div className="column py-0">
              <span>{title}</span>
              {subheading && (
                <>
                  <br />
                  <small className="faint-text">{subheading}</small>
                </>
              )}
            </div>
          </div>
          <span className="icon-helper fas fa-angle-down"></span>
        </button>
        <div
          className="accordion-body"
          id={`${accordionSlug}-content`}
        >
          <div
            className={unite({
              'accordion-inner': true,
              translucent: translucent,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => ({
    setMessages: ctx.notifications.setMessages!,
  }),
};

const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => ({
    updateCard: ctx.updateCard,
  }),
};

// Wrap Accordion with React.memo for performance optimization.
export default React.memo(
  withContextAdapters<
    ExternalProps,
    IAppContext,
    AppContextProps,
    IBoardContext,
    BoardContextProps
  >(Accordion, AppContextAdapter, BoardContextAdapter),
);

// o3 Feb 3rd 2025: If you upgrade to react-router-dom v6, consider using useNavigate instead of useHistory.
