import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  FORMAT_TEXT_COMMAND,
  TextFormatType,
  $isRangeSelection,
  $isTextNode,
} from 'lexical';
import type { RangeSelection } from 'lexical';
import Button from '../../Button';
import { unite } from '../../../../common/helpers/unite';

export const blockFormatButtons: ReadonlyArray<{
  format: string;
  icon: string;
  ariaLabel: string;
}> = [
  { format: 'bold', icon: 'fas fa-bold', ariaLabel: 'Format Bold' },
  { format: 'italic', icon: 'fas fa-italic', ariaLabel: 'Format Italics' },
  {
    format: 'underline',
    icon: 'fas fa-underline',
    ariaLabel: 'Format Underline',
  },
  {
    format: 'strikethrough',
    icon: 'fas fa-strikethrough',
    ariaLabel: 'Format Strikethrough',
  },
  { format: 'code', icon: 'fas fa-code', ariaLabel: 'Format Code' },
];

export default function ToolbarBlockStylePlugin() {
  const [editor] = useLexicalComposerContext();

  // Helper: Check if the current selection has the format active.
  const isActive = (format: string): boolean => {
    let active = false;
    editor.getEditorState().read(() => {
      const selection = $getSelection();
      if (selection && $isRangeSelection(selection)) {
        active = selection.hasFormat(format as TextFormatType);
      }
    });
    return active;
  };
  const handleButtonClick = (format: string) => {
    editor.update(() => {
      const selection = $getSelection();
      if (!selection || !selection.isCollapsed()) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, format as TextFormatType);
        return;
      }

      const anchor = (selection as RangeSelection).anchor;
      const node = anchor.getNode();

      if ($isTextNode(node)) {
        const textContent = node.getTextContent();
        const offset = anchor.offset;

        // Use regex to find the word boundaries
        const match = [...textContent.matchAll(/\b\w+\b/g)].find(
          (m) =>
            m.index !== undefined &&
            m.index <= offset &&
            offset <= m.index + m[0].length,
        );

        if (match && match.index !== undefined) {
          const wordStart = match.index;
          const wordEnd = match.index + match[0].length;

          (selection as RangeSelection).setTextNodeRange(
            node,
            wordStart,
            node,
            wordEnd,
          );
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, format as TextFormatType);
        } else {
          console.warn('Could not expand to a word, skipping formatting.');
        }
      }
    });
  };

  return (
    <>
      {blockFormatButtons.map(({ format, icon, ariaLabel }) => (
        <li key={format}>
          <Button
            onClick={() => handleButtonClick(format)}
            title={ariaLabel}
            className={unite({
              'secondary-button': true,
              'active-border': isActive(format),
            })}
            aria-label={ariaLabel}
          >
            <i className={`${icon} pe-none`} />
          </Button>
        </li>
      ))}
    </>
  );
}
