import React from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { BoardItemDTO } from '../../../../common/api/dtos/Board';
import BoardListingCard from '../../../partials/board/BoardListing/BoardListingCard';
import ContentLoader from '../../../partials/ContentLoader/ContentLoader';
import ErrorList from '../../../error/ErrorList/ErrorList';
import CreateBoardButton from './CreateBoardButton';
import ArchivedBoardsPage from '../../Board/BoardArchive/ArchivedBoardsPage';
import { Trans, useTranslation } from 'react-i18next';
import ContextMenu from '../../../controls/ContextMenu';
import LinkButton from '../../../controls/LinkButton';
import AppContext, {
  IAppContext,
} from '../../../../common/contexts/AppContext';
import { TFunction } from 'i18next';
import dayjsHelper from '../../../../common/helpers/dayjsHelper';
import NoBoardsMessage from './NoBoardsMessage';
import { NavLink } from 'react-router-dom';
import MessageBar from '../../../controls/MessageBar';
import Button from '../../../controls/Button';
import ISubscription from '../../../../common/interfaces/Subscription';

interface PrivateSpaceShelfProps {
  boards: BoardItemDTO[];
  status: TRequestStatus;
  showArchive: boolean;
  serverErrors: string[];
  toggleArchiveView: () => void;
  callback?: () => void;
}

const renderSubscriptionStatus = (
  subscription: ISubscription | null,
  t: TFunction<'home'>,
  context: IAppContext,
  usedFreeBoard: boolean,
  status: TRequestStatus,
): JSX.Element | null => {
  if (subscription === null) {
    return <></>;
  } else if (
    subscription.period === 'active' &&
    subscription.cancelAt !== null
  ) {
    return (
      <div className="flex-row fill">
        <div className="column px-2xs py-0">
          <MessageBar
            type="warning"
            icon="fa-exclamation-circle"
          >
            {t('personalWillCancel', {
              cancelAt: dayjsHelper(
                context.loggedUser?.subscription?.cancelAt,
              ).format('MMMM Do, YYYY'),
            })}{' '}
            {t('avoidGracePrefix')}{' '}
            <NavLink
              to={`/account/plans/personal-plan`}
              className={'link-button'}
            >
              <span className="text">{t('avoidGraceMid')}</span>
            </NavLink>{' '}
            {t('avoidGraceSuffix')}
          </MessageBar>
        </div>
      </div>
    );
  } else if (subscription.period === 'grace') {
    return (
      <>
        <div className="flex-row fill">
          <div className="column px-2xs py-0">
            <MessageBar
              type="danger"
              icon="fa-exclamation-circle"
            >
              <Trans
                i18nKey="home:personalWillDelete"
                values={{
                  deleteAt: dayjsHelper(
                    context.loggedUser?.subscription?.deleteAt,
                  ).format('MMMM Do, YYYY'),
                }}
                components={{
                  strong: <strong />,
                }}
              />{' '}
              <NavLink
                to={`/account/plans/personal-plan`}
                className={'link-button'}
              >
                <span className="text">{t('reactivate')}</span>
              </NavLink>{' '}
              {t('reactivateRest')}
            </MessageBar>
          </div>
        </div>
        {status !== 'loading' && (
          <div className="flex-row fill">
            <div className="column px-2xs py-0">
              <MessageBar
                type="info"
                icon="fa-info-circle"
              >
                {!usedFreeBoard
                  ? t('noFreeBoardCreated')
                  : t('withFreeBoardCreated')}
              </MessageBar>
            </div>
          </div>
        )}
      </>
    );
  } else if (subscription.period === 'suspended') {
    return (
      <div className="flex-row fill">
        <div className="column px-2xs py-0">
          <MessageBar
            type="warning"
            icon="fa-exclamation-circle"
          >
            <form
              target="_blank"
              method="POST"
              action={`${process.env.REACT_APP_API_BASE_URL}/self/updatePaymentMethod`}
            >
              <Trans
                i18nKey="home:personalPaymentIssue"
                values={{
                  endDate: dayjsHelper(
                    context.loggedUser?.subscription?.paymentRetryUntil,
                  ).format('MMMM Do, YYYY'),
                }}
                components={{
                  Button: (
                    <Button
                      type="submit"
                      className="link-button"
                    />
                  ),
                  span: <span className="text"></span>,
                  strong: <strong />,
                }}
              />
            </form>
          </MessageBar>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const PrivateSpaceShelf: React.FC<PrivateSpaceShelfProps> = ({
  boards,
  status,
  showArchive,
  serverErrors,
  toggleArchiveView,
  callback,
}) => {
  const { t } = useTranslation('home');
  const context = React.useContext(AppContext);

  const subscription: ISubscription | null =
    context.loggedUser?.subscription ?? null;

  // Determine if the user has used their free board
  const usedFreeBoard = boards.some(
    (board: BoardItemDTO) => board.paid === false,
  );

  return (
    <div className="flex-row fill">
      <div className="column pt-0">
        <div className="flex-row no-reflow fill flex-v-center flex-h-spread">
          <div className="column py-2xs">
            <h2 className="primary-title h3 normalcase no-wrap">
              <span>{t('personal')}</span>
              <ErrorList errors={serverErrors} />
            </h2>
          </div>
          <div className="column py-2xs pr-2xs">
            <ul className="control-list-component flex-h-end no-wrap">
              <li>
                {usedFreeBoard ? (
                  subscription?.period === 'grace' ? (
                    <LinkButton
                      to="/account/plans/personal-plan"
                      className="secondary-button"
                    >
                      <span className="fas fa-rocket-launch icon"></span>
                      <span className="text">{t('reactivatePlan')}</span>
                    </LinkButton>
                  ) : (
                    <LinkButton
                      to="/account/plans/personal-plan"
                      className="primary-button"
                    >
                      <span className="text">{t('upgradePlan')}</span>
                    </LinkButton>
                  )
                ) : (
                  boards.length !== 0 && (
                    <CreateBoardButton
                      disabled={false}
                      shrinkOnMobile
                    />
                  )
                )}
              </li>
              <li>
                <ContextMenu
                  dept={0}
                  title={t('personalOptions')}
                  triggerContent={
                    <span className="icon fas fa-ellipsis-h"></span>
                  }
                  triggerClassDefault="ghost-button"
                  triggerClassActive="secondary-button"
                >
                  <li className={showArchive ? 'active' : ''}>
                    <button
                      className="ghost-button"
                      onClick={toggleArchiveView}
                    >
                      <span className="fal fa-archive icon"></span>
                      <span className="text">{t('archivedBoards')}</span>
                    </button>
                  </li>
                  <li>
                    <hr />
                  </li>
                  <li>
                    {subscription === null ? (
                      <LinkButton
                        to="/account/plans/personal-plan"
                        className="ghost-button"
                      >
                        <span className="fas fa-rocket-launch icon accent-text"></span>
                        <span className="text">{t('upgradePlan')}</span>
                      </LinkButton>
                    ) : subscription.period === 'active' &&
                      subscription.cancelAt === null ? (
                      <LinkButton
                        to="/account/plans/personal-plan"
                        className="ghost-button"
                      >
                        <span className="fas fa-rocket-launch icon"></span>
                        <span className="text">{t('managePlan')}</span>
                      </LinkButton>
                    ) : (subscription.period === 'active' &&
                        subscription.cancelAt !== null) ||
                      subscription.period === 'grace' ? (
                      <LinkButton
                        to="/account/plans/personal-plan"
                        className="ghost-button"
                      >
                        <span className="fas fa-rocket-launch icon"></span>
                        <span className="text">{t('reactivatePlan')}</span>
                      </LinkButton>
                    ) : subscription.period === 'suspended' ? (
                      <LinkButton
                        to="/account/plans/personal-plan"
                        className="ghost-button"
                      >
                        <span className="fas fa-exclamation-triangle accent-text-yellow icon"></span>
                        <span className="text">{t('resolvePaymentIssue')}</span>
                      </LinkButton>
                    ) : null}
                  </li>
                </ContextMenu>
              </li>
            </ul>
          </div>
        </div>

        {renderSubscriptionStatus(
          subscription,
          t,
          context,
          usedFreeBoard,
          status,
        )}
        {status === 'loading' && !showArchive ? (
          <div className="flex-row card-list-component">
            <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
              <ContentLoader height="96px" />
            </div>
            <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
              <ContentLoader height="96px" />
            </div>
            <div className="column px-2xs pt-xs pb-0 flex-v-stretch">
              <ContentLoader height="96px" />
            </div>
          </div>
        ) : showArchive ? (
          <ArchivedBoardsPage toggleArchiveView={toggleArchiveView} />
        ) : (
          <div className="flex-row card-list-component pt-0 fade-in-grow">
            {boards.length !== 0 ? (
              boards.map((board: BoardItemDTO) => (
                <div
                  key={board.id}
                  className="column px-2xs pt-xs pb-0 flex-v-stretch"
                  style={{ height: '104px' }}
                >
                  <BoardListingCard
                    board={board}
                    subscription={subscription}
                    disabled={subscription?.period !== 'active' && board.paid}
                    callback={() => {
                      callback && callback();
                    }}
                  />
                </div>
              ))
            ) : (
              <NoBoardsMessage />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivateSpaceShelf;
