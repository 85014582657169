import { Component } from 'react';
import { BoardDTO } from '../../../../common/api/dtos/Board';
import BorddoUser from '../../BorddoUser';

interface ExpandedProps {
  boardId: string;
  color: string;
  boardDetails: Partial<BoardDTO> | null;
  loading: boolean;
}

class BoardListingCardExpanded extends Component<ExpandedProps> {
  render() {
    const { boardDetails, loading, color } = this.props;
    if (loading) {
      return (
        <div className={`accent-text ${color}-accent`}>
          <span className={`loader`}></span>
        </div>
      );
    }
    if (!boardDetails) {
      return <div>No details available</div>;
    }
    return (
      <ul className="control-list-component py-0 my-0">
        {boardDetails.owner && (
          <li>
            <BorddoUser
              size={24}
              avatarData={boardDetails.owner.avatar}
              title={boardDetails.owner.name}
              email={
                'email' in boardDetails.owner
                  ? (boardDetails.owner.email as string)
                  : undefined
              }
              avatarOnly={true}
            />
          </li>
        )}
        {boardDetails.members && boardDetails.members.length > 0 && (
          <li className="flex-v-center">
            {boardDetails.members.slice(0, 5).map((member) => {
              return (
                <div
                  key={member.id}
                  style={{
                    marginRight: '-12px',
                    filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))',
                  }}
                >
                  <BorddoUser
                    size={24}
                    avatarData={member.avatar}
                    title={member.name}
                    avatarOnly={true}
                  />
                </div>
              );
            })}
            {boardDetails.members.length > 5 && (
              <div
                style={{
                  filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))',
                }}
              >
                <BorddoUser
                  size={24}
                  title={`+ ${boardDetails.members.length - 5} more`}
                  avatarOnly={true}
                />
              </div>
            )}
          </li>
        )}
        {boardDetails.invitees && boardDetails.invitees.length > 0 && (
          <li
            className="flex-v-center"
            style={{
              filter: 'brightness(0.2)',
            }}
          >
            {boardDetails.invitees.slice(0, 5).map((invitee) => {
              return (
                <div
                  key={invitee.id}
                  style={{
                    marginRight: '-12px',
                    filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))',
                  }}
                >
                  <BorddoUser
                    size={24}
                    avatarData={invitee.avatar}
                    title={invitee.name ?? invitee.email}
                    email={
                      'email' in invitee ? (invitee.email as string) : undefined
                    }
                    avatarOnly={true}
                  />
                </div>
              );
            })}
            {boardDetails.invitees.length > 5 && (
              <div
                style={{
                  filter: ' drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))',
                }}
              >
                <BorddoUser
                  size={24}
                  title={'…'}
                  avatarOnly={true}
                />
              </div>
            )}
          </li>
        )}
      </ul>
    );
  }
}

export default BoardListingCardExpanded;
