import React, { useState } from 'react';
import Button from '../../../controls/Button'; // adjust path if needed
import { unite } from '../../../../common/helpers/unite';
import BoardCardPlaceholder from './BoardCardPlaceholder';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { useTranslation } from 'react-i18next';
import { ICard, IColumn } from '../../../../common/interfaces/BoardContext';

interface BoardCardFormProps {
  boardColumn: IColumn; // adjust type accordingly
  columnCardLimitState: string;
  setNewCard: (columnId: string, card: ICard) => void; // adjust types as needed
}

const BoardCardForm: React.FC<BoardCardFormProps> = ({
  boardColumn,
  columnCardLimitState,
  setNewCard,
}) => {
  const [showForm, setShowForm] = useState(false);
  const t = useTranslation('boardColumnExpanded').t;
  const cardLimit = boardColumn.cardLimit || 0;
  const isDisabled =
    showForm ||
    (columnCardLimitState !== 'disabled' &&
      cardLimit > 0 &&
      boardColumn.cards.length >= cardLimit &&
      columnCardLimitState);

  return (
    <>
      {!showForm && (
        <div className="flex-row tight-bottom fill add-card-button mx-0">
          <div className="column pt-0 pb-0 px-0">
            <Button
              className={unite({
                'secondary-button': true,
                fill: true,
                'flex-h-center': true,
              })}
              onClick={() => setShowForm(true)}
              disabled={!!isDisabled}
            >
              {isDisabled ? (
                t('limitEnforced')
              ) : (
                <>
                  <span className="fas fa-plus-circle text-xs mr-xs"></span>
                  <span className="text">{t('addCard')}</span>
                </>
              )}
            </Button>
          </div>
        </div>
      )}
      {showForm && (
        <div style={{ marginTop: '-190px', position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              height: '220px',
              top: '11px',
              left: '2px',
              right: '2px',
              bottom: '2px',
              borderRadius: '8px',
              backdropFilter: 'blur(15px)',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
            }}
          ></div>
          <BoardCardPlaceholder
            addCardForm={() => {
              setShowForm(false);
            }}
            boardColumn={boardColumn}
            setNewCard={setNewCard}
          />
        </div>
      )}
    </>
  );
};

export default withStyledTranslation('boardColumnExpanded')(BoardCardForm);
