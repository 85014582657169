import React, { useState, useEffect, useRef } from 'react';
import { ErrorNotificationPayload } from '../../../common/helpers/errorNotificationPayload';
import { SuccessNotificationPayload } from '../../../common/helpers/successNotificationPayload';
import { InfoNotificationPayload } from '../../../common/helpers/infoNotificationPayload';
import i18n from '../../../i18n';
import { generateLogo } from '../../../common/helpers/dynamicFavicon';

interface Props {
  id: number;
  notificationData:
    | ErrorNotificationPayload
    | SuccessNotificationPayload
    | InfoNotificationPayload;
  onDestroy: () => void;
}

const Notification: React.FC<Props> = ({ id, notificationData, onDestroy }) => {
  const [isExiting, setIsExiting] = useState(false);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [showInApp, setShowInApp] = useState(true);
  const timerId = useRef<NodeJS.Timeout | null>(null);

  const setSelfDestruct = () => {
    if (!(notificationData instanceof ErrorNotificationPayload)) {
      timerId.current = setTimeout(() => {
        destroyNotification();
      }, 5000);
    }
  };

  const clearSelfDestruct = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
  };

  const requestBrowserNotification = () => {
    if (window.Notification) {
      if (window.Notification.permission === 'granted') {
        showBrowserNotification();
        setShowInApp(false);
      } else if (window.Notification.permission !== 'denied') {
        window.Notification.requestPermission().then(
          (permission: NotificationPermission) => {
            if (permission === 'granted') {
              showBrowserNotification();
              setShowInApp(false);
            }
          },
        );
      }
    }
  };

  const getAccentColor = (): string => {
    const className = document.documentElement.className;
    const regex = /(\w+)-accent/;
    const match = className.match(regex);
    return match ? match[1] : 'default';
  };

  const showBrowserNotification = () => {
    // Get dynamic values from notificationData if available
    const dynamicTitle =
      'title' in notificationData && notificationData.title
        ? (notificationData as any).title
        : 'Borddo';
    const dynamicBody =
      notificationData.message ||
      ('validationMessage' in notificationData &&
        notificationData.validationMessage) ||
      'A borddo notification';
    const options: NotificationOptions = {
      body: dynamicBody,
      icon:
        'icon' in notificationData && (notificationData as any).icon
          ? (notificationData as any).icon
          : generateLogo(getAccentColor()),
      badge:
        'badge' in notificationData && (notificationData as any).badge
          ? (notificationData as any).badge
          : generateLogo(getAccentColor()),
      data:
        'data' in notificationData && (notificationData as any).data
          ? (notificationData as any).data
          : generateLogo(getAccentColor()),
      lang:
        'lang' in notificationData && (notificationData as any).lang
          ? (notificationData as any).lang
          : 'en',
      requireInteraction:
        'requireInteraction' in notificationData &&
        (notificationData as any).requireInteraction
          ? (notificationData as any).requireInteraction
          : false,
      silent:
        'silent' in notificationData && (notificationData as any).silent
          ? (notificationData as any).silent
          : false,
      tag:
        'tag' in notificationData && (notificationData as any).tag
          ? (notificationData as any).tag
          : 'borddo',
    };
    new window.Notification(dynamicTitle, options);
  };

  const destroyNotification = () => {
    setIsExiting(true);
    setTimeout(() => {
      onDestroy();
    }, 200);
  };

  const handleMouseEnter = () => clearSelfDestruct();
  const handleMouseLeave = () => setSelfDestruct();
  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    destroyNotification();
  };
  const handleFocus = () => clearSelfDestruct();
  const handleBlur = () => setSelfDestruct();
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };
  const handleTouchEnd = () => {
    if (touchStart === null || touchEnd === null) return;
    const minDistance = 50;
    const distance = touchStart - touchEnd;
    if (distance > minDistance || distance < -minDistance) {
      destroyNotification();
    }
  };

  const getType = () => {
    if (notificationData instanceof ErrorNotificationPayload) return 'error';
    if (notificationData instanceof SuccessNotificationPayload)
      return 'success';
    if (notificationData instanceof InfoNotificationPayload) return 'info';
    return null;
  };

  const renderErrorContent = () => {
    if (notificationData instanceof ErrorNotificationPayload) {
      return (
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <p className="us-none mb-xs">
            <small className="fas fa-exclamation-triangle accent-text-red text-v-mid"></small>{' '}
            <span className="text-v-mid text-sm">{i18n.t('error')}</span>
          </p>
          {notificationData.validationMessage && (
            <p className="faint-text us-none">
              <small>{notificationData.validationMessage}</small>
            </p>
          )}
          {notificationData.message && (
            <p className="faint-text us-none">
              <small>{notificationData.message}</small>
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  const renderSuccessContent = () => {
    if (notificationData instanceof SuccessNotificationPayload) {
      return (
        <div style={{ paddingRight: '30px' }}>
          <span className="fas fa-check accent-text-green"></span>{' '}
          <span>Success</span>
          <br />
          <p>
            <small className="faint-text">{notificationData.message}</small>
          </p>
        </div>
      );
    }
    return null;
  };

  const renderInfoContent = () => {
    if (notificationData instanceof InfoNotificationPayload) {
      return (
        <div style={{ paddingRight: '30px' }}>
          <span className="fas fa-info-circle"></span>{' '}
          <span className="">Info</span>
          <br />
          <p>
            <small className="faint-text">{notificationData.message}</small>
          </p>
        </div>
      );
    }
    return null;
  };

  const renderBody = () => {
    const type = getType();
    if (type === 'error') return renderErrorContent();
    if (type === 'success') return renderSuccessContent();
    if (type === 'info') return renderInfoContent();
    return null;
  };

  useEffect(() => {
    setSelfDestruct();
    requestBrowserNotification();
    return () => clearSelfDestruct();
  }, []);

  if (!showInApp) return null;

  return (
    <>
      {getType() && (
        <div
          className={`notification-item ${!isExiting ? 'reveal-right-1' : 'hide-right-1'} card`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            animationDelay: `0.${id}s`,
          }}
        >
          {renderBody()}
          <button
            className="ghost-button top-tight right-tight"
            onClick={handleCloseClick}
            onFocus={handleFocus}
            onBlur={handleBlur}
          >
            <span className="fas fa-times"></span>
          </button>
        </div>
      )}
    </>
  );
};

export default Notification;
