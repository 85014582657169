import { useHistory, useLocation } from 'react-router-dom';
import { createSlug } from './createSlug';

export function useItemSlug() {
  // Replace props with hooks:
  const history = useHistory();
  const location = useLocation();

  const addItemSlug = (
    card: { number: number; id: string; title: string },
    cardPrefix: string,
  ) => {
    const { pathname } = location;
    const cardId = pathname.indexOf('/view/');
    let urlBase: string;
    if (cardId === -1) {
      urlBase = pathname.replace('/view', '');
    } else {
      urlBase = pathname.substring(0, cardId);
    }
    const sanitizedSlug = createSlug(
      `${cardPrefix ? cardPrefix + '-' : ''}${card.number}-${card.title}`,
    );
    const newUrl = `${urlBase}/view/card/${encodeURIComponent(card.id)}/${sanitizedSlug}`;
    history.push(newUrl);
  };

  const removeItemSlug = (boardName: string) => {
    const pathString = location.pathname.includes('board-archived-cards')
      ? '/view/card/'
      : '/card/';
    const { pathname } = location;
    const cardId = pathname.indexOf(pathString);
    let urlBase: string;
    if (cardId === -1) {
      urlBase = pathname;
    } else {
      urlBase = pathname.substring(0, cardId);
    }
    const newURL = location.pathname.includes('board-archived-cards')
      ? `${urlBase}`
      : `${urlBase}/${createSlug(boardName || '')}`;
    history.push(newURL);
  };

  const getItemSlug = () => {
    const { pathname } = location;
    const cardId = pathname.indexOf('/view/card/');
    if (cardId === -1) {
      return '';
    }
    const cardSlug = pathname.substring(cardId + 11);
    const cardSlugParts = cardSlug.split('/');
    return cardSlugParts[1];
  };

  return { addItemSlug, removeItemSlug, getItemSlug };
}
