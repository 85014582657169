export const alignmentButtons: ReadonlyArray<{
  format: string;
  icon: string;
  ariaLabel: string;
}> = [
  { format: 'left', icon: 'fas fa-align-left', ariaLabel: 'Align Left' },
  { format: 'center', icon: 'fas fa-align-center', ariaLabel: 'Align Center' },
  { format: 'right', icon: 'fas fa-align-right', ariaLabel: 'Align Right' },
  { format: 'justify', icon: 'fas fa-align-justify', ariaLabel: 'Justify' },
];
