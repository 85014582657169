import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  INDENT_CONTENT_COMMAND,
  KEY_TAB_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  $isParagraphNode,
  ElementNode,
} from 'lexical';

function calculateIndentLevel(): number {
  let indent = 0;

  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return 0;

  const nodes = selection.getNodes();
  for (const node of nodes) {
    let element = node;

    // Walk up to an element node
    while (element !== null && !$isElementNode(element)) {
      if (element.getParent() === null) break; // Avoid infinite loop if parent is null
      element = element.getParent() as ElementNode;
    }

    // Walk up to a block-level element like paragraph (adapt to your setup)
    while (element !== null && !$isParagraphNode(element)) {
      if (element.getParent() === null) break; // Avoid infinite loop if parent is null
      element = element.getParent() as ElementNode;
    }

    if (element !== null) {
      const indentAttr = (element as ElementNode).getIndent?.();
      if (typeof indentAttr === 'number') {
        indent = Math.max(indent, indentAttr);
      } else {
        const style = (element as ElementNode).getStyle?.();
        const match = style?.match(/margin-left:\s*(\d+)px/);
        if (match) {
          const px = parseInt(match[1], 10);
          indent = Math.max(indent, Math.floor(px / 20));
        }
      }
    }
  }

  return indent;
}

export default function ToolbarIndentationPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_TAB_COMMAND,
      (event: KeyboardEvent) => {
        const isShift = event.shiftKey;
        event.preventDefault();

        editor.update(() => {
          const indentLevel = calculateIndentLevel();

          if (!isShift && indentLevel >= 3) {
            return;
          }

          editor.dispatchCommand(
            isShift ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND,
            undefined,
          );
        });

        return true;
      },
      1,
    );
  }, [editor]);

  return null;
}
