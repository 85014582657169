import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';

interface VElementScrollbarProps {
  children: ReactNode;
  offsetY?: number;
}

const VElementScrollbar: React.FC<VElementScrollbarProps> = ({
  children,
  offsetY = 0,
}) => {
  const [thumbHeight, setThumbHeight] = useState<number>(0);
  const [thumbTop, setThumbTop] = useState<number>(0);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTopAtDragStart, setScrollTopAtDragStart] = useState(0);
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLElement | null>(null);
  const updateScheduled = useRef(false);

  const updateThumb = useCallback(() => {
    if (!parentRef.current) return;
    if (updateScheduled.current) return;
    updateScheduled.current = true;

    requestAnimationFrame(() => {
      const { scrollHeight, clientHeight, scrollTop } = parentRef.current!;
      const newThumbHeight = (clientHeight / scrollHeight) * clientHeight;
      const newThumbTop = (scrollTop / scrollHeight) * clientHeight;
      setThumbHeight(newThumbHeight);
      setThumbTop(newThumbTop);
      setIsScrollable(scrollHeight > clientHeight);
      updateScheduled.current = false;
    });
  }, []);

  useEffect(() => {
    const parentElement = scrollbarRef.current?.parentElement?.querySelector(
      '.scroll-content, .ReactVirtualized__Grid.ReactVirtualized__List',
    ) as HTMLElement;
    parentRef.current = parentElement;
    if (!parentElement) return;

    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes' || mutation.type === 'childList') {
          updateThumb();
        }
      }
    });
    observer.observe(parentElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    const handleScroll = () => {
      updateThumb();
    };

    parentElement.addEventListener('scroll', handleScroll, { passive: true });
    updateThumb();

    return () => {
      observer.disconnect();
      parentElement.removeEventListener('scroll', handleScroll);
    };
  }, [updateThumb]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!parentRef.current) return;
    setIsDragging(true);
    setStartY(e.clientY);
    setScrollTopAtDragStart(parentRef.current.scrollTop);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging || !parentRef.current) return;
      e.preventDefault();
      const deltaY = e.clientY - startY;
      const { scrollHeight, clientHeight } = parentRef.current;
      parentRef.current.scrollTop =
        scrollTopAtDragStart + (deltaY * scrollHeight) / clientHeight;
    };
    const handleMouseUp = () => setIsDragging(false);

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startY, scrollTopAtDragStart]);

  return (
    <div
      className="virtual-custom-scrollbar"
      ref={scrollbarRef}
    >
      {children}
      {isScrollable && (
        <div
          className={`virtual-custom-scrollbar-track${isDragging ? ' dragging' : ''}`}
          style={{ transform: `translateY(${offsetY}px)` }} // applied offsetY
        >
          <div
            className="virtual-custom-scrollbar-thumb"
            style={{ height: `${thumbHeight}px`, top: `${thumbTop}px` }}
            onMouseDown={handleMouseDown}
            ref={thumbRef}
          ></div>
        </div>
      )}
    </div>
  );
};

export default VElementScrollbar;
