// Home.tsx

import React, { Component } from 'react';
import AppContext from '../../../common/contexts/AppContext';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Toolbar from '../../partials/Toolbar/Toolbar';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import {
  getQuickAccess,
  listBoards,
} from '../../../common/api/endpoints/board';
import { IBoardListQuery } from '../../../common/interfaces/BoardListQuery';
import { BoardItemDTO } from '../../../common/api/dtos/Board';
import { NavLink } from 'react-router-dom';
import NewMenu from '../../menus/New/NewMenu';
import { listTeams } from '../../../common/api/endpoints/team';
import { TeamDTO } from '../../../common/api/dtos/Team';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

// Import the new components
import QuickAccessSection from './partials/QuickAccessSection';
import TeamSpaceShelf from './partials/TeamSpaceShelf';
import PWAInstall from '../../partials/PWA/PWAInstall';
import WelcomeDialog from '../../partials/Onboarding/WelcomeDialog';
import ShelfManager from './partials/ShelfManager';

import ContextMenu from '../../controls/ContextMenu';
import DeletedAccount from '../Settings/Account/DeletedAccount';
import PrivateSpaceShelf from './partials/PrivateSpaceShelf';

export interface Props extends WithTranslation {
  lockedAccount: boolean;
  deleteAt: string;
  unlockApplication: () => void;
  setLockedAccount: (lockedAccount: boolean) => void;
}

interface State {
  status: {
    personal: TRequestStatus;
    teams: { [teamId: string]: TRequestStatus };
    archived: {
      personal: TRequestStatus;
      teams: { [teamId: string]: TRequestStatus };
    };
  };
  statusQuickAccess: TRequestStatus;
  boards: { [key: string]: BoardItemDTO[] };
  loadingBoards: boolean[];
  quickAccessBoards: BoardItemDTO[] | undefined;
  teams: TeamDTO[];
  serverErrors: string[];
  reorderQuickAccess: boolean;
  showArchive: {
    personal: boolean;
    teams: { [teamId: string]: boolean };
  };
  page: string;
  loadingMore: boolean;
  nextPage: string | null;
  hiddenTeams: string[];
  clearHiddenTeamsDialog: boolean;
}

class Home extends Component<Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  private scrollPromise: Promise<void> | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      status: {
        personal: 'loading',
        teams: {},
        archived: {
          personal: 'idle',
          teams: {},
        },
      },
      boards: {
        personal: [],
      },
      statusQuickAccess: 'loading',
      loadingBoards: [],
      quickAccessBoards: [],
      teams: [],
      serverErrors: [],
      reorderQuickAccess: false,
      showArchive: {
        personal: false,
        teams: {},
      },
      page: '',
      loadingMore: false,
      nextPage: null,
      hiddenTeams: [],
      clearHiddenTeamsDialog: false,
    };
  }

  componentDidMount() {
    if (!this.props.lockedAccount) {
      this.fetchBoardsForContext(null); // Fetch personal boards
      this.fetchAllTeams();
      this.fetchQuickAccess();
      this.checkIfMoreContentNeeded();
      this.setState({
        hiddenTeams: JSON.parse(
          localStorage.getItem('hiddenShelves' + this.context.loggedUser?.id) ||
            '[]',
        ),
      });
    }

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.lockedAccount !== prevProps.lockedAccount &&
      !this.props.lockedAccount
    ) {
      this.fetchBoardsForContext(null); // Fetch personal boards
      this.fetchAllTeams();
      this.fetchQuickAccess();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up scroll listener
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  }

  checkIfMoreContentNeeded = () => {
    if (this.state.loadingMore) return;
    if (
      document.documentElement.scrollHeight - 100 <= window.innerHeight &&
      this.state.nextPage
    ) {
      this.setState({ loadingMore: true }, () => {
        this.fetchMoreTeams();
      });
    }
  };

  removeServerErrors = () => {
    this.setState({
      serverErrors: [],
    });
  };

  handleResize = () => {
    this.checkIfMoreContentNeeded(); // Trigger content check on resize
  };

  handleScroll = () => {
    if (this.scrollPromise) return;

    this.scrollPromise = new Promise((resolve) => {
      if (!this.state.nextPage) {
        this.scrollPromise = null;
        return resolve();
      }

      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        this.setState({ loadingMore: true }, async () => {
          await this.fetchMoreTeams();
          this.scrollPromise = null;
          resolve();
        });
      } else {
        this.scrollPromise = null;
        resolve();
      }
    });
  };

  fetchBoardsForContext = async (teamId: string | null, archived = false) => {
    const isPersonal = teamId === null;
    const query: IBoardListQuery = {
      name: isPersonal ? 'personal' : `team_${teamId}`,
      params: [
        { param: 'archived', value: archived ? 'true' : 'false' },
        { param: 'teamId', value: isPersonal ? 'null' : teamId },
      ],
    };

    // Set loading status for the specific context
    if (isPersonal) {
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          personal: 'loading',
        },
      }));
    } else {
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          teams: { ...prevState.status.teams, [teamId!]: 'loading' },
        },
      }));
    }

    try {
      const { boards } = await listBoards(query);

      this.setState((prevState) => ({
        boards: {
          ...prevState.boards,
          [teamId || 'personal']: boards as unknown as BoardItemDTO[], // Store boards under teamId or 'personal'
        },
        status: {
          ...prevState.status,
          personal: isPersonal ? 'success' : prevState.status.personal,
          teams: teamId
            ? { ...prevState.status.teams, [teamId]: 'success' }
            : prevState.status.teams,
        },
      }));
    } catch (err) {
      this.setState((prevState) => ({
        status: {
          ...prevState.status,
          personal: isPersonal ? 'error' : prevState.status.personal,
          teams: teamId
            ? { ...prevState.status.teams, [teamId]: 'error' }
            : prevState.status.teams,
        },
        serverErrors: [err as string],
      }));
    }
  };

  fetchMoreTeams = async () => {
    const { nextPage, teams: existingTeams } = this.state;

    if (!nextPage) return; // No more pages to load

    try {
      const { teams: newTeams, nextPage: newNextPage } = await listTeams(
        process.env.REACT_APP_TEAM_LAZYLOAD_PAGE_SIZE,
        nextPage,
      );

      const filteredTeams = newTeams.filter(
        (newTeam) =>
          !existingTeams.some((existingTeam) => existingTeam.id === newTeam.id),
      );

      this.setState(
        (prevState) => ({
          teams: [...prevState.teams, ...filteredTeams],
          loadingMore: false,
          loadingBoards: Array(
            [...prevState.teams, ...filteredTeams].length,
          ).fill(true),
          nextPage: newNextPage,
        }),
        () => {
          // Fetch boards for new teams
          filteredTeams.forEach((team) => {
            this.fetchBoardsForContext(team.id);
          });
          // Check if more content is needed after updating state
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        loadingMore: false,
        serverErrors: [err as string],
      });
    }
  };

  fetchAllTeams = async () => {
    this.setState((prevState) => ({
      status: {
        ...prevState.status,
        personal: 'loading',
        teams: {},
        archived: {
          personal: 'idle',
          teams: {},
        },
      },
    }));
    try {
      const { teams, nextPage } = await listTeams(
        process.env.REACT_APP_TEAM_LAZYLOAD_PAGE_SIZE,
      );

      this.setState(
        {
          teams,
          loadingBoards: Array(teams.length).fill(true),
          nextPage,
        },
        () => {
          // Check if more content is needed after the first fetch
          this.state.teams.forEach((team) => {
            this.fetchBoardsForContext(team.id); // Fetch boards for each team
          });
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      console.debug(err);
    }
  };

  fetchQuickAccess = async () => {
    try {
      this.setState({ statusQuickAccess: 'loading' });
      const { boards } = await getQuickAccess();
      this.setState({
        quickAccessBoards: Array.isArray(boards) ? boards : undefined,
        statusQuickAccess: 'success',
      });
    } catch (err) {
      console.debug(err);
      this.setState({
        statusQuickAccess: 'error',
        quickAccessBoards: undefined,
      });
    }
  };

  clearHiddenTeams = (teamId: string) => {
    this.setState((prevState) => ({
      hiddenTeams: prevState.hiddenTeams.filter((id) => id !== teamId),
      clearHiddenTeamsDialog: false,
    }));
    localStorage.setItem(
      'hiddenShelves' + this.context.loggedUser?.id,
      JSON.stringify(this.state.hiddenTeams.filter((id) => id !== teamId)),
    );
  };

  hideShelf = (teamId: string) => {
    const { teams } = this.state;
    const team = teams.find((team) => team.id === teamId);

    if (!team) return;

    try {
      const hiddenTeamsKey = 'hiddenShelves' + this.context.loggedUser?.id;
      const hiddenTeams = JSON.parse(
        localStorage.getItem(hiddenTeamsKey) || '[]',
      );
      const updatedHiddenTeams = hiddenTeams.includes(teamId)
        ? hiddenTeams.filter((id: string) => id !== teamId)
        : [...hiddenTeams, teamId];
      localStorage.setItem(hiddenTeamsKey, JSON.stringify(updatedHiddenTeams));
      this.setState({
        hiddenTeams: updatedHiddenTeams,
      });
    } catch (err) {
      console.debug(err);
    }
  };

  toggleArchiveView = async (teamId: string | null) => {
    if (teamId === null) {
      // Handle personal archive
      this.setState(
        (prevState) => ({
          showArchive: {
            ...prevState.showArchive,
            personal: !prevState.showArchive.personal,
          },
        }),
        async () => {
          if (this.state.showArchive.personal) {
            await this.fetchBoardsForContext(null, true); // Fetch archived personal boards
          } else {
            await this.fetchBoardsForContext(null); // Fetch non-archived personal boards
          }
        },
      );
    } else {
      // Handle team-specific archive
      this.setState(
        (prevState) => ({
          showArchive: {
            ...prevState.showArchive,
            teams: {
              ...prevState.showArchive.teams,
              [teamId]: !prevState.showArchive.teams[teamId],
            },
          },
        }),
        async () => {
          if (this.state.showArchive.teams[teamId]) {
            await this.fetchBoardsForContext(teamId, true); // Fetch archived team boards
          } else {
            await this.fetchBoardsForContext(teamId); // Fetch non-archived team boards
          }
        },
      );
    }
  };

  renderWelcomeDialog = () => {
    const { teams, status, boards } = this.state;

    const allBoards = boards.allBoards || [];

    if (
      teams.length !== 0 &&
      status.personal !== 'loading' &&
      teams.every(
        (team) =>
          allBoards.filter((board: BoardItemDTO) => board.teamId === team.id)
            .length === 0,
      )
    ) {
      return <WelcomeDialog noBoards={true} />;
    }
    return null;
  };

  render() {
    const { t } = this.props;
    const {
      boards,
      teams,
      status,
      serverErrors,
      quickAccessBoards,
      showArchive,
      loadingMore,
      nextPage,
      hiddenTeams,
      statusQuickAccess,
    } = this.state;

    const filteredPersonalBoards = boards.personal || [];

    const visibleTeams = teams.filter(
      (team) => !hiddenTeams?.includes(team.id),
    );

    return (
      <>
        <Toolbar>
          {!this.props.lockedAccount && (
            <div className="form-group">
              <ContextMenu
                dept={0}
                triggerContent={
                  <>
                    <span>{t('new')}</span>
                    <span className="fal fa-chevron-circle-down icon"></span>
                  </>
                }
                triggerClassDefault="secondary-button"
                triggerClassActive="secondary-button"
              >
                <NewMenu />
              </ContextMenu>
            </div>
          )}

          <div className="form-group visible-mobile">
            <ContextMenu
              dept={0}
              triggerContent={
                <>
                  <span className="sr-only">{t('quickAccess')}</span>

                  <span className="fas fa-star pr-xs"></span>
                  <span className="fas fa-chevron-down text-2xs"></span>
                </>
              }
              triggerClassDefault="ghost-button"
              triggerClassActive="secondary-button"
            >
              <QuickAccessSection
                statusQuickAccess={statusQuickAccess}
                quickAccessBoards={quickAccessBoards}
              />
            </ContextMenu>
          </div>
        </Toolbar>

        {this.props.lockedAccount ? (
          <DeletedAccount
            deleteAt={this.props.deleteAt}
            setLockedAccount={this.props.setLockedAccount}
            unlockApplication={this.props.unlockApplication}
            removeServerErrors={this.removeServerErrors}
          />
        ) : (
          <>
            {this.renderWelcomeDialog()}

            <div className="homepage-structure flex-row fill">
              <div className="column py-0">
                <div className="flex-row spread">
                  <div
                    className="column small pb-0"
                    style={{
                      height: '80px',
                    }}
                  >
                    <div
                      className="flex-row squeeze no-reflow"
                      style={{
                        position: 'fixed',
                        maxWidth: '320px',
                      }}
                    >
                      <div className="column pb-0">
                        <NavLink
                          to="/account/user-account/avatar"
                          className="image-link"
                        >
                          <Thumbnail
                            classes="thumbnail size-64"
                            avatarData={this.context.loggedUser!.avatar}
                            title={this.context.loggedUser!.name}
                          />
                        </NavLink>
                      </div>
                      <div className="column pb-0 small flex-v-center">
                        <div>
                          <small
                            className=" text-ellipsis"
                            style={{ maxWidth: '220px' }}
                          >
                            {t('greeting', {
                              name: this.context.loggedUser!.name,
                            })}
                          </small>
                          <br />
                          <small className="faint-text">
                            {t('welcomeMessage')}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-row">
                  <div className="column small quick-access hidden-mobile">
                    {/* Quick Access Section */}
                    <QuickAccessSection
                      statusQuickAccess={statusQuickAccess}
                      quickAccessBoards={quickAccessBoards}
                    />
                  </div>
                  <div className="column largest boards">
                    <div className="inner-boards">
                      <div className="flex-row fill">
                        <div className="column py-0">
                          <div className="flex-row fill">
                            <div className="column pr-2xs">
                              <ul className="control-list-component flex-h-end">
                                <li>
                                  <PWAInstall />
                                </li>
                                {hiddenTeams.length > 0 && (
                                  <li>
                                    <ShelfManager
                                      hiddenTeams={hiddenTeams}
                                      teams={teams}
                                      clearHiddenTeams={this.clearHiddenTeams}
                                      hideShelf={this.hideShelf}
                                      t={t}
                                    />
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Create Board Button */}
                      {/* Personal Boards Section */}
                      <PrivateSpaceShelf
                        boards={filteredPersonalBoards}
                        status={status.personal}
                        showArchive={showArchive.personal}
                        serverErrors={serverErrors}
                        toggleArchiveView={() => this.toggleArchiveView(null)}
                        callback={() => {
                          this.fetchQuickAccess();
                        }}
                      />

                      {visibleTeams.map((team) => (
                        <TeamSpaceShelf
                          key={team.id}
                          team={team}
                          boards={boards[team.id] || []}
                          showArchive={showArchive.teams[team.id]}
                          serverErrors={serverErrors}
                          hideShelf={this.hideShelf}
                          toggleArchiveView={() =>
                            this.toggleArchiveView(team.id)
                          }
                          status={status.teams[team.id]}
                          callback={() => {
                            this.fetchQuickAccess();
                          }}
                        />
                      ))}
                      {loadingMore && nextPage && (
                        <div className="loader"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withStyledTranslation('home')(Home);
