import React, { Component } from 'react';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import Button from '../../controls/Button';
import DirectionalButton from '../../controls/DirectionalButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  setDeletedColumn: () => void;
  totalCards: number;
}

class ColumnDelete extends Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                text: this.props.directionalButton.text,
                direction: this.props.directionalButton.direction,
                onClick: this.props.directionalButton.onClick,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        <li>
          <hr />
        </li>
        <li>
          <p
            className="text-sm"
            style={{
              maxWidth: '200px',
              whiteSpace: 'normal',
            }}
          >
            {t('bulkOperationDeleteColumn')}
          </p>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <Button
            className="secondary-button fill pb-xs text-left"
            onClick={() => this.props.setDeletedColumn()}
          >
            <span className="negative-text icon">
              <span className="fas fa-exclamation-circle"></span>
            </span>
            <span
              className="text"
              style={{ textAlign: 'left' }}
            >
              <span>{t('deleteColumn')}</span>
              <br />
              <span className="faint-text">
                {t('archiveAllCards', { count: this.props.totalCards })}
              </span>
            </span>
          </Button>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('columnDelete')(ColumnDelete);
