// Manages the add / edit description button and the description editor in a card flyout.
import React, { useState, useEffect } from 'react';
import Button from '../../../../controls/Button';
import { unite } from '../../../../../common/helpers/unite';
import { withStyledTranslation } from '../../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import { LexicalEditor } from 'lexical';
import EditorViewer from '../../../../controls/EditorControl/EditorViewer';
import EditorControl from '../../../../controls/EditorControl/EditorControl';
import { AttachmentDTO } from '../../../../../common/api/dtos/Card';

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];
  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface Props extends WithTranslation {
  disabled?: boolean;
  boardCard: BoardCard;
  newCard: boolean;
  onDescriptionChange: (value: string) => void;
  descriptionEditor: LexicalEditor | null;
  setDescriptionEditor: (editor: LexicalEditor | null) => void;
  cardAttachments: AttachmentDTO[];
}

const CardDescriptionEditor: React.FC<Props> = (props) => {
  // Destructure props
  const {
    t,
    boardCard,
    disabled,
    newCard,
    onDescriptionChange,
    cardAttachments,
  } = props;

  // Replace state with hooks
  const [showLexical, setShowLexical] = useState(false);
  const [form, setForm] = useState({
    description: boardCard.description ?? null,
  });
  // Add state for resetTrigger to force EditorControl to reinitialize
  const [resetTrigger, setResetTrigger] = useState(0);

  // Mimic componentDidUpdate for newCard prop
  useEffect(() => {
    if (newCard) {
      setShowLexical(false);
    }
  }, [newCard]);

  const handleSetDescription = (value: string | null) => {
    setForm({ description: value ?? '' });
  };

  return (
    <div>
      <div className="flex-row fill">
        <div className="column pb-sm">
          {boardCard.description.length === 0 && !showLexical && (
            <div
              className={unite(
                'lexical-button-trigger',
                `add-description-${boardCard.id}`,
                { hidden: showLexical },
              )}
              style={{ display: showLexical ? 'none' : 'block' }}
            >
              {!disabled ? (
                <Button
                  className="secondary-button fill text-left flex-h-start py-sm px-sm"
                  disabled={disabled}
                  onClick={() => {
                    setShowLexical(true);
                  }}
                >
                  <span className="icon fal fa-text pr-xs"></span>
                  <span className="faint-text  text">
                    {t('typeDescription')}
                  </span>
                </Button>
              ) : (
                <div className="card opaque">
                  <span className="text-xs faint-text">
                    {t('noDescription')}
                  </span>
                </div>
              )}
            </div>
          )}
          {boardCard.description.length > 0 && !showLexical && (
            <div>
              <div className="pb-xs">
                <EditorViewer
                  editorState={boardCard.description} // use boardCard.description directly
                  namespace={`description-viewer-${boardCard.id}`}
                  resetTrigger={resetTrigger} // pass resetTrigger
                />
                {!disabled && (
                  <Button
                    className="secondary-button lexical-button-trigger"
                    styles={{
                      position: 'sticky',
                      zIndex: 0,
                      bottom: '8px',
                      backdropFilter: 'blur(8px)',
                    }}
                    onClick={() => {
                      setShowLexical(true);
                    }}
                  >
                    <span className="text text-left">{t('edit')}</span>
                  </Button>
                )}
              </div>
            </div>
          )}
          {showLexical && (
            <>
              <EditorControl
                onChange={handleSetDescription}
                namespace={`description-editor-${boardCard.id}`}
                initialEditorState={boardCard.description ?? null}
                cardAttachments={cardAttachments}
                resetTrigger={resetTrigger} // pass resetTrigger
                allowEmpty={true}
                confirmText={
                  boardCard.description
                    ? t('updateDescription')
                    : t('addDescription')
                }
                cancelText={t('cancel')}
                onSave={() => {
                  onDescriptionChange(form.description ?? '');
                  setShowLexical(false);
                }}
                onCancel={() => {
                  setForm({ description: boardCard.description });
                  setResetTrigger((prev) => prev + 1); // trigger reset in EditorControl
                  setShowLexical(false);
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyledTranslation('addCardDescription')(
  CardDescriptionEditor,
);
