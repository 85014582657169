import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
} from 'react';
import {
  IColumn,
  UpdateColumnData,
} from '../../../../common/interfaces/BoardContext';
import {
  deleteColumn,
  updateColumn,
} from '../../../../common/api/endpoints/column';
import {
  showErrorNotifications,
  showSuccessNotifications,
} from '../../../../common/helpers/showNotifications';
import TextBox from '../../../controls/TextBox';
import Button from '../../../controls/Button';
import ColumnContext from '../../../menus/Column/ColumnContext';
import BoardContext from '../../../../common/contexts/BoardContext';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import { NotificationMessage } from '../../../../common/contexts/NotificationsContext';
import { handleArchiveAllCards } from './BoardColumnExpanded/partials/Helpers';
import { unite } from '../../../../common/helpers/unite';

export interface BoardColumnHeaderProps extends WithTranslation {
  boardColumn: IColumn;
  className?: string;
  columnCardLimitState: 'disabled' | 'enabled' | 'enforced';
  isCollapsed: boolean;
  setUpdatedColumn?: (id: string, data: UpdateColumnData) => void;
  setDeletedColumn?: (id: string) => void;
  handleCollapseColumn: () => void;
  handleExpandColumn: () => void;
  setMessages?: (messages: NotificationMessage | NotificationMessage[]) => void;
  setAllArchivedCards?: (id: string, archivedIds: string[]) => void;
}

export interface FormData {
  columnTitle: string;
  cardLimit: number | null | undefined;
}

const BoardColumnHeader: React.FC<BoardColumnHeaderProps> = (props) => {
  const boardCTX = useContext(BoardContext);
  const {
    boardColumn,
    columnCardLimitState,
    setUpdatedColumn,
    setDeletedColumn,
    handleCollapseColumn,
    handleExpandColumn,
    setMessages,
    isCollapsed = false,
    t,
  } = props;
  const [showEditColumn, setShowEditColumn] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    columnTitle: boardColumn.title,
    cardLimit: boardColumn.cardLimit,
  });
  const textBoxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showEditColumn) textBoxRef.current?.focus();
  }, [showEditColumn]);

  const toggleShowEditColumn = () => setShowEditColumn((prev) => !prev);

  const updateForm = <K extends keyof FormData>(field: K, value: FormData[K]) =>
    setFormData((prev) => ({ ...prev, [field]: value }));

  const setColumnTitle = (ev: ChangeEvent<HTMLInputElement>) =>
    updateForm('columnTitle', ev.target.value);

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      toggleShowEditColumn();
      setFormData({
        columnTitle: boardColumn.title,
        cardLimit: boardColumn.cardLimit || 0,
      });
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    toggleShowEditColumn();
    if (
      formData.columnTitle === boardColumn.title ||
      formData.columnTitle === ''
    )
      return;
    try {
      const data = { title: formData.columnTitle };
      const column = await updateColumn(boardColumn.id, data);
      setUpdatedColumn && setUpdatedColumn(column.id, { title: column.title });
    } catch (err) {
      showErrorNotifications(err, setMessages || (() => false));
    }
  };

  const renderColumnCount = () => {
    const { cardLimit } = formData;
    const totalCards = boardColumn.cards.length;
    const visibleCards = boardColumn.visibleCards.length;
    const hasFilter = !!boardCTX.board.cardFilter.value;
    const hasLimit = columnCardLimitState !== 'disabled' && cardLimit;
    return (
      <sup className="pe-none no-wrap text-sm">
        <small>
          {hasFilter ? (
            <span>
              {visibleCards}
              {totalCards > 0 && (
                <span>
                  {' ('}
                  {totalCards}
                  {hasLimit && `/${cardLimit}`}
                  {')'}
                </span>
              )}
            </span>
          ) : hasLimit ? (
            <span>
              {totalCards}/{cardLimit}
            </span>
          ) : (
            <span>{totalCards}</span>
          )}
        </small>
      </sup>
    );
  };

  const handleDelete = async () => {
    try {
      const result = await deleteColumn(boardColumn.id);
      setDeletedColumn && setDeletedColumn(boardColumn.id);
      const notifications =
        result.cards.length === 1
          ? [t('deletedColumnSingular', { columnTitle: boardColumn.title })]
          : [
              t('deletedColumn', {
                columnTitle: boardColumn.title,
                cardCount: result.cards.length,
              }),
            ];
      showSuccessNotifications(notifications, setMessages || (() => false));
    } catch (err) {
      showErrorNotifications(err, setMessages || (() => false));
    }
  };

  const setLimit = (value: number) => {
    setFormData({
      cardLimit: value,
      columnTitle: boardColumn.title,
    });
  };

  const handleSubmitLimit = async (value: number) => {
    try {
      const data = { cardLimit: value === 0 ? null : value };
      const response = await updateColumn(boardColumn.id, data);
      boardCTX.reloadBoard();
      setFormData((prev) => ({
        ...prev,
        cardLimit: response.cardLimit || null,
      }));
    } catch (err) {
      console.debug(err);
    }
  };

  const onSetLimit = (value: number) => {
    handleSubmitLimit(value);
  };

  return (
    <div className="flex-row list-drag-head no-reflow">
      {isCollapsed ? (
        <div
          className={['column', 'card-board-list', 'collapsed-list'].join(' ')}
        >
          <div className={['list-drag-helper list-drag-head'].join(' ')}>
            <div
              className="column pt-2xs large"
              style={{ paddingLeft: '1px' }}
            >
              <div
                className={unite({
                  'collapsed-handle': true,
                })}
              >
                <div
                  className={unite({
                    'flex-v-center': true,
                    'static-border-danger': Boolean(
                      columnCardLimitState !== 'disabled' &&
                        boardColumn.cardLimit &&
                        boardColumn.cardLimit < boardColumn.cards.length,
                    ),
                  })}
                >
                  <small className="fas fa-grip-lines icon"></small>
                  <Button
                    className={'ghost-button'}
                    title={t('expandColumn', {
                      columnTitle: boardColumn.title,
                    })}
                    onClick={handleExpandColumn}
                  >
                    <span className="pe-none card-board-list-title">
                      {boardColumn.title}
                    </span>
                  </Button>
                </div>
                &nbsp;&nbsp;
                <span className="inline-block mt-xs">
                  {renderColumnCount()}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="column pb-0 large">
            {showEditColumn ? (
              <form
                onSubmit={handleSubmit}
                onBlur={handleSubmit}
                className="fill"
              >
                <TextBox
                  id="columnTitle"
                  name="columnTitle"
                  label="Column Title"
                  srOnly={true}
                  type="text"
                  value={formData.columnTitle}
                  onChange={setColumnTitle}
                  onKeyDown={handleInputKeyDown}
                  className="button-padding fill"
                  placeholder={t('enterColumnTitle')}
                  formGroupClassNames="mb-0"
                  ref={textBoxRef}
                />
              </form>
            ) : boardCTX.board.user.role !== 'owner' &&
              boardCTX.board.user.role !== 'admin' ? (
              <div
                className="ghost-button soft-disabled"
                title={boardColumn.title}
              >
                <span className="pe-none">{boardColumn.title}</span>
                &nbsp;
                {renderColumnCount()}
              </div>
            ) : (
              <div className="no-wrap">
                <Button
                  className="ghost-button"
                  title={boardColumn.title}
                  onClick={toggleShowEditColumn}
                >
                  <span
                    className="pe-none"
                    style={{
                      maxWidth: '200px',
                      overflow: 'hidden',
                      lineHeight: '1.5',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {boardColumn.title}
                  </span>
                </Button>{' '}
                <span className="inline-block mt-xs">
                  {renderColumnCount()}
                </span>
              </div>
            )}
          </div>
          <div className="column pb-0 flex-h-end">
            <ColumnContext
              triggerClassDefault="ghost-button"
              triggerClassActive="secondary-button"
              contextMenuClassName="align-h-start"
              setDeletedColumn={handleDelete}
              onArchiveAllCards={() => handleArchiveAllCards(props)}
              onSetLimit={onSetLimit}
              setLimit={setLimit}
              limit={formData.cardLimit || 0}
              handleCollapseColumn={handleCollapseColumn}
              totalCards={boardColumn.cards.length}
              columnCardLimitState={columnCardLimitState}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withStyledTranslation('boardColumnExpanded')(BoardColumnHeader);
