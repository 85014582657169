import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { debounce } from 'lodash';

interface Props {
  name: string;
  value: string;
  label?: string;
  id?: string;
  onChange?: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
  fluidWidth?: boolean;
  disabled?: boolean;
  rows?: number;
  cols?: number;
  onBlur?: (ev: React.FocusEvent<HTMLTextAreaElement>) => void;
  error?: string;
  required?: boolean;
  placeholder?: string;
  br?: boolean;
  noMargin?: boolean;
  autofocus?: boolean;
  className?: string;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  autoHeight?: boolean;
  maxLength?: number;
  showCounter?: boolean;
  onTextAreaHeightChange?: (height: number | undefined) => void;
  noResize?: boolean;
  spellCheck?: boolean;
}

const TextArea: React.FC<Props> = (props) => {
  const [textAreaHeight, setTextAreaHeight] = useState<number | undefined>(
    undefined,
  );
  const [focused, setFocused] = useState(false);
  const controlRef = useRef<HTMLTextAreaElement>(null);

  const updateTextAreaHeight = useCallback(() => {
    if (controlRef.current) {
      controlRef.current.style.height = 'auto';
      const scrollHeight = controlRef.current.scrollHeight;
      const newHeight = scrollHeight + 3; // Adding a small offset
      controlRef.current.style.height = newHeight + 'px';
      setTextAreaHeight(newHeight);
      if (props.onTextAreaHeightChange) {
        props.onTextAreaHeightChange(newHeight);
      }
    }
  }, [props.onTextAreaHeightChange]);

  const debouncedHandleInput = useMemo(
    () =>
      debounce(() => {
        if (props.autoHeight) {
          updateTextAreaHeight();
        }
      }, 100),
    [props.autoHeight, updateTextAreaHeight],
  );

  useEffect(() => {
    if (props.autoHeight) {
      updateTextAreaHeight();
      const handleResize = () => updateTextAreaHeight();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [props.autoHeight, updateTextAreaHeight]);

  useEffect(() => {
    if (props.autoHeight) {
      updateTextAreaHeight();
    }
  }, [props.value, props.autoHeight, updateTextAreaHeight]);

  useEffect(() => {
    return () => {
      debouncedHandleInput.cancel();
    };
  }, [debouncedHandleInput]);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(false);
      if (props.onBlur) {
        props.onBlur(e);
      }
    },
    [props.onBlur],
  );

  return (
    <div
      className={`form-group textarea-wrapper ${props.noMargin ? 'mb-0' : ''}`}
    >
      {(props.id || props.label || props.required) && (
        <label htmlFor={props.id}>
          {props.label}
          {props.required && '*'}
        </label>
      )}
      {props.br && <br />}
      <textarea
        ref={controlRef}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onInput={props.autoHeight ? debouncedHandleInput : undefined}
        disabled={props.disabled}
        rows={props.autoHeight ? 1 : props.rows}
        placeholder={props.placeholder}
        cols={props.cols}
        required={props.required}
        onBlur={onBlur}
        onFocus={onFocus}
        className={`${props.fluidWidth ? 'fluid-width' : ''} ${props.className ? props.className : ''} ${
          props.noResize ? 'no-resize' : ''
        }`}
        aria-invalid={!!props.error}
        autoFocus={props.autofocus}
        onKeyDown={props.onKeyDown}
        style={{
          maxHeight: props.autoHeight ? 'none' : undefined,
          resize: props.autoHeight ? 'none' : undefined,
          height: props.autoHeight ? textAreaHeight + 'px' : undefined,
        }}
        maxLength={props.maxLength ? props.maxLength : 255}
        spellCheck={props.spellCheck}
      ></textarea>
      {props.showCounter && focused && (
        <span className="textarea-counter flag-text">
          {props.value.length}/{props.maxLength ? props.maxLength : 255}
        </span>
      )}
      {props.error ? (
        <ul className="error-list light">
          <li>{props.error}</li>
        </ul>
      ) : (
        ''
      )}
    </div>
  );
};

export default TextArea;
