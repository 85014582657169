import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';
import { $wrapNodes } from '@lexical/selection';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import ContextMenu from '../../ContextMenu';
import { unite } from '../../../../common/helpers/unite';

export type BlockType = 'paragraph' | 'quote' | 'h1' | 'h2' | 'ul' | 'ol';

export const supportedBlockTypes = new Set([
  'paragraph',
  'quote',
  'h1',
  'h2',
  'ul',
  'ol',
]);

export const blockTypeToBlockName = {
  paragraph: 'Normal',
  h1: 'Large Heading',
  h2: 'Small Heading',
  ol: 'Numbered List',
  ul: 'Bulleted List',
  quote: 'Quote',
};

const blockTypeToIcon = {
  paragraph: 'fa-text-size',
  h1: 'fa-h1',
  h2: 'fa-h2',
  ol: 'fa-list-ol',
  ul: 'fa-list',
  quote: 'fa-quote-right',
};

export default function BlockMenuOptions({
  editor,
  blockType,
  setBlockType,
  formatMenuRef,
}: {
  editor: LexicalEditor;
  blockType: string;
  toolbarRef: React.RefObject<HTMLDivElement>;
  setBlockType: (val: BlockType) => void;
  formatMenuRef: React.RefObject<ContextMenu>;
}) {
  const formatParagraph = () => {
    formatMenuRef.current?.close();
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    formatMenuRef.current?.close();
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
    }
  };

  const formatSmallHeading = () => {
    formatMenuRef.current?.close();
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    }
  };

  const formatList = (listType: 'number' | 'bullet') => {
    formatMenuRef.current?.close();
    if (listType === 'number' && blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType('ol');
    } else if (listType === 'bullet' && blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('ul');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    }
  };

  const formatQuote = () => {
    formatMenuRef.current?.close();
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const blockTypeToCommand = {
    paragraph: formatParagraph,
    h1: formatLargeHeading,
    h2: formatSmallHeading,
    ul: () => formatList('bullet'),
    ol: () => formatList('number'),
    quote: formatQuote,
  };

  return (
    <>
      {Object.entries(blockTypeToBlockName).map(([type, name]) => (
        <li key={type}>
          <button
            className={unite({
              'ghost-button': true,
              active: blockType === type,
            })}
            onClick={
              blockTypeToCommand[type as keyof typeof blockTypeToCommand]
            }
          >
            <span
              className={`icon fas ${blockTypeToIcon[type as keyof typeof blockTypeToIcon] || 'fa-file'}`}
            />
            <span className="text">{name}</span>
            {blockType === type && <span className="active" />}
          </button>
        </li>
      ))}
    </>
  );
}
