import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import CONDUIT_BRIDGE from '../EditorControl/themes/conduit';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import ToolbarPlugin from '../EditorControl/plugins/ToolbarPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';

import { AttachmentDTO } from '../../../common/api/dtos/Card';
import { AttachmentNode as AttachmentNode } from './plugins/nodes/AttachmentNode';
import Button from '../Button';
import { unite } from '../../../common/helpers/unite';
import ToolbarIndentationPlugin from './plugins/ToolbarIndentationPlugin'; // <-- Added
import { SmartLinkifyPlugin } from './plugins/SmartLinkifyPlugin';
import { MentionNode } from './plugins/nodes/MentionNode';

const theme = CONDUIT_BRIDGE;

interface EditorControlProps {
  onChange: (value: string | null) => void;
  initialEditorState?: string | null;
  namespace?: string;
  cardAttachments: AttachmentDTO[];
  altStyle?: boolean;
  resetTrigger?: any;
  confirmText?: string;
  cancelText?: string;
  onSave?: () => void;
  onCancel?: () => void;
  allowEmpty?: boolean;
}

function onError(error: unknown) {
  console.error(error);
}

function ContentChangedPlugin({
  onChange,
  initialEditorState,
  setIsUnchanged,
}: {
  onChange: (editorState: EditorState) => void;
  initialEditorState?: string | null;
  setIsUnchanged: (unchanged: boolean) => void;
}) {
  const [editor] = useLexicalComposerContext();

  // Memoize the parsed initial state to avoid repetitive parsing
  const parsedInitial = useMemo(() => {
    if (initialEditorState) {
      try {
        return editor.parseEditorState(JSON.parse(initialEditorState));
      } catch (error) {
        console.error('Parsing initial editor state error:', error);
      }
    }
    return null;
  }, [editor, initialEditorState]);

  useEffect(() => {
    const unregister = editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState);

      if (!parsedInitial) {
        setIsUnchanged(false);
        return;
      }

      try {
        editorState.read(() => {
          const currentJSON = editorState.toJSON();
          const initialJSON = parsedInitial.toJSON();
          setIsUnchanged(
            JSON.stringify(currentJSON) === JSON.stringify(initialJSON),
          );
        });
      } catch (e) {
        console.error('Comparison failed in ContentChangedPlugin:', e);
      }
    });
    return unregister;
  }, [editor, onChange, parsedInitial, setIsUnchanged]);

  return null;
}

export default function EditorControl({
  onChange,
  initialEditorState,
  namespace,
  cardAttachments,
  altStyle,
  resetTrigger,
  confirmText,
  cancelText,
  onSave,
  onCancel,
  allowEmpty = false,
}: EditorControlProps) {
  const [isUnchanged, setIsUnchanged] = useState(true); // safe default

  const initialConfig = useMemo(
    () => ({
      namespace: namespace ?? 'EditorControl',
      theme,
      onError,
      editorState: initialEditorState
        ? (editor: LexicalEditor) =>
            editor.parseEditorState(JSON.parse(initialEditorState))
        : undefined,
      editable: true,
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        LinkNode,
        MentionNode,
        AttachmentNode,
      ],
      transformers: TRANSFORMERS,
    }),
    [initialEditorState, namespace],
  );

  const handleChange = useCallback(
    (editorState: EditorState | null) => {
      if (editorState == null) {
        onChange(null);
        setIsUnchanged(true);
      } else {
        onChange(JSON.stringify(editorState.toJSON()));
      }
    },
    [onChange, setIsUnchanged],
  );

  return (
    <div className="flex-row fill">
      <div className="column pt-0 pb-2xs">
        <LexicalComposer initialConfig={initialConfig}>
          <EditorContent
            editorState={initialEditorState}
            initialEditorState={initialEditorState}
            handleChange={handleChange}
            cardAttachments={cardAttachments}
            altStyle={altStyle}
            resetTrigger={resetTrigger}
            onSave={onSave}
            onCancel={onCancel}
            confirmText={confirmText}
            cancelText={cancelText}
            setIsUnchanged={setIsUnchanged}
            isUnchanged={isUnchanged}
            allowEmpty={allowEmpty}
          />
        </LexicalComposer>
      </div>
    </div>
  );
}

// Wrap EditorContent with React.memo to prevent unnecessary re-renders
export const EditorContent = React.memo(function EditorContent({
  editorState,
  initialEditorState,
  handleChange,
  cardAttachments,
  altStyle,
  resetTrigger,
  confirmText = 'Save',
  cancelText = 'Cancel',
  onSave,
  onCancel,
  setIsUnchanged,
  isUnchanged,
  allowEmpty = false,
}: {
  editorState?: string | null;
  initialEditorState?: string | null;
  handleChange: (editorState: EditorState | null) => void;
  cardAttachments: AttachmentDTO[];
  altStyle?: boolean;
  resetTrigger?: any;
  confirmText?: string;
  cancelText?: string;
  onSave?: () => void;
  onCancel?: () => void;
  setIsUnchanged: (unchanged: boolean) => void;
  isUnchanged: boolean;
  allowEmpty?: boolean;
}) {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);

  useEffect(() => {
    if (editorState && editor) {
      setTimeout(() => {
        try {
          editor.update(() => {
            const parsedState = editor.parseEditorState(
              JSON.parse(editorState),
            );
            editor.setEditorState(parsedState);
          });
        } catch (error) {
          console.error('Error restoring editor state:', error);
        }
      }, 0);
    }
  }, [editorState, editor, resetTrigger]);

  // Memoize the parsed initial state to minimize repetitive parsing
  const parsedInitial = useMemo(() => {
    if (initialEditorState) {
      try {
        return editor.parseEditorState(JSON.parse(initialEditorState));
      } catch (err) {
        console.error(
          'Error parsing initial editor state in EditorContent:',
          err,
        );
      }
    }
    return null;
  }, [editor, initialEditorState]);

  useEffect(() => {
    if (editorState && parsedInitial && editor) {
      try {
        const parsedCurrent = editor.parseEditorState(JSON.parse(editorState));
        parsedCurrent.read(() => {
          const currentText = $getRoot().getTextContent();
          parsedInitial.read(() => {
            const initialText = $getRoot().getTextContent();
            setIsUnchanged(currentText === initialText);
          });
        });
      } catch (error) {
        console.error('Error comparing editor states:', error);
      }
    }
  }, [editorState, parsedInitial, editor, setIsUnchanged]);

  return (
    <div className="lexical-control">
      <div className="editor-inner">
        <ToolbarPlugin
          cardAttachments={cardAttachments}
          altStyle={altStyle}
        />
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              style={{
                marginTop: '-10px',
                marginBottom: '-10px',
                paddingTop: '30px',
                paddingBottom: '30px',
                minHeight: '100px',
                boxShadow: 'none',
              }}
              className="card editor-input opaque"
              spellCheck={true}
              disabled={false}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <div
          className={unite({
            'bottom-toolbar': true,
            'alt-style': altStyle,
          })}
          style={{
            position: 'sticky',
            zIndex: 2,
            bottom: '9px',
          }}
        >
          <div className="card opaque py-xs px-0">
            <Button
              className="primary-button mx-xs"
              disabled={(!allowEmpty && isEmpty) || isUnchanged}
              onClick={onSave ?? (() => false)}
            >
              <span className="text">{confirmText}</span>
            </Button>
            <Button
              className="secondary-button mr-xs"
              onClick={onCancel}
            >
              <span className="text">{cancelText}</span>
            </Button>
          </div>
        </div>
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <HistoryPlugin />
        <ToolbarIndentationPlugin />

        <ListPlugin />
        <LinkPlugin />
        <SmartLinkifyPlugin />
        <CheckListPlugin />
        <ContentChangedPlugin
          onChange={(editorState: EditorState) => {
            editorState.read(() => {
              const text = $getRoot().getTextContent().trim();
              handleChange(text === '' ? null : editorState);
            });
          }}
          initialEditorState={initialEditorState}
          setIsUnchanged={setIsUnchanged}
        />
      </div>
    </div>
  );
});
