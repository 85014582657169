import { $getSelection, $isRangeSelection } from 'lexical';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';
import { iconForAttachment } from './helpers/iconForAttachment';
import { $createAttachmentBlockNode } from './nodes/AttachmentNode';
import ContextMenu from '../../ContextMenu';
import Button from '../../Button';

type ToolbarAttachmentPluginProps = {
  editor: any;
  cardAttachments: AttachmentDTO[];
  attachmentMenuRef: React.RefObject<ContextMenu>;
};

export default function ToolbarAttachmentPlugin({
  editor,
  cardAttachments,
  attachmentMenuRef,
}: ToolbarAttachmentPluginProps) {
  function handleInsertAttachment(attachmentId: string) {
    try {
      editor.update(() => {
        const attachment = cardAttachments.find(
          (att) => att.id === attachmentId,
        );
        if (!attachment) {
          console.error(`Attachment not found: ${attachmentId}`);
          return;
        }
        attachmentMenuRef.current?.close();
        const attachmentNode = $createAttachmentBlockNode(attachment);
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          selection.insertNodes([attachmentNode]);
        }
      });
    } catch (error) {
      console.error('Error inserting attachment:', error);
    }
  }

  return (
    <ContextMenu
      dept={0}
      ref={attachmentMenuRef}
      contextMenuClassName="align-v-start"
      triggerClassDefault="secondary-button"
      triggerClassActive="secondary-button active-border"
      title="Attachments - ALT+3"
      name="attachmentsMenu"
      triggerContent={
        <>
          <i className="fas fa-paperclip pe-none" />
          <span className="icon fas fa-angle-down"></span>
        </>
      }
    >
      <>
        {cardAttachments.map((attachment) => (
          <li key={attachment.id}>
            <Button
              className="ghost-button"
              onClick={() => handleInsertAttachment(attachment.id)}
              title={attachment.filename}
            >
              <span
                className={`${iconForAttachment(attachment.contentType)} icon pe-none`}
              ></span>
              <span
                className="text text-ellipsis pe-none"
                style={{
                  maxWidth: '200px',
                }}
              >
                {attachment.filename}
              </span>
            </Button>
          </li>
        ))}
        {cardAttachments.length === 0 && (
          <li>
            <p className="text-sm">This card has no attachments</p>
          </li>
        )}
      </>
    </ContextMenu>
  );
}
