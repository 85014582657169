const CONDUIT_BRIDGE = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  paragraph: 'editor-paragraph',
  quote: 'text-quote',
  heading: {
    h1: 'h1 text-500 text-2xl',
    h2: 'h2 text-500 text-xl',
  },
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
    listitem: 'editor-listitem',
  },
  link: 'link-button',
  text: {
    bold: 'text-700',
    italic: 'text-italic',
    overflowed: 'editor-text-overflowed',
    hashtag: 'editor-text-hashtag',
    underline: 'text-underline',
    strikethrough: 'text-line-through',
    underlineStrikethrough: 'text-underline text-line-through',
    code: 'code-inline',
  },
  code: 'code-block',
};

export default CONDUIT_BRIDGE;
