// Manages the add comment button and the comment editor in a card flyout.
import React, { useState, useEffect, useContext } from 'react';
import { createComment } from '../../../../../common/api/endpoints/comment';
import { CommentDTO } from '../../../../../common/api/dtos/Comment';
import Button from '../../../../controls/Button';
import { useTranslation } from 'react-i18next';
import EditorControl from '../../../../controls/EditorControl/EditorControl';
import AppContext from '../../../../../common/contexts/AppContext';
import { AttachmentDTO } from '../../../../../common/api/dtos/Card';

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];
  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface Props {
  disabled: boolean;
  boardCard: BoardCard;
  addComment: (commentData: CommentDTO) => void;
  handleAddComment?: () => void;
  newCard: boolean;
  cardAttachments: AttachmentDTO[];
}

const CardCommentEditor: React.FC<Props> = ({
  boardCard,
  addComment,
  handleAddComment,
  newCard,
  cardAttachments,
}) => {
  const { t } = useTranslation('addCardComment');
  const appContext = useContext(AppContext);

  const [comment, setComment] = useState<string | null>(null);
  const [showLexical, setShowLexical] = useState<boolean>(false);

  useEffect(() => {
    setComment(null);
    setShowLexical(false);
  }, [newCard]);

  const createCommentHandler = async () => {
    if (comment !== null) {
      try {
        const newComment = await createComment(boardCard.id, comment);
        setComment(null);
        addComment(newComment);
        handleAddComment && handleAddComment();
        setShowLexical(false);
      } catch (err) {
        console.debug(err);
        appContext.notifications.setMessages
          ? appContext.notifications.setMessages(err as any)
          : () => {
              false;
            };
      }
    }
  };

  const toggleShowLexical = (value: boolean) => {
    setShowLexical(value);
  };

  return (
    <>
      <div>
        {showLexical ? (
          <div>
            <EditorControl
              namespace={`comment-editor-${boardCard.id}`}
              onChange={(value: string | null) => setComment(value)}
              cardAttachments={cardAttachments}
              confirmText={t('addComment')}
              cancelText={t('cancel')}
              onSave={createCommentHandler}
              onCancel={() => toggleShowLexical(false)}
              altStyle={true}
            />
          </div>
        ) : (
          <div>
            <Button
              className="secondary-button fill text-left flex-h-start py-sm px-sm"
              onClick={() => toggleShowLexical(true)}
            >
              <span className="icon fal fa-comment-alt-lines pt-3xs pr-xs"></span>
              <span className="text faint-text">{t('writeComment')}</span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default CardCommentEditor;
