import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import Joi from 'joi';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import TextBox from '../../controls/TextBox';
import RequestStatus from '../../partials/RequestStatus/RequestStatus';
import { processJoiError } from '../../../common/helpers/processJoiError';
import Button from '../../controls/Button';
import { FormErrorMsgs } from '../../../common/configs/FormErrors';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import errorKeyFormatter from '../../../common/helpers/errorKeyFormatter';
import { ErrorNotificationPayload } from '../../../common/helpers/errorNotificationPayload';

interface FormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

type FormErrors = Partial<Record<keyof FormData, string>>;

interface Props extends WithTranslation {
  editUserId?: number;
  enforced?: boolean;
  callback?: () => void;
}

const PasswordForm: FC<Props> = ({ t, enforced, callback }) => {
  const [formData, setFormData] = useState<FormData>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [invalidPasswordMatch, setInvalidPasswordMatch] =
    useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<TRequestStatus>('idle');
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  // Memoize the Joi schema.
  const formSchema = useMemo(() => {
    return Joi.object({
      currentPassword: Joi.string().required().messages(FormErrorMsgs.string),
      newPassword: Joi.string()
        .required()
        .pattern(
          /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~])[A-Za-z\d/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~]{8,}/,
        )
        .invalid(Joi.ref('currentPassword'))
        .messages({
          ...FormErrorMsgs.string,
          'any.invalid':
            'New password cannot be the same as the current password.',
        }),
      confirmPassword: Joi.string()
        .required()
        .valid(Joi.ref('newPassword'))
        .messages(FormErrorMsgs.confirmPassword),
    });
  }, []);

  // Validate password match whenever relevant fields change.
  useEffect(() => {
    setInvalidPasswordMatch(formData.newPassword !== formData.confirmPassword);
  }, [formData.newPassword, formData.confirmPassword]);

  const updateFormError = useCallback(
    <K extends keyof FormData>(field: K, value: string) => {
      setFormErrors((prev) => ({ ...prev, [field]: value }));
    },
    [],
  );

  const validateFormField = useCallback(
    <K extends keyof FormData>(field: K) => {
      const result = formSchema.validate(formData, { abortEarly: false });
      let errorMessage = '';
      if (result.error) {
        for (const error of result.error.details) {
          if (error.path[0] === field) {
            errorMessage = error.message;
            break;
          }
        }
      }
      updateFormError(field, errorMessage);
    },
    [formData, formSchema, updateFormError],
  );

  const validateForm = useCallback(() => {
    setFormErrors({});
    const result = formSchema.validate(formData, { abortEarly: false });
    if (result.error) {
      const errors = processJoiError(result.error) as FormErrors;
      setFormErrors(errors);
      return false;
    }
    return true;
  }, [formData, formSchema]);

  const updateForm = useCallback(
    <K extends keyof FormData>(field: K, value: FormData[K]) => {
      setFormData((prev) => ({ ...prev, [field]: value }));
      setFormStatus('idle');
    },
    [],
  );

  const setCurrentPassword = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      updateForm('currentPassword', ev.target.value);
    },
    [updateForm],
  );

  const setNewPassword = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      updateForm('newPassword', ev.target.value);
    },
    [updateForm],
  );

  const setConfirmPassword = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      updateForm('confirmPassword', ev.target.value);
    },
    [updateForm],
  );

  const updateEntity = useCallback(
    async (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      setFormStatus('loading');
      setServerErrors([]);
      try {
        // Simulate API call success.
        setFormStatus('success');
        setFormData({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
        if (callback) callback();
      } catch (err) {
        const errorMsg = errorKeyFormatter(
          (err as ErrorNotificationPayload[])[0],
        );
        setServerErrors((prev) => [...prev, errorMsg]);
        setFormStatus('error');
      }
    },
    [callback],
  );

  const handleSubmit = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      if (validateForm()) {
        updateEntity(ev);
      }
    },
    [validateForm, updateEntity],
  );

  return (
    <form autoComplete="off">
      <div className="flex-row">
        <div className="column large">
          {enforced && (
            <TextBox
              label={t('currentPasswordLabel')}
              type="password"
              name="currentPassword"
              id="currentPassword"
              value={formData.currentPassword}
              onChange={setCurrentPassword}
              autoComplete="new-password"
              br
              required
              disabled={formStatus === 'loading'}
              onBlur={({ target }) =>
                validateFormField(target.name as keyof FormData)
              }
              error={formErrors.currentPassword}
            />
          )}
          <TextBox
            label={t('newPasswordLabel')}
            type="password"
            name="newPassword"
            id="newPassword"
            value={formData.newPassword}
            onChange={setNewPassword}
            autoComplete="new-password"
            br
            required
            disabled={formStatus === 'loading'}
            onBlur={({ target }) =>
              validateFormField(target.name as keyof FormData)
            }
            error={formErrors.newPassword}
          />
          <TextBox
            label={t('confirmPasswordLabel')}
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={setConfirmPassword}
            autoComplete="new-password"
            br
            required
            disabled={formStatus === 'loading'}
            onBlur={({ target }) =>
              validateFormField(target.name as keyof FormData)
            }
            error={
              formErrors.confirmPassword ||
              (invalidPasswordMatch ? t('passwordsDoNotMatch') : '')
            }
          />
          <Button
            className="primary-button"
            disabled={formStatus === 'loading'}
            onClick={handleSubmit}
          >
            <RequestStatus status={formStatus} />
            <span className="text">{t('updatePasswordButton')}</span>
          </Button>
          {formStatus === 'success' && (
            <div className="mt-sm reveal-up-1">
              <p className="positive-text">
                <small className="fas fa-check-circle"></small>
                <span>{t('passwordUpdateSuccess')}</span>
              </p>
            </div>
          )}
          {serverErrors.length > 0 && (
            <ul className="error-list light">
              {serverErrors.map((err) => (
                <li
                  key={err}
                  className="flex-v-center"
                >
                  <span className="text">{t('app:' + err + '.pass')}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </form>
  );
};

export default withStyledTranslation('passwordForm')(PasswordForm);
