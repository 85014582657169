import React, { Component } from 'react';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;

  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  prompt(): Promise<void>;
}

interface State {
  installPrompt: null | BeforeInstallPromptEvent;
  show: boolean;
}

class PWAInstall extends Component<Record<string, never>, State> {
  installButton: React.RefObject<HTMLButtonElement>;
  constructor(props: Record<string, never>) {
    super(props);
    this.installButton = React.createRef();
    this.state = {
      installPrompt: null,
      show: false,
    };
  }

  componentDidMount(): void {
    window.addEventListener(
      'beforeinstallprompt',
      this.handlePrompt as EventListener,
    );
    window.addEventListener('appinstalled', this.handleAppInstalled);
  }

  handlePrompt = (ev: Event) => {
    const event = ev as BeforeInstallPromptEvent;
    event.preventDefault();
    this.setState({
      installPrompt: event,
      show: true,
    });
  };

  handleInstall = () => {
    if (!this.state.installPrompt) {
      return;
    }

    this.state.installPrompt.prompt();
    this.state.installPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.debug('User accepted the install prompt');
      } else {
        console.debug('User dismissed the install prompt');
      }
      this.setState({
        installPrompt: null,
        show: false,
      });
    });
  };

  handleAppInstalled = () => {
    console.debug('PWA was installed');
    this.setState({ show: false });
  };

  componentWillUnmount(): void {
    window.removeEventListener(
      'beforeinstallprompt',
      this.handlePrompt as EventListener,
    );
    window.removeEventListener('appinstalled', this.handleAppInstalled);
  }

  render() {
    return (
      <>
        {this.state.show && (
          <button
            id="install"
            ref={this.installButton}
            className="secondary-button"
            onClick={this.handleInstall}
          >
            <span className="fas fa-mobile icon accent-text-green"></span>
            <span className="text">Install Borddo App</span>
          </button>
        )}
      </>
    );
  }
}

export default PWAInstall;
