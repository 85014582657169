import React from 'react';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { BoardItemDTO } from '../../../../common/api/dtos/Board';
import QuickAccessDroppable from '../../../partials/QuickAccess/QuickAccessDroppable';
import { useTranslation } from 'react-i18next';
import ContentLoader from '../../../partials/ContentLoader/ContentLoader';
import MessageBar from '../../../controls/MessageBar';

interface QuickAccessSectionProps {
  statusQuickAccess: TRequestStatus;
  quickAccessBoards: BoardItemDTO[] | undefined;
}

const QuickAccessSection: React.FC<QuickAccessSectionProps> = ({
  statusQuickAccess,
  quickAccessBoards,
}) => {
  const { t } = useTranslation('home');

  return (
    <div>
      <div className="flex-row fill flex-v-center flex-h-spread">
        <div className="column pt-xs pb-0">
          {/* <h2 className="primary-title h3 normalcase">
            <img
              alt="starred boards icon"
              className="icon"
              src={`${process.env.REACT_APP_ICONS_PATH}/starred-boards.svg`}
            />{' '}
            <span>{t('quickAccess')}</span>
          </h2> */}
        </div>
      </div>
      <div className="flex-row card-list-component fill">
        {typeof quickAccessBoards === 'undefined' ? (
          <div className="column flex-v-stretch">
            <div className="card">
              <div className="content py-xs">
                <p>
                  <span className="fas fa-exclamation-triangle negative-text"></span>{' '}
                  <span className="faint-text text-sm">
                    {t('quickAccessUnknownError')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : quickAccessBoards.length ? (
          <div className="column px-xs pt-0 pb-0">
            <QuickAccessDroppable elements={quickAccessBoards} />
          </div>
        ) : (
          <div className="column flex-v-stretch">
            <MessageBar
              type="info"
              icon="fas fa-star"
            >
              {t('quickAccessNoBoards')}
            </MessageBar>
          </div>
        )}
        {statusQuickAccess === 'loading' && (
          <div className="column px-xs pt-xs pb-0">
            <ContentLoader
              height="66px"
              ghost={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickAccessSection;
