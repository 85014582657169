import React, { Component, KeyboardEvent } from 'react';
import { InviteeDTO, MemberDTO } from '../../../common/api/dtos/Member';
import Button from '../../controls/Button';
import ComboBox from '../../controls/ComboBox';
import { ROLE_OPTIONS } from '../../../common/configs/roleOptions';
import Dialog from '../../controls/Dialog';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import ISubscription from '../../../common/interfaces/Subscription';

interface Props extends WithTranslation {
  member: MemberDTO | InviteeDTO;
  removeMember: (id: string) => Promise<void>;
  updateTeamMemberRole: (id: string, role: string) => Promise<void>;
  subscription: ISubscription | null;
}

interface State {
  showDeleteButtons: boolean;
  role: number | string | undefined | { label: string; value: string };
}

class ManageTeamMember extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showDeleteButtons: false,
      role: this.props.member.role.toLowerCase(),
    };
  }

  setRole = (
    ev: React.MouseEvent<HTMLLIElement> | KeyboardEvent<Element>,
    role: number | string | undefined | { label: string; value: string },
  ) => {
    const memberRole = role as string;
    this.setState({ role: memberRole });
    this.props.updateTeamMemberRole(this.props.member.id, memberRole);
  };

  render() {
    const { t } = this.props;
    const filterRoles =
      this.props.member.role === 'owner'
        ? ROLE_OPTIONS
        : ROLE_OPTIONS.filter((option) => option.value !== 'owner');

    return (
      <ul className="control-list-component flex-h-end no-wrap">
        <>
          {this.props.member.role ? (
            <ul className="control-list-component flex-h-end no-wrap">
              <li className="my-0">
                <ComboBox
                  id="userRoles"
                  value={this.props.member.role.toLowerCase()}
                  options={filterRoles}
                  onChange={this.setRole}
                  getValue={(op) => op?.value}
                  getLabel={(op) => t(op?.value)}
                  formGroupClassname="mb-0"
                  disabled={
                    this.props.member.role === 'owner' ||
                    this.props.subscription?.period !== 'active'
                  }
                  title={'User role'}
                />
              </li>
              <li className="my-0">
                <Button
                  className="ghost-button"
                  onClick={() => this.setState({ showDeleteButtons: true })}
                  disabled={
                    this.props.member.role === 'owner' ||
                    this.props.subscription?.period !== 'active'
                  }
                  title="Remove member"
                >
                  <span className="fas fa-times pe-none"></span>
                </Button>
              </li>
            </ul>
          ) : null}
          {this.state.showDeleteButtons && (
            <>
              <Dialog
                title={t('memberRemovalTitle')}
                message={t('removeMemberConfirmation', {
                  member:
                    this.props.member.name ||
                    (this.props.member as InviteeDTO).email ||
                    this.props.member.id,
                })}
                confirmText={t('confirmRemoveText')}
                info={
                  <p className="text-sm faint-text">
                    <span className="fas fa-info-circle"></span>{' '}
                    <span>{t('removeAccessText')}</span>
                  </p>
                }
                cancelText={t('cancelText')}
                onConfirm={() => {
                  this.props.removeMember(this.props.member.id);
                  this.setState({ showDeleteButtons: false });
                }}
                onCancel={() => this.setState({ showDeleteButtons: false })}
              />
            </>
          )}
        </>
      </ul>
    );
  }
}

export default withStyledTranslation('manageTeamMember')(ManageTeamMember);
