import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  TextFormatType,
  FORMAT_ELEMENT_COMMAND,
  ElementFormatType,
  ElementNode,
  SELECTION_CHANGE_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../Button';
import {
  FormatState,
  getInitialFormatState,
  updateFormatState,
} from './formatState';
import styles from './toolbar.module.css';
import BlockMenuPlugin from './BlockMenuPlugin';
import ToolbarLinkPlugin from './ToolbarLinkPlugin';
import ToolbarAttachmentPlugin from './ToolbarAttachmentPlugin';
import { alignmentButtons } from './alignmentButtons';
import { BlockType } from './BlockMenuOptions';
import { $isListNode, ListNode } from '@lexical/list';
import { $getNearestNodeOfType } from '@lexical/utils';
import { $isHeadingNode } from '@lexical/rich-text';
import { $isCodeNode } from '@lexical/code';
import ToolbarBlockStylePlugin from './ToolbarBlockStylePlugin';
import ContextMenu from '../../ContextMenu';

export default function ToolbarPlugin({
  cardAttachments,
  altStyle,
}: {
  cardAttachments: any[];
  altStyle?: boolean;
}) {
  const [editor] = useLexicalComposerContext();
  const [formatState, setFormatState] = useState<FormatState>(
    getInitialFormatState(),
  );
  const [url, setUrl] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [blockType, setBlockType] = useState('paragraph' as BlockType);
  const [showLinkMenu, setShowLinkMenu] = useState(false);
  const linkMenuRef = useRef(null);
  const formatMenuRef = useRef<ContextMenu | null>(null);
  const attachmentMenuRef = useRef(null);

  const updateToolbar = useCallback(() => {
    try {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        const element = anchorNode.getTopLevelElementOrThrow();
        const elementFormat = (element as ElementNode).getFormatType();
        const elementKey = element.getKey();
        const elementDOM = editor.getElementByKey(elementKey);

        if (elementDOM !== null) {
          if ($isListNode(element)) {
            const parentList = $getNearestNodeOfType(anchorNode, ListNode);
            const type = parentList ? parentList.getTag() : element.getTag();
            setBlockType(type as BlockType);
          } else {
            const type = (
              $isHeadingNode(element) ? element.getTag() : element.getType()
            ) as BlockType;
            setBlockType(type);

            if ($isCodeNode(element)) {
              editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
            }
          }
        }

        setFormatState(updateFormatState(selection, elementFormat));

        if (elementDOM) {
          const editorInner = elementDOM.closest('.editor-inner');
          const editorInput = elementDOM.closest('.editor-input');
          if (editorInner && editorInput) {
            const editorInputRect = editorInput.getBoundingClientRect();
            const elementDOMRect = elementDOM.getBoundingClientRect();
            const toolbarHeight = toolbarRef.current?.offsetHeight || 0;
            setPosition({
              top:
                elementDOMRect.top -
                editorInputRect.top +
                elementDOMRect.height +
                toolbarHeight,
              left: elementDOMRect.left - editorInputRect.left,
            });
          }
        }
      }
    } catch (error) {
      console.error('Error updating toolbar:', error);
    }
  }, [editor]);

  useEffect(() => {
    const unregister = editor.registerUpdateListener(() => {
      editor.getEditorState().read(updateToolbar);
    });
    return unregister;
  }, [editor, updateToolbar]);

  useEffect(() => {
    const toggleHighlight = (shouldHighlight: boolean) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const formatType = shouldHighlight ? 'highlight' : 'remove-highlight';
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType as any);
        }
      });
    };
    toggleHighlight(showLinkMenu);
  }, [showLinkMenu, editor]);

  useEffect(() => {
    const unregister = editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();
        return false;
      },
      1,
    );
    return unregister;
  }, [editor, updateToolbar]);

  const isFormatActive = (format: string): boolean => {
    const key = `is${format.charAt(0).toUpperCase() + format.slice(1)}`;
    return formatState[key as keyof FormatState];
  };

  const handleButtonClick = (format: string) => {
    if (['left', 'center', 'right', 'justify'].includes(format)) {
      if (isFormatActive(format)) {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, '');
      } else {
        editor.dispatchCommand(
          FORMAT_ELEMENT_COMMAND,
          format as ElementFormatType,
        );
      }
    } else {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, format as TextFormatType);
    }
  };

  return (
    <div
      ref={toolbarRef}
      className={`${styles.toolbar} ${
        altStyle ? styles['toolbar-alt-style'] : styles['toolbar-base-style']
      }`}
      style={{ position: 'sticky', zIndex: 2, top: altStyle ? '16px' : '9px' }}
    >
      <div className="card opaque py-2xs px-2xs">
        <ul className="control-list-component">
          <li>
            <BlockMenuPlugin
              editor={editor}
              toolbarRef={toolbarRef}
              blockType={blockType}
              formatMenuRef={formatMenuRef}
              setBlockType={setBlockType}
            />
          </li>
          <ToolbarBlockStylePlugin />
          {alignmentButtons.map(({ format, icon, ariaLabel }) => (
            <li key={format}>
              <Button
                onClick={() => handleButtonClick(format)}
                title={ariaLabel}
                className={`secondary-button spaced ${
                  isFormatActive(format) ? 'active-border' : ''
                }`}
                aria-label={ariaLabel}
              >
                <i className={`${icon} pe-none`} />
              </Button>
            </li>
          ))}
          <li>
            <ToolbarLinkPlugin
              editor={editor}
              url={url}
              setUrl={setUrl}
              position={position}
              linkMenuRef={linkMenuRef}
              setShowLinkMenu={setShowLinkMenu}
            />
          </li>
          <li>
            <ToolbarAttachmentPlugin
              editor={editor}
              cardAttachments={cardAttachments}
              attachmentMenuRef={attachmentMenuRef}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
