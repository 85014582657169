import {
  DecoratorNode,
  SerializedLexicalNode,
  Spread,
  LexicalNode,
} from 'lexical';
import { AttachmentDTO } from '../../../../../common/api/dtos/Card';
import { iconForAttachment, isViewable } from '../helpers/iconForAttachment';
import Button from '../../../Button';

type SerializedAttachmentBlockNode = Spread<
  {
    attachment: AttachmentDTO;
    type: 'attachment-ref';
    version: 1;
  },
  SerializedLexicalNode
>;

export class AttachmentNode extends DecoratorNode<JSX.Element> {
  __attachment: AttachmentDTO;

  static getType(): string {
    return 'attachment-ref';
  }

  static clone(node: AttachmentNode): AttachmentNode {
    return new AttachmentNode(node.__attachment, node.__key);
  }

  static importJSON(
    serializedNode: SerializedAttachmentBlockNode,
  ): AttachmentNode {
    const { attachment } = serializedNode;
    return $createAttachmentBlockNode(attachment);
  }

  exportJSON(): SerializedAttachmentBlockNode {
    return {
      attachment: this.__attachment,
      type: 'attachment-ref',
      version: 1,
    };
  }

  constructor(attachment: AttachmentDTO, key?: string) {
    super(key);
    this.__attachment = attachment;
  }

  createDOM(): HTMLElement {
    const span = document.createElement('span');
    span.className = 'attachment-button';
    return span;
  }

  updateDOM(): false {
    return false;
  }

  decorate() {
    const { contentType, filename, id } = this.__attachment;

    return isViewable(contentType) ? (
      <Button
        onClick={() => {
          const event = new CustomEvent('openAttachment', {
            detail: this.__attachment,
          });
          window.dispatchEvent(event);
        }}
        className="secondary-button"
        draggable={false}
      >
        <span className={`${iconForAttachment(contentType)} icon`}></span>
        <span className="text">{filename}</span>
      </Button>
    ) : (
      <a
        href={`${process.env.REACT_APP_API_BASE_URL}/attachment/${id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="secondary-button"
      >
        <span className={`${iconForAttachment(contentType)} icon`}></span>
        <span className="text">{filename}</span>
      </a>
    );
  }
}

export function $createAttachmentBlockNode(
  attachment: AttachmentDTO,
): AttachmentNode {
  return new AttachmentNode(attachment);
}

export function $isAttachmentBlockNode(
  node: LexicalNode | null | undefined,
): node is AttachmentNode {
  return node instanceof AttachmentNode;
}
