// Manages the display of a user comment and its editor in a card flyout.
import React, { useState, useContext } from 'react';
import {
  updateComment,
  deleteComment,
} from '../../../../../common/api/endpoints/comment';
import { CommentDTO } from '../../../../../common/api/dtos/Comment';
import Button from '../../../../controls/Button';
import { IBoardCurrentUser } from '../../../../../common/interfaces/BoardCurrentUser';
import Dialog from '../../../../controls/Dialog';
import getTime from '../../../../../common/helpers/getTime';
import { Trans, useTranslation } from 'react-i18next';
import { InviteeDTO, MemberDTO } from '../../../../../common/api/dtos/Member';
import Thumbnail from '../../../Thumbnail/Thumbnail';
import EditorControl from '../../../../controls/EditorControl/EditorControl';
import EditorViewer from '../../../../controls/EditorControl/EditorViewer';
import AppContext from '../../../../../common/contexts/AppContext';
import { AttachmentDTO } from '../../../../../common/api/dtos/Card';

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];
  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface Props {
  disabled: boolean;
  boardCard: BoardCard;
  boardUser: IBoardCurrentUser;
  comment: CommentDTO;
  updateComment: (commentData: Partial<CommentDTO>) => void;
  removeComment: (commentData: Partial<CommentDTO>) => void;
  handleDeleteComment?: () => void;
  members: (MemberDTO | InviteeDTO)[];
  cardAttachments: AttachmentDTO[];
}

const UserCommentEditor: React.FC<Props> = (props) => {
  // Destructure props
  const {
    comment,
    updateComment: updateCommentProp,
    removeComment,
    boardUser,
    disabled,
    members,
    handleDeleteComment,
    cardAttachments,
  } = props;
  const appContext = useContext(AppContext);
  const { t } = useTranslation('userComment');

  // State hooks
  const [form, setForm] = useState({ comment: comment.content });
  const [showLexical, setShowLexical] = useState(false);
  const [showDeletionPrompt, setShowDeletionPrompt] = useState(false);

  const handleSetComment = (value: string | null) => {
    setForm({ comment: value ?? '' });
  };

  const handleUpdate = async () => {
    if (form.comment !== comment.content && form.comment !== null) {
      try {
        const updatedComment = await updateComment(comment.id, form.comment);
        setForm({ comment: '' });
        updateCommentProp(updatedComment);
        setShowLexical(false);
      } catch (err) {
        console.debug(err);
        appContext.notifications.setMessages
          ? appContext.notifications.setMessages(err as any)
          : () => false;
      }
    }
  };

  const handleCancel = () => {
    setShowLexical(false);
    setForm({ comment: comment.content });
  };

  const getMemberAvatar = (id: string) => {
    const member = members.find((m) => m.id === id);
    return member?.avatar;
  };

  const getMemberName = (id: string | undefined) => {
    const member = members.find((m) => m.id === id);
    return member?.name ?? (member as InviteeDTO)?.email;
  };

  const renderAvatar = () => {
    const memberName = getMemberName(comment.authorId);
    return (
      <Thumbnail
        classes="size-24 icon"
        avatarData={getMemberAvatar(comment.authorId)}
        title={memberName ?? t('unknown')}
      />
    );
  };

  const handleDelete = async () => {
    try {
      await deleteComment(comment.id);
      const deletedComment = {
        ...comment,
        deletedBy: boardUser.id,
        deletedAt: new Date().toISOString(),
      };
      removeComment(deletedComment);
      handleDeleteComment?.();
      setShowLexical(false);
    } catch (err) {
      console.debug(err);
    }
  };

  const renderDeletionDialog = () => {
    if (!showDeletionPrompt) return null;
    const timeFormat = appContext.loggedUser?.accountSettings.timeFormat ?? 12;
    return (
      <Dialog
        title={t('commentDeletion')}
        message={
          <Trans
            i18nKey="userComment:commentDeletionConfirmation"
            values={{
              avatar: renderAvatar(),
              author: getMemberName(comment.authorId),
              time: getTime(comment.createdAt, timeFormat),
            }}
            components={[
              renderAvatar(),
              <strong key="1" />,
              <strong key="2" />,
            ]}
          />
        }
        info={
          <p className="text-sm faint-text">
            <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
            <span>{t('operationIrreversible')}</span>
          </p>
        }
        cancelText={t('cancel')}
        confirmText={t('permanentlyDelete')}
        onCancel={() => setShowDeletionPrompt(false)}
        onConfirm={() => {
          setShowDeletionPrompt(false);
          handleDelete();
        }}
      />
    );
  };

  const canEditOrDelete =
    (['owner', 'admin'].includes(boardUser.role) ||
      boardUser.id === comment.authorId) &&
    !disabled;

  return (
    <>
      {!showLexical ? (
        <>
          <EditorViewer
            editorState={comment.content}
            namespace={`comment-viewer-${comment.id}`}
          />
          {canEditOrDelete && (
            <ul className="control-list-component mb-xs">
              {boardUser.id === comment.authorId && (
                <li>
                  <Button
                    className="secondary-button lexical-button-trigger"
                    onClick={() => setShowLexical(true)}
                  >
                    <span className="text">{t('edit')}</span>
                  </Button>
                </li>
              )}
              <li>
                <Button
                  className="secondary-button"
                  onClick={() => setShowDeletionPrompt(true)}
                >
                  <span className="text">{t('delete')}</span>
                </Button>
                {renderDeletionDialog()}
              </li>
            </ul>
          )}
        </>
      ) : (
        <div>
          {comment.content && (
            <EditorControl
              onChange={handleSetComment}
              namespace={`comment-editor-${comment.id}`}
              initialEditorState={comment.content}
              cardAttachments={cardAttachments}
              altStyle={true}
              confirmText={t('updateComment')}
              cancelText={t('cancel')}
              onSave={() => handleUpdate()}
              onCancel={() => handleCancel()}
            />
          )}
        </div>
      )}
    </>
  );
};

export default UserCommentEditor;
