import React, { MouseEvent } from 'react';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { setCardTag } from '../../../common/api/endpoints/card';
import { TAG_COLORS } from '../../../common/configs/tag';
import AppContext, { IAppContext } from '../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../common/contexts/NotificationsContext';
import BoardContext from '../../../common/contexts/BoardContext';
import { IBoardContext } from '../../../common/interfaces/BoardContext';
import { getColorSymbol } from '../../../common/helpers/getColorSymbol';
import { showErrorNotifications } from '../../../common/helpers/showNotifications';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import { TContextActionTag } from '../../../common/types/Tag';
import Button from '../../controls/Button';
import DirectionalButton from '../../controls/DirectionalButton';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import selectTutorial from '../../../common/helpers/selectTutorial';
import { updateTutorialStep } from '../../../common/helpers/tutorialHelper';
import { UserDTO } from '../../../common/api/dtos/User';
import { IClientData } from '../../../common/interfaces/ClientData';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
  updateClientData: (clientData: Partial<IClientData>) => void;
  loggedUser: UserDTO;
}
interface BoardContextProps {
  isMember: boolean;
  tags: TagDTO[];
  loadingTagIds: string[];
  setLoadingTagIds: (tags: string[]) => void;
  assignTag: (cardId: string, tagId: string) => void;
  unassignTag: (cardId: string, tagId: string) => void;
}
interface ExternalProps {
  selected: string[];
  directionalButton?: IDirectionalButton;
  action: TContextActionTag;
  cardId?: string;
  setSelectedTags?: (tags: string[]) => void;
}
interface Props extends AppContextProps, ExternalProps, BoardContextProps {}

interface State {
  currentTags: TagDTO[];
  showTags: number;
}

class TagMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTags: [],
      showTags: 5, // initial number of tags to display, to be defined
    };
  }

  componentDidMount(): void {
    this.setState({
      currentTags: this.props.tags,
    });
  }

  tutorialAction_assignTag = async () => {
    const loggedUser = this.props.loggedUser;
    if (loggedUser) {
      const userType = selectTutorial(loggedUser);
      if (userType !== 'solo' && userType !== 'teamOwner') return;
      const stepId = userType === 'solo' ? 'assign_tag' : 'assign_tag_team';

      await updateTutorialStep(
        loggedUser,
        userType,
        stepId,
        this.props.setMessages,
        this.props.updateClientData,
      );
    }
  };

  handleSetTags = async (ev: MouseEvent<HTMLButtonElement>) => {
    const tagId = ev.currentTarget.value;

    // disable button on click
    const tempLoadingTags = this.props.loadingTagIds
      ? [...this.props.loadingTagIds]
      : [];

    try {
      const tempTags = [...this.props.selected];
      const existingTagIndex = tempTags.indexOf(tagId);

      this.props.setLoadingTagIds &&
        this.props.setLoadingTagIds([tagId, ...tempLoadingTags]);

      if (existingTagIndex === -1) {
        tempTags.push(tagId);
        if (this.props.action === 'assign' && this.props.cardId) {
          await setCardTag(String(this.props.cardId), tagId, 'tag');
          this.tutorialAction_assignTag();
          this.props.assignTag(this.props.cardId, tagId);
        }
      } else {
        if (this.props.action === 'assign' && this.props.cardId) {
          await setCardTag(String(this.props.cardId), tagId, 'untag');
          this.props.unassignTag(this.props.cardId, tagId);
        }
        tempTags.splice(existingTagIndex, 1);
      }

      this.props.setSelectedTags && this.props.setSelectedTags(tempTags);
    } catch (error) {
      showErrorNotifications(error, this.props.setMessages);
    }

    // enable button after response
    const idToRemove = tempLoadingTags.findIndex((id) => id === tagId);
    tempLoadingTags.splice(idToRemove, 1);
    this.props.setLoadingTagIds && this.props.setLoadingTagIds(tempLoadingTags);
  };

  // SHOW MORE, UNCOMMENT TO USE
  // handleShowMore = () => {
  //   this.setState({
  //     showTags: this.state.currentTags.length, // opens all tags, to be defined
  //   });
  // };

  render() {
    // SHOW MORE, UNCOMMENT TO USE
    // const displayShowMoreButton =
    //   this.state.currentTags.length > this.state.showTags;

    return (
      <>
        {this.props.directionalButton && (
          <>
            <li>
              <DirectionalButton
                directionalButton={{
                  text: this.props.directionalButton.text,
                  direction: this.props.directionalButton.direction,
                  onClick: this.props.directionalButton.onClick,
                }}
                className={`flex-h-spread ${
                  this.props.isMember ? 'disabled' : ''
                }`}
              />
            </li>
            <li>
              <hr />
            </li>
          </>
        )}
        {/* SHOW MORE, UNCOMMENT TO USE */}
        {/* {this.state.currentTags.slice(0, this.state.showTags).map((tag) => { */}
        {this.props.tags.map((tag) => {
          const symbol = getColorSymbol(TAG_COLORS, tag.color);
          const isActive =
            this.props.selected && this.props.selected.includes(tag.id!);
          const isLoading = this.props.loadingTagIds
            ? this.props.loadingTagIds.includes(tag.id)
            : false;

          return (
            <li
              className={`${isActive ? 'active' : ''}`}
              key={tag.id}
            >
              <Button
                className="ghost-button"
                onClick={this.handleSetTags}
                value={tag.id}
                disabled={isLoading}
              >
                <span className={`icon accent-text-${tag.color}`}>
                  <span className={`cb-only icon mr-0 ${symbol} `}></span>
                  <span className={`cb-none pl-2xs fas fa-tag`}></span>
                </span>
                <span className="text">{tag.name}</span>
              </Button>
            </li>
          );
        })}
        {/* SHOW MORE, UNCOMMENT TO USE */}
        {/* {displayShowMoreButton && (
          <li>
            <Button
              className="secondary-button fill"
              onClick={this.handleShowMore}
            >
              <span className="text">Show more</span>
            </Button>
          </li>
        )} */}
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      updateClientData: ctx.updateClientData!,
      loggedUser: ctx.loggedUser!,
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      isMember: ctx.board.user.role === 'member',
      tags: ctx.board.tags!,
      loadingTagIds: ctx.loadingTagIds,
      setLoadingTagIds: ctx.setLoadingTagIds,
      assignTag: ctx.assignTag,
      unassignTag: ctx.unassignTag,
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(TagMenu, AppContextAdapter, BoardContextAdapter);
