import React, { ChangeEvent, FormEvent, useState, useMemo } from 'react';
import { TAG_COLORS } from '../../../common/configs/tag';
import Button from '../../controls/Button';
import TextBox from '../../controls/TextBox';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { UserDTO } from '../../../common/api/dtos/User';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import Joi from 'joi';

interface FormData {
  id: string;
  name: string;
  color: string;
}

interface Props extends WithTranslation {
  handleSubmit: (boardTagData: TagDTO) => void;
  legendText: string;
  selectedTag?: TagDTO | null;
  serverErrors: string[];
  loggedUser: UserDTO;
  setSelectedContext?: (context: TContextMenu) => void;
}

const schema = Joi.object({
  id: Joi.string().allow(''),
  name: Joi.string()
    .pattern(/^[a-zA-Z0-9 ~\-'.]*$/)
    .min(1)
    .trim()
    .required()
    .messages({
      'string.pattern.base':
        "The name can only contain letters, numbers, spaces, apostrophes ('), hyphens (-), and periods (.)",
      'string.empty': 'The name cannot be empty',
      'any.required': 'The name is required',
    }),
  color: Joi.string().required(),
});

const TagForm: React.FC<Props> = (props) => {
  const defaultColor = useMemo(() => {
    const storedColor = localStorage.getItem(
      `${props.loggedUser.id}-lastTagColor`,
    );
    return storedColor ? storedColor : TAG_COLORS[0].id;
  }, [props.loggedUser.id]);

  const [formData, setFormData] = useState<FormData>({
    id: props.selectedTag?.id || '',
    name: props.selectedTag?.name || '',
    color: props.selectedTag?.color || defaultColor,
  });
  const [formErrors, setFormErrors] = useState<
    Partial<Record<keyof FormData, string>>
  >({});

  const validateForm = (): boolean => {
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const errors: Partial<Record<keyof FormData, string>> = {};
      error.details.forEach((detail) => {
        const key = detail.path[0] as keyof FormData;
        errors[key] = detail.message;
      });
      setFormErrors(errors);
      return false;
    }
    setFormErrors({});
    return true;
  };

  const updateFormData = <K extends keyof FormData>(
    field: K,
    value: FormData[K],
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const setTagName = (ev: ChangeEvent<HTMLInputElement>) => {
    updateFormData('name', ev.target.value);
  };

  const setTagColor = (value: string) => {
    localStorage.setItem(`${props.loggedUser.id}-lastTagColor`, value);
    updateFormData('color', value);
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      props.handleSubmit(formData);
    }
  };

  const { t } = props;

  return (
    <fieldset id="addTagForm">
      <legend>{props.legendText}</legend>
      <ul className="control-list-component unwrappable">
        {TAG_COLORS.map((color) => {
          const isSelected = formData.color === color.id;
          return (
            <li key={color.id}>
              <Button
                className={`secondary-button ${isSelected ? 'active selection-border' : ''}`}
                value={formData.color}
                onClick={() => setTagColor(color.id)}
              >
                <span className={`icon accent-text-${color.id}`}>
                  <span className={`cb-only ${color.symbol}`}></span>
                  <span className="cb-none fas fa-tag"></span>
                </span>
              </Button>
            </li>
          );
        })}
      </ul>
      <div className="flex-row fill tagInputForm">
        <div className="column">
          <form onSubmit={handleFormSubmit}>
            <TextBox
              label={t('tagNameLabel')}
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={setTagName}
              srOnly={true}
              placeholder={t('tagNameLabel')}
              maxLength={30}
              showCounter={false}
              className="tagInput"
              error={formErrors.name}
            />
            <ul className="control-list-component">
              <li>
                <Button
                  className="primary-button"
                  type="submit"
                >
                  {t('saveTag')}
                </Button>
              </li>
              <li>
                <Button
                  className="ghost-button"
                  title={t('closeForm')}
                  onClick={() =>
                    props.setSelectedContext &&
                    props.setSelectedContext('TagManageMenus')
                  }
                >
                  <span className="fas fa-times pe-none"></span>
                </Button>
              </li>
            </ul>
          </form>
          {props.children}
        </div>
      </div>
    </fieldset>
  );
};

export default withStyledTranslation('tagForm')(TagForm);
