import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  CSSProperties,
} from 'react';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';

interface VideoPlayerProps extends WithTranslation {
  src: string;
  alt?: string;
  goToPrevious: () => void;
  goToNext: () => void;
  introDirection?: 'prev' | 'next' | null;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  goToPrevious,
  goToNext,
  introDirection,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const fullScreenRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const animationFrameId = useRef<number | null>(null);
  const containerResizeObserver = useRef<ResizeObserver | null>(null);
  const [videoReady, setVideoReady] = useState(false);

  const handleSpacePress = useCallback((event: KeyboardEvent): void => {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleSpacePress);
    videoRef.current?.setAttribute('webkit-playsinline', '');

    return () => {
      window.removeEventListener('keydown', handleSpacePress);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      if (containerResizeObserver.current && containerRef.current) {
        containerResizeObserver.current.unobserve(containerRef.current);
      }
    };
  }, [handleSpacePress]);

  const onWheel = (event: React.WheelEvent<HTMLDivElement>): void => {
    if (event.shiftKey) {
      event.preventDefault();
      if (event.deltaY < 0) {
        goToPrevious();
      } else if (event.deltaY > 0) {
        goToNext();
      }
      return;
    }
    event.preventDefault();
  };

  const introAnimation = (): string => {
    if (introDirection === null) {
      return '';
    }
    return introDirection === 'prev' ? 'reveal-right-1' : 'reveal-left-1';
  };

  const containerStyle: CSSProperties = {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '100%',
  };

  const videoStyle: CSSProperties = {
    cursor: 'grab',
    position: 'absolute',
    transform: `translate(-50%, -50%)`,
    left: '50%',
    top: '50%',
    width: 'auto',
    height: 'auto',
    maxWidth: 'calc(100% - 16px)',
    maxHeight: 'calc(100% - 16px)',
    zIndex: 1,
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    border: '1px solid rgba(100, 100, 100, 0.33)',
    backgroundColor: 'black',
  };

  return (
    <div
      className="showcased-image-wrapper"
      ref={fullScreenRef}
    >
      <div
        ref={containerRef}
        className={introAnimation()}
        style={containerStyle}
        onWheel={onWheel}
      >
        {/* Placeholder black div until video is ready */}
        {!videoReady && (
          <div
            style={{
              ...videoStyle,
              position: 'absolute',
              width: '320px',
              height: '180px',
            }}
          >
            <div className="center v-center">
              <span className="loader"></span>
            </div>
          </div>
        )}
        <video
          ref={videoRef}
          controls
          playsInline
          src={src}
          style={{ ...videoStyle, opacity: videoReady ? 1 : 0 }}
          onCanPlay={() => setVideoReady(true)}
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default withStyledTranslation('imageZoomer')(VideoPlayer);
