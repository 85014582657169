import React from 'react';

interface MaterialIconProps {
  icon: string;
  className?: string;
}

const MaterialIcon: React.FC<MaterialIconProps> = ({
  icon,
  className,
  ...props
}) => {
  return (
    <i
      className={`material-icons ${className || ''}`}
      {...props}
    >
      {icon}
    </i>
  );
};

export default MaterialIcon;
