import React from 'react';
import Button from '../../controls/Button';
import { UserDTO } from '../../../common/api/dtos/User';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import LinkButton from '../../controls/LinkButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  handleLogout: () => void;
  loggedUser?: UserDTO;
  lockedAccount?: boolean;
}

class UserMenu extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <li>
          <div className="flex-row tight squeeze">
            <div className="column flex-v-center">
              <LinkButton
                to={'/account/user-account/avatar'}
                className="image-link"
                disabled={this.props.lockedAccount}
              >
                <Thumbnail
                  classes="thumbnail size-32"
                  avatarData={this.props.loggedUser?.avatar}
                  title={this.props.loggedUser?.name}
                />
              </LinkButton>
            </div>
            <div className="column flex-v-center">
              <span
                className="text text-ellipsis"
                style={{ maxWidth: '120px' }}
              >
                {this.props.loggedUser?.name} <br />
              </span>
            </div>
          </div>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <LinkButton
            to="/account/user-account"
            className="ghost-button"
            id="accountContextMenuButton"
            activeClassName="active disabled"
            disabled={this.props.lockedAccount}
          >
            <span className="far fa-user icon"></span>
            <span className="text">{t('account')}</span>
          </LinkButton>
        </li>
        <li>
          <LinkButton
            to="/account/global-scope"
            className="ghost-button"
            id="settingsContextMenuButton"
            activeClassName="active disabled"
            disabled={this.props.lockedAccount}
          >
            <span className="icon fal fa-cog"></span>
            <span className="text">{t('appSettings')}</span>
          </LinkButton>
        </li>
        <li>
          <Button
            className="ghost-button"
            onClick={() => this.props.handleLogout()}
            id="logOutContextMenuButton"
            idleIcon="fas fa-power-off"
          >
            <span className="text">{t('logOut')}</span>
          </Button>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('userMenu')(UserMenu);
